const ro = {
	mixed: {
		default: "este invalid",
		required: "este un câmp obligatoriu",
		defined: "trebuie să fie definit",
		notNull: "nu poate fi nul",
		oneOf: "trebuie să fie una dintre următoarele valori: ${values}",
		notOneOf: "nu trebuie să fie una dintre următoarele valori: ${values}"
	},
	string: {
		length: "trebuie să aibă exact ${length} caractere",
		min: "trebuie să aibă cel puțin ${min} caractere",
		max: "trebuie să aibă cel mult ${max} caractere",
		matches: "trebuie să corespundă următorului: ${regex}",
		email: "trebuie să fie un email valid",
		url: "trebuie să fie un URL valid",
		uuid: "trebuie să fie un UUID valid",
		trim: "trebuie să fie o secvență de caractere fără spații la început și sfârșit",
		lowercase: "trebuie să fie o secvență de caractere cu litere mici",
		uppercase: "trebuie să fie o secvență de caractere cu litere mari"
	},
	number: {
		min: "trebuie să fie mai mare sau egal cu ${min}",
		max: "trebuie să fie mai mic sau egal cu ${max}",
		lessThan: "trebuie să fie mai mic decât ${less}",
		moreThan: "trebuie să fie mai mare decât ${more}",
		positive: "trebuie să fie un număr pozitiv",
		negative: "trebuie să fie un număr negativ",
		integer: "trebuie să fie un număr întreg"
	},
	date: {
		min: "trebuie să fie mai târziu decât ${min}",
		max: "trebuie să fie mai devreme decât ${max}"
	},
	object: {
		noUnknown: "conține chei neprevăzute: ${unknown}"
	},
	array: {
		min: "trebuie să aibă cel puțin ${min} elemente",
		max: "trebuie să aibă cel mult ${max} elemente",
		length: "trebuie să aibă ${length} elemente"
	},
	boolean: {
		isValue: "trebuie să fie ${value}"
	}
};

const fr = {
	mixed: {
		default: "n'est pas valide.",
		required: "est un champ requis",
		defined: "doit être défini",
		notNull: "ne peut pas être nul",
		oneOf: "doit être l'une des valeurs suivantes: ${values}",
		notOneOf: "ne doit pas être l'une des valeurs suivantes: ${values}"
	},
	string: {
		length: "doit être exactement ${length} caractères",
		min: "doit être au moins ${min} caractères",
		max: "doit être au plus ${max} caractères",
		matches: "doit correspondre à ce qui suit: ${regex}",
		email: "doit être un e-mail valide",
		url: "doit être une URL valide",
		uuid: "doit être un uuid valide",
		trim: "doit être une chaîne taillée",
		lowercase: "doit être une chaîne en minuscules",
		uppercase: "Doit être une chaîne en majuscules"
	},
	number: {
		min: "doit être supérieur ou égal à ${min}",
		max: "doit être inférieur ou égal à ${max}",
		lessThan: "doit être inférieur à ${less}",
		moreThan: "doit être supérieur à ${more}",
		positive: "doit être un nombre positif",
		negative: "doit être un nombre négatif",
		integer: "doit être un entier"
	},
	date: {
		min: "Le champ doit être plus tard que ${min}",
		max: "Le champ doit être plus tôt que ${max}"
	},
	boolean: {
		isValue: "Le champ doit être ${value}"
	},
	object: {
		noUnknown: "Le champ ne peut pas avoir des clés non spécifiées dans la forme de l'objet"
	},
	array: {
		min: "Le champ doit avoir au moins ${min} des articles",
		max: "Le champ doit avoir moins ou égal à ${max}",
		length: "doit avoir ${length} des articles"
	}
}

const vi = {
	mixed: {
		default: "không hợp lệ.",
		required: "là một trường bắt buộc",
		defined: "phải được xác định",
		notNull: "không thể là NULL",
		oneOf: "phải là một trong các giá trị sau: ${values}",
		notOneOf: "Không được là một trong các giá trị sau: ${values}"
	},
	string: {
		length: "phải chính xác là ${length} ký tự",
		min: "phải ít nhất ${min} ký tự",
		max: "nhất phải là các ký tự ${max}",
		matches: "phải phù hợp như sau: ${regex}",
		email: "phải là một email hợp lệ",
		url: "phải là một URL hợp lệ",
		uuid: "phải là UUID hợp lệ",
		trim: "phải là một chuỗi được cắt",
		lowercase: "phải là chuỗi chữ thường",
		uppercase: "phải là chuỗi trường hợp trên"
	},
	number: {
		min: "phải lớn hơn hoặc bằng ${min}",
		max: "phải nhỏ hơn hoặc bằng ${max}",
		lessThan: "phải nhỏ hơn ${less}",
		moreThan: "phải lớn hơn ${more}",
		positive: "phải là một số dương",
		negative: "phải là số âm",
		integer: "phải là một số nguyên"
	},
	date: {
		min: "Trường phải muộn hơn ${min}",
		max: "Trường phải sớm hơn ${max}"
	},
	boolean: {
		isValue: "Trường phải là ${value}"
	},
	object: {
		noUnknown: "Trường không thể có các khóa không được chỉ định trong hình dạng đối tượng"
	},
	array: {
		min: "Trường phải có ít nhất ${min} các mục",
		max: "Trường phải có ít hơn hoặc bằng các mục ${max}",
		length: "Phải có ${length} các mục"
	}
}

const zh = {
	mixed: {
		default: "无效。",
		required: "是必需的字段",
		defined: "必须定义",
		notNull: "不能无效",
		oneOf: "必须是以下值之一：${values}",
		notOneOf: "不能是以下值之一：${values}"
	},
	string: {
		length: "必须完全是${length}字符",
		min: "至少必须是${min}字符",
		max: "最多必须是${max}字符",
		matches: "必须匹配以下：“${regex}”",
		email: "必须是一封有效的电子邮件",
		url: "必须是有效的URL",
		uuid: "必须是有效的UUID",
		trim: "必须是修剪的字符串",
		lowercase: "必须是小写字符串",
		uppercase: "必须是大写字符串"
	},
	number: {
		min: "必须大于或等于${min}",
		max: "必须小于或等于${max}",
		lessThan: "必须小于${less}",
		moreThan: "必须大于${more}",
		positive: "必须是一个正数",
		negative: "必须是负数",
		integer: "必须是一个整数"
	},
	date: {
		min: "字段必须比${min}晚。",
		max: "字段必须早于${max}"
	},
	boolean: {
		isValue: "字段必须为${value}"
	},
	object: {
		noUnknown: "字段不能具有对象形状中未指定的键"
	},
	array: {
		min: "字段至少必须具有${min}项目",
		max: "字段必须小于或等于${max}项目",
		length: "必须有${length}项目"
	}
}

const ar = {
	mixed: {
		default: " غير صالح.",
		required: " هو حقل مطلوب",
		defined: " يجب تعريفها",
		notNull: " لا يمكن أن يكون فارغًا",
		oneOf: " يجب أن تكون واحدة من القيم التالية: ${values}",
		notOneOf: " يجب ألا تكون واحدة من القيم التالية: ${values}"
	},
	string: {
		length: " يجب أن يكون بالضبط ${length} أحرف",
		min: " يجب أن تكون أحرفًا على الأقل ${min}",
		max: " يجب أن تكون على الأكثر ${max} أحرف",
		matches: " يجب أن يتطابق مع ما يلي: ${regex}",
		email: " يجب أن يكون بريدًا إلكترونيًا صالحًا",
		url: " يجب أن يكون عنوان URL صالح",
		uuid: " يجب أن يكون uuid صالح",
		trim: " يجب أن تكون سلسلة مقلدة",
		lowercase: " يجب أن تكون سلسلة صغيرة",
		uppercase: " يجب أن تكون سلسلة حالة علوية"
	},
	number: {
		min: " يجب أن تكون أكبر من أو تساوي ${min}",
		max: " يجب أن يكون أقل من أو يساوي ${max}",
		lessThan: " يجب أن يكون أقل من ${less}",
		moreThan: " يجب أن تكون أكبر من ${more}",
		positive: " يجب أن يكون رقمًا إيجابيًا",
		negative: " يجب أن يكون رقمًا سالبًا",
		integer: " يجب أن يكون عدد صحيح"
	},
	date: {
		min: "يجب أن يكون الحقل  متأخراً عن ${min}",
		max: "يجب أن يكون الحقل  في وقت سابق من ${max}"
	},
	boolean: {
		isValue: " يجب أن يكون الحقل ${value}"
	},
	object: {
		noUnknown: " لا يمكن أن يحتوي الحقل على مفاتيح غير محددة في شكل الكائن"
	},
	array: {
		min: " يجب أن يكون للحقل عناصر على الأقل ${min}",
		max: " يجب أن يحتوي الحقل على أقل من أو يساوي ${max} عناصر",
		length: " يجب أن يكون لديها ${length} عناصر"
	}
}

const en = {
	mixed: {
		default: " is invalid",
		required: " is a required field",
		defined: " must be defined",
		notNull: " cannot be null",
		oneOf: " must be one of the following values: ${values}",
		notOneOf: " must not be one of the following values: ${values}"
	},
	string: {
		length: " must be exactly ${length} characters",
		min: " must be at least ${min} characters",
		max: " must be at most ${max} characters",
		matches: " must match the following: ${regex}",
		email: " must be a valid email",
		url: " must be a valid URL",
		uuid: " must be a valid UUID",
		trim: " must be a trimmed string",
		lowercase: " must be a lowercase string",
		uppercase: " must be a upper case string"
	},
	number: {
		min: " must be greater than or equal to ${min}",
		max: " must be less than or equal to ${max}",
		lessThan: " must be less than ${less}",
		moreThan: " must be greater than ${more}",
		positive: " must be a positive number",
		negative: " must be a negative number",
		integer: " must be an integer"
	},
	date: {
		min: " field must be later than ${min}",
		max: " field must be at earlier than ${max}"
	},
	object: {
		noUnknown: " field has unspecified keys: ${unknown}"
	},
	array: {
		min: " field must have at least ${min} items",
		max: " field must have less than or equal to ${max} items",
		length: " must have ${length} items"
	},
	boolean: {
		isValue: " field must be ${value}"
	}
}

export const getYupLocale = (lang) => {
	switch (lang) {
		case 'en':
			return en;
		case 'ro':
			return ro;
		case 'fr':
			return fr;
		case 'vi':
			return vi;
		case 'cn':
			return zh;
		case 'ar':
			return ar;
		default:
			return en;
	}
}
