import React, {useCallback, useState} from 'react';
import PropTypes from "prop-types";
import * as Yup from "yup";
import Stack from "@mui/material/Stack";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {useForm} from "react-hook-form";

import {yupResolver} from "@hookform/resolvers/yup";

import FormProvider, {RHFAutocomplete, RHFSelect, RHFTextField} from 'src/components/hook-form';
import axios, {endpoints} from "../../utils/axios";
import {useLocales} from "../../locales";
import {countries} from "../../assets/data";
import Iconify from "../../components/iconify";
import {useSnackbar} from "src/components/snackbar";
import MenuItem from "@mui/material/MenuItem";
import LoadingButton from "@mui/lab/LoadingButton";
import {Backdrop, CircularProgress} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useAuthContext} from "../../auth/hooks";

function OrganisationDetails({
	                             stepper = false,
	                             handleNext,
	                             handleBack,
	                             setErrorMsg,
	                             hasBack,
	                             setOrganisationData,
	                             organisationData,
	                             setConfigurationData,
	                             configurationData,
                             }) {
	const [loading, setLoading] = useState(false);
	const {selectOrganisation, fetchSubscription} = useAuthContext();
	const {enqueueSnackbar} = useSnackbar();
	const locales = useLocales();
	const {t} = locales;

	const addressSchema = Yup.object().shape({
		street: Yup.string().min(3, t('fieldErrors.minLength', {length: 3})).max(100, t('fieldErrors.maxLength', {length: 100})).required(t('fieldErrors.required')),
		city: Yup.string().min(3, t('fieldErrors.minLength', {length: 3})).max(50, t('fieldErrors.maxLength', {length: 50})).required(t('fieldErrors.required')),
		county: Yup.string().min(3, t('fieldErrors.minLength', {length: 3})).max(50, t('fieldErrors.maxLength', {length: 50})).required(t('fieldErrors.required')),
		country: Yup.string().min(3, t('fieldErrors.minLength', {length: 3})).max(50, t('fieldErrors.maxLength', {length: 50})).required(t('fieldErrors.required')),
	});

	const legalDetailsSchema = Yup.object().shape({
		taxId: Yup.string().max(100, t('fieldErrors.maxLength', {length: 100})),
	});

	const RegisterSchema = Yup.object().shape({
		language: Yup.string().required(t('fieldErrors.required')),
		name: Yup.string().required(t('fieldErrors.required')),
		address: addressSchema,
		legalDetails: legalDetailsSchema,
	});

	const methods = useForm({
		resolver: yupResolver(RegisterSchema), defaultValues: {...organisationData, ...configurationData},
	});

	const {
		handleSubmit,
	} = methods;

	const submit = handleSubmit(async data => {
		try {
			setLoading(true)
			if (organisationData) {
				const responseOrganisation = await axios.patch(endpoints.organisation.update(organisationData.id), data);
				if (setOrganisationData) {
					setOrganisationData(responseOrganisation.data);
				}
				if (!stepper) {
					enqueueSnackbar(t('successes.updated'), {variant: 'success'});
				}
			} else {
				data.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
				const responseOrganisation = await axios.post(endpoints.organisation.create, data);
				if (setOrganisationData) {
					setOrganisationData(responseOrganisation.data.organisation);

				}
				const responseConfiguration = await axios.get(endpoints.configurations.find);
				if (setConfigurationData) {
					setConfigurationData(responseConfiguration.data);
				}
				await selectOrganisation(responseOrganisation.data.organisation.id);
				await fetchSubscription();
			}
			if (handleNext) {
				setLoading(false);
				handleNext();
			}
		} catch (error) {
			setLoading(false);
			console.error(error);
			if (setErrorMsg) {
				setErrorMsg(typeof error === 'string' ? error : error.message);
			}
		}

	}, error => {
	});

	const handleChangeLang = useCallback((newLang) => {
		locales.onChangeLang(newLang);
	}, [locales]);


	return (<FormProvider methods={methods} onSubmit={submit}>
		<Backdrop
			sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
			open={!organisationData && loading}
		>
			<Box display="flex" flexDirection="column" alignItems="center">
				<CircularProgress/>
				<Typography variant="h6">
					{t('loaders.creatingOrganization')}
				</Typography>
			</Box>
		</Backdrop>
		<Stack sx={{mt: 5, mb: 2}} spacing={2}>
			<RHFSelect name="language" label="Language">
				{locales.allLangs.map((option) => (<MenuItem
					key={option.value}
					value={option.value}
					onClick={() => handleChangeLang(option.value)}
				>
					<Stack direction="row" spacing={2}>
						<Iconify icon={option.icon} sx={{borderRadius: 0.65, width: 28}}/> {option.label}
					</Stack>
				</MenuItem>))}
			</RHFSelect>
			<RHFTextField name="legalDetails.taxId" label={t('fields.taxId')}/>
			<RHFTextField name="name" label={t('fields.name')}/>
			<RHFTextField name="address.street" label={t('fields.address')}/>
			<RHFTextField name="address.city" label={t('fields.city')}/>
			<RHFTextField name="address.county" label={t('fields.county')}/>
			<RHFAutocomplete
				name="address.country"
				label={t('fields.country')}
				options={countries.map((country) => country.label)}
				getOptionLabel={(option) => option}
				isOptionEqualToValue={(option, value) => option === value}
				renderOption={(props, option) => {
					const {code, label, phone} = countries.filter((country) => country.label === option)[0];

					if (!label) {
						return null;
					}

					return (<li {...props} key={label}>
						<Iconify
							key={label}
							icon={`circle-flags:${code.toLowerCase()}`}
							width={28}
							sx={{mr: 1}}
						/>
						{label} ({code}) +{phone}
					</li>);
				}}
			/>
		</Stack>
		{!stepper && (<Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 3}}>
			<Button
				color="inherit"
				size="large"
				type="submit"
				variant="contained"
				onClick={submit}
			>
				{t('buttons.update')}
			</Button>
		</Box>)}
		{stepper && (<Box sx={{display: 'flex', flexDirection: 'row', pt: 2, mt: 3}}>
			<Button
				size="large"
				variant="outlined"
				disabled={!hasBack}
				onClick={handleBack}
				sx={{mr: 1}}
			>
				{t('buttons.back')}
			</Button>
			<Box sx={{flex: '1 1 auto'}}/>
			<LoadingButton
				color="inherit"
				size="large"
				type="submit"
				variant="contained"
				onClick={submit}
				isLoading={methods.formState.isSubmitting}
			>
				{organisationData ? t('buttons.next') : t('buttons.create')}
			</LoadingButton>
		</Box>)}
	</FormProvider>);
}

OrganisationDetails.propTypes = {
	stepper: PropTypes.bool,
	handleNext: PropTypes.func,
	handleBack: PropTypes.func,
	setErrorMsg: PropTypes.func,
	hasBack: PropTypes.bool,
	organisationData: PropTypes.object,
	setOrganisationData: PropTypes.func,
	setConfigurationData: PropTypes.func,
	configurationData: PropTypes.object,
};

export default OrganisationDetails;
