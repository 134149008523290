import React from 'react';
import {Helmet} from "react-helmet-async";
import AppointmentCreatedTemplateSmsView from "../../../../sections/templates/sms/AppointmentCreatedTemplateSmsView";
import {useLocales} from "../../../../locales";

export default function AppointmentCreatedTemplateSmsPage() {
	const  { t } = useLocales();
	return (
		<>
			<Helmet>
				<title>{t('seo.appointmentConfirmationTemplate.title')} - {t('appName')}</title>
			</Helmet>

			<AppointmentCreatedTemplateSmsView/>
		</>
	);
};
