import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import {useLocales} from "../../locales";

// ----------------------------------------------------------------------

export default function SearchNotFound({ query, sx, ...other }) {
    const {t} = useLocales();
  return query ? (
    <Paper
      sx={{
        bgcolor: 'unset',
        textAlign: 'center',
        ...sx,
      }}
      {...other}
    >
      <Typography variant="h6" gutterBottom>
          {t('titles.notFound')}
      </Typography>

      <Typography variant="body2">
          {t('subtitles.noResultsFor')} &nbsp;
        <strong>&quot;{query}&quot;</strong>.
        <br /> {t('subtitles.noResultsTips')}
      </Typography>
    </Paper>
  ) : (
    <Typography variant="body2" sx={sx}>
      Please enter keywords
    </Typography>
  );
}

SearchNotFound.propTypes = {
  query: PropTypes.string,
  sx: PropTypes.object,
};
