import parsePhoneNumberFromString from "libphonenumber-js";

export const acceptedSMSCountries = [
	{ country: 'United Arab Emirates', isoCountry: 'AE' },
	{ country: 'Antigua and Barbuda', isoCountry: 'AG' },
	{ country: 'Albania', isoCountry: 'AL' },
	{ country: 'Angola', isoCountry: 'AO' },
	{ country: 'Antarctica', isoCountry: 'AQ' },
	{ country: 'Argentina', isoCountry: 'AR' },
	{ country: 'Austria', isoCountry: 'AT' },
	{ country: 'Australia', isoCountry: 'AU' },
	{ country: 'Aland Islands', isoCountry: 'AX' },
	{ country: 'Bosnia and Herzegovina', isoCountry: 'BA' },
	{ country: 'Belgium', isoCountry: 'BE' },
	{ country: 'Bahrain', isoCountry: 'BH' },
	{ country: 'Brunei', isoCountry: 'BN' },
	{ country: 'Brazil', isoCountry: 'BR' },
	{ country: 'Bahamas', isoCountry: 'BS' },
	{ country: 'Botswana', isoCountry: 'BW' },
	{ country: 'Canada', isoCountry: 'CA' },
	{ country: 'Cocos (Keeling) Islands', isoCountry: 'CC' },
	{ country: 'Switzerland', isoCountry: 'CH' },
	{ country: 'Chile', isoCountry: 'CL' },
	{ country: 'China', isoCountry: 'CN' },
	{ country: 'Colombia', isoCountry: 'CO' },
	{ country: 'Costa Rica', isoCountry: 'CR' },
	{ country: 'Cuba', isoCountry: 'CU' },
	{ country: 'Christmas Island', isoCountry: 'CX' },
	{ country: 'Cyprus', isoCountry: 'CY' },
	{ country: 'Czech Republic', isoCountry: 'CZ' },
	{ country: 'Germany', isoCountry: 'DE' },
	{ country: 'Denmark', isoCountry: 'DK' },
	{ country: 'Dominican Republic', isoCountry: 'DO' },
	{ country: 'Estonia', isoCountry: 'EE' },
	{ country: 'Spain', isoCountry: 'ES' },
	{ country: 'Finland', isoCountry: 'FI' },
	{ country: 'Micronesia', isoCountry: 'FM' },
	{ country: 'Faroe Islands', isoCountry: 'FO' },
	{ country: 'France', isoCountry: 'FR' },
	{ country: 'United Kingdom', isoCountry: 'GB' },
	{ country: 'Guernsey', isoCountry: 'GG' },
	{ country: 'Gibraltar', isoCountry: 'GI' },
	{ country: 'Greenland', isoCountry: 'GL' },
	{ country: 'Greece', isoCountry: 'GR' },
	{ country: 'Guam', isoCountry: 'GU' },
	{ country: 'Hong Kong', isoCountry: 'HK' },
	{ country: 'Honduras', isoCountry: 'HN' },
	{ country: 'Croatia', isoCountry: 'HR' },
	{ country: 'Hungary', isoCountry: 'HU' },
	{ country: 'Ireland', isoCountry: 'IE' },
	{ country: 'Isle of Man', isoCountry: 'IM' },
	{ country: 'India', isoCountry: 'IN' },
	{ country: 'Iceland', isoCountry: 'IS' },
	{ country: 'Italy', isoCountry: 'IT' },
	{ country: 'Jersey', isoCountry: 'JE' },
	{ country: 'Japan', isoCountry: 'JP' },
	{ country: 'Korea Republic of', isoCountry: 'KR' },
	{ country: 'Liechtenstein', isoCountry: 'LI' },
	{ country: 'Lithuania', isoCountry: 'LT' },
	{ country: 'Luxembourg', isoCountry: 'LU' },
	{ country: 'Latvia', isoCountry: 'LV' },
	{ country: 'Moldova', isoCountry: 'MD' },
	{ country: 'Macedonia', isoCountry: 'MK' },
	{ country: 'Macao', isoCountry: 'MO' },
	{ country: 'Northern Mariana Islands', isoCountry: 'MP' },
	{ country: 'Malta', isoCountry: 'MT' },
	{ country: 'Mexico', isoCountry: 'MX' },
	{ country: 'Namibia', isoCountry: 'NA' },
	{ country: 'Norfolk Island', isoCountry: 'NF' },
	{ country: 'Nicaragua', isoCountry: 'NI' },
	{ country: 'Netherlands', isoCountry: 'NL' },
	{ country: 'Norway', isoCountry: 'NO' },
	{ country: 'New Zealand', isoCountry: 'NZ' },
	{ country: 'Poland', isoCountry: 'PL' },
	{ country: 'Puerto Rico', isoCountry: 'PR' },
	{ country: 'Portugal', isoCountry: 'PT' },
	{ country: 'Palau', isoCountry: 'PW' },
	{ country: 'Paraguay', isoCountry: 'PY' },
	{ country: 'Romania', isoCountry: 'RO' },
	{ country: 'Solomon Islands', isoCountry: 'SB' },
	{ country: 'Seychelles', isoCountry: 'SC' },
	{ country: 'Sweden', isoCountry: 'SE' },
	{ country: 'Singapore', isoCountry: 'SG' },
	{ country: 'Svalbard and Jan Mayen', isoCountry: 'SJ' },
	{ country: 'Slovakia', isoCountry: 'SK' },
	{ country: 'San Marino', isoCountry: 'SM' },
	{ country: 'El Salvador', isoCountry: 'SV' },
	{ country: 'Swaziland', isoCountry: 'SZ' },
	{ country: 'Thailand', isoCountry: 'TH' },
	{ country: 'Turkey', isoCountry: 'TR' },
	{ country: 'Taiwan', isoCountry: 'TW' },
	{ country: 'United States', isoCountry: 'US' },
	{ country: 'Uruguay', isoCountry: 'UY' },
	{ country: 'Venezuela', isoCountry: 'VE' },
	{ country: 'Virgin Islands, U.S.', isoCountry: 'VI' },
	{ country: 'South Africa', isoCountry: 'ZA' }
];

export const canSendSms = (phoneNumber) => {
	const phone = parsePhoneNumberFromString(phoneNumber);
	if (phone) {
		const countryISO = phone.country;
		return acceptedSMSCountries.some(
			(country) => country.isoCountry === countryISO
		);
	}
	return null;
}
