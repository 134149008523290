import {m} from 'framer-motion';
// @mui
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
// hooks
import {useBoolean} from 'src/hooks/use-boolean';
// _mock
// components
import {varHover} from 'src/components/animate';
import AiChatView from "./AiChat";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import Button from "@mui/material/Button";
import {useContext, useState} from "react";
import axios, {endpoints} from "../../utils/axios";
import {useLocales} from "../../locales";
import {AuthContext} from "../../auth/context/jwt";
import {canUseFeature} from "../../utils/feature-control";
import {Features} from "../../assets/data/features";

const prompt = async (message, history) => {
	const response = await axios.post(endpoints.assistant.prompt, { message, history });
	return response.data;
}

// ----------------------------------------------------------------------
const Role = {
	System: 'system',
	User: 'user',
}

export default function AiAssistant() {
	const {features} = useContext(AuthContext);
	const {t} = useLocales();
	const canUseAssistant = canUseFeature(features, Features.Assistant);
	const drawer = useBoolean();

	const [messages, setMessages] = useState([
		{ role: Role.System, content: t('components.assistant.welcome') },
	]);

	const sendPrompt = async (message) => {
		setMessages((prevMessages) => [
			...prevMessages,
			{ role: Role.User, content: message },
		]);
		try {
			const answer = await prompt(message, messages);
			setMessages((prevMessages) => [
				...prevMessages,
				{ role: Role.System, content: answer },
			]);
		} catch(e) {
			if (e.code) {
				setMessages((prevMessages) => [
					...prevMessages,
					{ role: Role.System, content: t(`errors.${e.code}`) },
				]);
			}
		}

	}

	const renderHead = (
		<Stack direction="row" alignItems="center" sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
			<Typography variant="h6" sx={{ flexGrow: 1 }}>
				{t('buttons.assistant')}
			</Typography>
		</Stack>
	);

	if (!canUseAssistant) {
		return null;
	}

	return (
		<>
			<Button
				component={m.button}
				whileTap="tap"
				whileHover="hover"
				variants={varHover(1.05)}
				color={drawer.value ? 'primary' : 'default'}
				onClick={drawer.onTrue}
				variant="container"
				startIcon={<SupportAgentIcon />}
			>
				{t('buttons.assistant')}
			</Button>

			<Drawer
				open={drawer.value}
				onClose={drawer.onFalse}
				anchor="right"
				maxWidth
				slotProps={{
					backdrop: { invisible: true },
				}}
				PaperProps={{
					sx: { width: 1, maxWidth: 420 },
				}}
				keepMounted
			>
				{renderHead}
				<Divider />
				<AiChatView messages={messages} sendPrompt={sendPrompt}/>
			</Drawer>
		</>
	);
}
