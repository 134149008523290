// @mui
import React from "react";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// hooks
// routes
// locales
import {useLocales} from 'src/locales';
// components
import Label from 'src/components/label';
import LinearProgress from "@mui/material/LinearProgress";
import PropTypes from "prop-types";
import {Features} from "../../assets/data/features";
import Chip from "@mui/material/Chip";
import {AdministrativeRoles, hasRole} from "../../utils/roles";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BusinessIcon from '@mui/icons-material/Business';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import {useAuthContext} from "../../auth/hooks";

// ----------------------------------------------------------------------


export default function NavUpgrade() {
	const {user, organisation, features, subscription} = useAuthContext();
	const {t} = useLocales();

	const canSeeSubscriptionStatus = hasRole(user?.role, AdministrativeRoles);

	return (
		<Stack
			sx={{
				px: 2,
				py: 5,
				textAlign: 'center',
			}}
		>
			<Stack alignItems="center">
				<Stack spacing={0.5} sx={{mb: 2}}>
					<Chip icon={<BusinessIcon/>} label={organisation?.name} color="primary"/>
					<Chip icon={<AccountCircleIcon/>} label={user?.name} variant="outlined"/>
					<Chip icon={<VerifiedUserIcon/>} label={t(`roles.${user?.role?.toLowerCase()}.title`)}
					      variant="outlined"/>
				</Stack>


				{canSeeSubscriptionStatus && (
					<>
						<Box sx={{m: 2}}>
							<Label
								color="primary"
								variant="filled"
							>
								{t('components.subscriptionQuotaBar.subscription')}: {t(`plans.${subscription?.plan?.title.toLowerCase()}.title`)}
							</Label>
						</Box>
						<StatusQuota features={features} feature={Features.SendEmail} title={t('components.subscriptionQuotaBar.emails')}/>
						<StatusQuota features={features} feature={Features.SendSms} title={t('components.subscriptionQuotaBar.sms')}/>
						<StatusQuota features={features} feature={Features.AddAppointment} title={t('components.subscriptionQuotaBar.appointments')}/>
						<StatusQuota features={features} feature={Features.AddCustomer} title={t('components.subscriptionQuotaBar.customers')}/>
						<StatusQuota features={features} feature={Features.AddEmployee} title={t('components.subscriptionQuotaBar.employees')}/>
						<StatusQuota features={features} feature={Features.AddService} title={t('components.subscriptionQuotaBar.services')}/>
					</>
				)}


				{/*
        <Button variant="contained" href={paths.minimalUI} target="_blank" rel="noopener">
          {t('upgrade_to_pro')}
        </Button>
          */}
			</Stack>
		</Stack>
	);
}

const getPercentage = (quota, limit) => {
	const value = (quota / limit) * 100;
	if (value > 100) return 100;
	return Math.floor(value);
}

function Quota({quota, limit}) {
	const percetange = getPercentage(quota, limit);
	const color = percetange > 80 ? 'error' : 'primary';
	return <LinearProgress
		variant="determinate"
		value={percetange}
		color={color}
	/>
}

Quota.propTypes = {
	quota: PropTypes.number,
	limit: PropTypes.number,
};

function StatusQuota({features, feature, title}) {
	const quotaData = features?.find(f => f.feature === feature)

	if (!quotaData || !quotaData?.limit) {
		return null;
	}

	const quota = quotaData?.count;
	const limit = quotaData?.limit;

	return (
		<Box sx={{width: '100%'}}>
			<Box sx={{display: 'flex', alignItems: 'center'}}>
				<Box sx={{mr: 1, minWidth: 90}}>
					<Typography variant="body2" color="text.secondary">{title}</Typography>
				</Box>
				<Box sx={{width: 90, mr: 1}}>
					<Quota quota={quota} limit={limit}/>
				</Box>
				<Box sx={{mr: 1}}>
					<Typography variant="body2"
					            color="text.secondary">{quota}/<strong>{limit}</strong></Typography>
				</Box>
			</Box>
		</Box>
	)
}

StatusQuota.propTypes = {
	features: PropTypes.array,
	feature: PropTypes.string,
	title: PropTypes.string,
}
