import PropTypes from 'prop-types';
import {useCallback, useState} from 'react';
import Stack from '@mui/material/Stack';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import Iconify from 'src/components/iconify';
import * as Yup from 'yup';

// ----------------------------------------------------------------------

export default function ChatMessageInput({
	                                         handleSendMessage,
	                                         //
	                                         loading,
                                         }) {

	const [message, setMessage] = useState('');
	;
	const handleChangeMessage = useCallback((event) => {
		setMessage(event.target.value);
	}, []);

	const [errorMessage, setErrorMessage] = useState(null)
	const messageSchema = Yup.string().required().min(1).max(1000);

	const validateMessage = useCallback(async (m) => {
		try {
			await messageSchema.validate(m);
			return true;
		} catch (error) {
			console.error(error);
			return false;
		}
	}, [messageSchema]);

	const submitMessage = async () => {
		if (!loading && await validateMessage(message)) {
			handleSendMessage(message);
			setMessage('');
		}
	}

	const handleMessage = useCallback(
		async (event) => {
			try {
				if (!loading && event.key === 'Enter') {
					if (await validateMessage(message)) {
						handleSendMessage(message);
						setMessage('');
					}
				}
			} catch (error) {
				console.error(error);
			}
		},
		[message, handleSendMessage, loading, validateMessage]
	);

	return (
		<InputBase
			value={message}
			onKeyUp={handleMessage}
			onChange={handleChangeMessage}
			placeholder="Type a message"
			error={!!errorMessage}
			endAdornment={
				<Stack direction="row" sx={{flexShrink: 0}}>
					<IconButton onClick={() => submitMessage()} disabled={loading}>
						<Iconify icon="solar:reply-2-bold"/>
					</IconButton>
				</Stack>
			}
			sx={{
				px: 1,
				height: 56,
				flexShrink: 0,
				borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
			}}
			helperText={errorMessage}
		/>
	);
}

ChatMessageInput.propTypes = {
	handleSendMessage: PropTypes.func,
	loading: PropTypes.bool,
};
