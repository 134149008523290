import React from 'react';
import {Helmet} from "react-helmet-async";
import InvitationView from "../../sections/invitation/InvitationView";

function InvitationPage(props) {
	return (
		<>
			<Helmet>
				<title>Invitation</title>
			</Helmet>

			<InvitationView/>
		</>
	);
}

export default InvitationPage;
