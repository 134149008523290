import React from 'react';
import Stack from "@mui/material/Stack";
import FormProvider, {RHFTextField} from "../../../../components/hook-form";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {enqueueSnackbar} from "notistack";
import PropTypes from "prop-types";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import {useLocales} from "../../../../locales";
import Divider from "@mui/material/Divider";
import SmsTemplatePreview from "./SmsTemplatePreview";

const maxLength = 250;


const variables = [
	{ label: 'variables.customerName', value: "{{customer.name}}" },
	{ label: 'variables.customerEmail', value: "{{customer.email}}" },
	{ label: 'variables.customerPhoneNumber', value: "{{customer.phoneNumber}}" },
	{ label: 'variables.employeeName', value: "{{employee.name}}" },
	{ label: 'variables.employeeEmail', value: "{{employee.email}}" },
	{ label: 'variables.employeePhoneNumber', value: "{{employee.phoneNumber}}" },
	{ label: 'variables.appointmentDate', value: "{{appointment.date}}" },
	{ label: 'variables.appointmentTime', value: "{{appointment.time}}" },
	{ label: 'variables.serviceName', value: "{{service.name}}" },
	{ label: 'variables.serviceDuration', value: "{{service.duration}}" },
	{ label: 'variables.servicePrice', value: "{{service.price}}" },
	{ label: 'variables.serviceCurrency', value: "{{service.currency}}" },
	{ label: 'variables.serviceLocation', value: "{{service.location}}" },
	{ label: 'variables.organisation.name', value: "{{organisation.name}}" },
	{ label: 'variables.organisationAddressStreet', value: "{{organisation.address.street}}" },
	{ label: 'variables.organisationAddressCity', value: "{{organisation.address.city}}" },
	{ label: 'variables.organisationAddressCounty', value: "{{organisation.address.county}}" },
	{ label: 'variables.organisationAddressCountry', value: "{{organisation.address.country}}" },
];

const AppointmentSmsTemplate = ({
	                                handleUpdate, defaultValues,
                                }) => {
	const { t } = useLocales();
	const Schema = Yup.object().shape({
		body: Yup.string().required().max(maxLength), closing: Yup.string(),
	});

	const methods = useForm({
		resolver: yupResolver(Schema), defaultValues
	});

	const {
		handleSubmit,
		watch
	} = methods;

	const bodyValue = watch('body');

	const onSubmit = handleSubmit(async (data) => handleUpdate(data), (errors) => {
		console.error(errors);
	});

	return (<FormProvider methods={methods} onSubmit={onSubmit}>
		<Stack spacing={3}>
			<Box>
				<Typography variant="h5" sx={{m: 0, p:0}}>{t('titles.variables')}</Typography>
				<Typography variant="caption" sx={{pb: 0}}>{t('subtitles.variables')}</Typography>
			</Box>
			<Grid container spacing={1} sx={{mb: 2, pt: 0}}>
				{variables.map((variable) => (
					<Grid item>
						<Chip label={t(variable.label)} key={variable.value} onClick={() => {
							navigator.clipboard.writeText(variable.value);
							enqueueSnackbar(t('snackbars.variableCopy', { label: t(variable.label) }), {variant: 'success'});
						}}/>
					</Grid>
				))}
			</Grid>
			<RHFTextField
				multiline
				maxRows={10}
				name="body"
				label={t('fields.body')}
				maxChars={maxLength}
			/>
			<LoadingButton variant="contained" type="submit">{t('buttons.update')}</LoadingButton>
			<Divider>{t('titles.previewTemplate')}</Divider>
			<SmsTemplatePreview body={bodyValue}/>
		</Stack>
	</FormProvider>);
};
AppointmentSmsTemplate.propTypes = {
	defaultValues: PropTypes.object.isRequired, handleUpdate: PropTypes.func.isRequired,
};

export default AppointmentSmsTemplate;
