import React, {useContext, useMemo} from 'react';
import PropTypes from "prop-types";
import {Grid} from "@mui/material";
import {sanitize} from 'dompurify';
import {AuthContext} from "../../../../auth/context/jwt";
import {primaryPresets} from "../../../../theme/options/presets";
import moment from "moment";
import {useLocales} from "../../../../locales";
import {getEmail, getName, getPhoneNumber, getServiceName, getServicePrice} from "../../../../data/mock";
import {replacePlaceholders} from "../../../../data/template";



export default function EmailTemplatePreview({title, body, closing}) {
	const { currentLang } = useLocales();
	const {configuration} = useContext(AuthContext);
	const logo = configuration?.logo || `https://storage.googleapis.com/bizcalendarkit/email/logo-full-${configuration.themeColorPresets}.png`;
	const color = primaryPresets.find(preset => preset.name === configuration.themeColorPresets).main || primaryPresets[0].main;
	const formattedDate = moment().locale(currentLang.value).format('dddd, D MMMM, HH:mm').toString();

	const data = useMemo(
		() => ({
			employee: {
				name: getName(currentLang.value),
				email: getEmail(currentLang.value),
				phoneNumber: getPhoneNumber(currentLang.value),
			},
			service: {
				name: getServiceName(currentLang.value),
				price: getServicePrice(currentLang.value),
				duration: 60
			},
			customer: {
				name: getName(currentLang.value),
				email: getEmail(currentLang.value),
				phoneNumber: getPhoneNumber(currentLang.value),
			},
			appointment: {
				date: moment().locale(currentLang.value).format('dddd, D MMMM').toString(),
				time: moment().locale(currentLang.value).format('HH:mm').toString(),
			}

		}), [currentLang.value]
	);

	title = replacePlaceholders(title, data);
	body = replacePlaceholders(body, data);
	closing = replacePlaceholders(closing, data);

	return (
		<>
			<table
				className="nl-container"
				width="100%"
				border={0}
				cellPadding={0}
				cellSpacing={0}
				role="presentation"
				style={{
					msoTableLspace: "0pt",
					msoTableRspace: "0pt",
					backgroundColor: "#FFFFFF"
				}}
			>
				<tbody>
				<tr>
					<td>
						<table
							className="row row-1"
							align="center"
							width="100%"
							border={0}
							cellPadding={0}
							cellSpacing={0}
							role="presentation"
							style={{
								msoTableLspace: "0pt",
								msoTableRspace: "0pt",
								backgroundColor: "#132437"
							}}
						>
							<tbody>
							<tr>
								<td>
									<table
										className="row-content stack"
										align="center"
										border={0}
										cellPadding={0}
										cellSpacing={0}
										role="presentation"
										style={{
											msoTableLspace: "0pt",
											msoTableRspace: "0pt",
											backgroundRepeat: "no-repeat",
											backgroundPosition: "center top",
											color: "#000000",
											backgroundImage:
												'url("https://d1oco4z2z1fhwp.cloudfront.net/templates/default/4016/blue-glow_3.jpg")',
											width: 600,
											margin: "0 auto"
										}}
										width={600}
									>
										<tbody>
										<tr>
											<td
												className="column column-1"
												width="100%"
												style={{
													msoTableLspace: "0pt",
													msoTableRspace: "0pt",
													fontWeight: 400,
													textAlign: "left",
													verticalAlign: "top",
													borderTop: 0,
													borderRight: 0,
													borderBottom: 0,
													borderLeft: 0
												}}
											>
												<table
													className="image_block block-1"
													width="100%"
													border={0}
													cellPadding={0}
													cellSpacing={0}
													role="presentation"
													style={{
														msoTableLspace: "0pt",
														msoTableRspace: "0pt"
													}}
												>
													<tbody>
													<tr>
														<td
															className="pad"
															style={{
																paddingBottom: 35,
																paddingLeft: 30,
																paddingRight: 30,
																paddingTop: 35,
																width: "100%"
															}}
														>
															<div
																className="alignment"
																style={{lineHeight: 10}}
															>
																<div
																	style={{
																		display: 'flex',
																		justifyContent: 'center',
																		alignItems: 'center',
																		margin: 0,
																	}}
																>
																	<img
																		src={logo}
																		style={{
																			maxWidth: '100%',
																			height: 'auto',
																		}}
																		width={360}
																		alt="Books Plus"
																		title="Books Plus"
																		height="auto"
																	/>
																</div>
															</div>
														</td>
													</tr>
													</tbody>
												</table>
											</td>
										</tr>
										</tbody>
									</table>
								</td>
							</tr>
							</tbody>
						</table>
						<table
							className="row row-2"
							align="center"
							width="100%"
							border={0}
							cellPadding={0}
							cellSpacing={0}
							role="presentation"
							style={{
								msoTableLspace: "0pt",
								msoTableRspace: "0pt",
								backgroundColor: "#132437"
							}}
						>
							<tbody>
							<tr>
								<td>
									<table
										className="row-content stack"
										align="center"
										border={0}
										cellPadding={0}
										cellSpacing={0}
										role="presentation"
										style={{
											msoTableLspace: "0pt",
											msoTableRspace: "0pt",
											backgroundPosition: "center top",
											color: "#000000",
											backgroundColor: "#ffffff",
											width: 600,
											margin: "0 auto"
										}}
										width={600}
									>
										<tbody>
										<tr>
											<td
												className="column column-1"
												width="100%"
												style={{
													msoTableLspace: "0pt",
													msoTableRspace: "0pt",
													fontWeight: 400,
													textAlign: "left",
													paddingBottom: 10,
													verticalAlign: "top",
													borderTop: 0,
													borderRight: 0,
													borderBottom: 0,
													borderLeft: 0
												}}
											>
												<table
													className="image_block block-1"
													width="100%"
													border={0}
													cellPadding={0}
													cellSpacing={0}
													role="presentation"
													style={{
														msoTableLspace: "0pt",
														msoTableRspace: "0pt"
													}}
												>
													<tbody>
													<tr>
														<td
															style={{
																width: "100%",
															}}
														>
															<div
																style={{lineHeight: 10}}
															>
																<Grid
																	container
																	direction="row"
																	justifyContent="center"
																	alignItems="center"
																>
																	<img
																		src={`https://storage.googleapis.com/bizcalendarkit/email/appointment/appointment-booked-${configuration?.themeColorPresets}.png`}
																		width={360}
																		alt="img"
																	/>
																</Grid>
															</div>
														</td>
													</tr>
													</tbody>
												</table>
											</td>
										</tr>
										</tbody>
									</table>
								</td>
							</tr>
							</tbody>
						</table>
						<table
							className="row row-3"
							align="center"
							width="100%"
							border={0}
							cellPadding={0}
							cellSpacing={0}
							role="presentation"
							style={{
								msoTableLspace: "0pt",
								msoTableRspace: "0pt",
								backgroundColor: color,
								backgroundImage: 'url("")',
								backgroundRepeat: "no-repeat"
							}}
						>
							<tbody>
							<tr>
								<td>
									<table
										className="row-content stack"
										align="center"
										border={0}
										cellPadding={0}
										cellSpacing={0}
										role="presentation"
										style={{
											msoTableLspace: "0pt",
											msoTableRspace: "0pt",
											backgroundColor: "#ffffff",
											color: "#000000",
											width: 600,
											margin: "0 auto"
										}}
										width={600}
									>
										<tbody>
										<tr>
											<td
												className="column column-1"
												width="100%"
												style={{
													msoTableLspace: "0pt",
													msoTableRspace: "0pt",
													fontWeight: 400,
													textAlign: "left",
													verticalAlign: "top",
													borderTop: 0,
													borderRight: 0,
													borderBottom: 0,
													borderLeft: 0
												}}
											>
												<table
													className="heading_block block-1"
													width="100%"
													border={0}
													cellPadding={0}
													cellSpacing={0}
													role="presentation"
													style={{
														msoTableLspace: "0pt",
														msoTableRspace: "0pt"
													}}
												>
													<tbody>
													<tr>
														<td
															className="pad"
															style={{
																paddingBottom: 5,
																paddingTop: 25,
																textAlign: "center",
																width: "100%"
															}}
														>
															<h1
																style={{
																	margin: 0,
																	color: "#555555",
																	direction: "ltr",
																	fontFamily:
																		"Arial, Helvetica Neue, Helvetica, sans-serif",
																	fontSize: 36,
																	fontWeight: "normal",
																	letterSpacing: "normal",
																	lineHeight: "120%",
																	textAlign: "center",
																	marginTop: 0,
																	marginBottom: 0,
																	msoLineHeightAlt: "43.199999999999996px"
																}}
															>
																<strong>{title}</strong>
															</h1>
														</td>
													</tr>
													</tbody>
												</table>
												<table
													className="paragraph_block block-3"
													width="100%"
													border={0}
													cellPadding={0}
													cellSpacing={0}
													role="presentation"
													style={{
														msoTableLspace: "0pt",
														msoTableRspace: "0pt",
														wordBreak: "break-word"
													}}
												>
													<tbody>
													<tr>
														<td
															className="pad"
															style={{
																paddingBottom: 20,
																paddingLeft: 30,
																paddingRight: 30,
																paddingTop: 20
															}}
														>
															<div
																style={{
																	color: "#737487",
																	fontFamily:
																		"Arial, Helvetica Neue, Helvetica, sans-serif",
																	fontSize: 18,
																	lineHeight: "180%",
																	textAlign: "left",
																	msoLineHeightAlt: "32.4px"
																}}
															>
																<p
																	style={{
																		margin: 0,
																		wordBreak: "break-word"
																	}}
																>
																	<div
																		dangerouslySetInnerHTML={{__html: sanitize(body)}}/>
																</p>
															</div>
														</td>
													</tr>
													</tbody>
												</table>
												<table
													className="paragraph_block block-4"
													width="100%"
													border={0}
													cellPadding={0}
													cellSpacing={0}
													role="presentation"
													style={{
														msoTableLspace: "0pt",
														msoTableRspace: "0pt",
														wordBreak: "break-word"
													}}
												>
													<tbody>
													<tr>
														<td
															className="pad"
															style={{
																paddingBottom: 20,
																paddingLeft: 30,
																paddingRight: 30,
																paddingTop: 20
															}}
														>
															<div
																style={{
																	color: "#737487",
																	fontFamily:
																		"Arial, Helvetica Neue, Helvetica, sans-serif",
																	fontSize: 35,
																	fontWeight: 700,
																	lineHeight: "180%",
																	textAlign: "center",
																	msoLineHeightAlt: 63
																}}
															>
																<p
																	style={{
																		margin: 0,
																		wordBreak: "break-word"
																	}}
																>
																	<strong>
																		{formattedDate}
																	</strong>
																</p>
															</div>
														</td>
													</tr>
													</tbody>
												</table>
											</td>
										</tr>
										</tbody>
									</table>
								</td>
							</tr>
							</tbody>
						</table>
						<table
							className="row row-4"
							align="center"
							width="100%"
							border={0}
							cellPadding={0}
							cellSpacing={0}
							role="presentation"
							style={{
								msoTableLspace: "0pt",
								msoTableRspace: "0pt",
								backgroundColor: color
							}}
						>
							<tbody>
							<tr>
								<td>
									<table
										className="row-content stack"
										align="center"
										border={0}
										cellPadding={0}
										cellSpacing={0}
										role="presentation"
										style={{
											msoTableLspace: "0pt",
											msoTableRspace: "0pt",
											backgroundColor: "#ffffff",
											color: "#000000",
											width: 600,
											margin: "0 auto"
										}}
										width={600}
									>
										<tbody>
										<tr>
											<td
												className="column column-1"
												width="100%"
												style={{
													msoTableLspace: "0pt",
													msoTableRspace: "0pt",
													fontWeight: 400,
													textAlign: "left",
													verticalAlign: "top",
													borderTop: 0,
													borderRight: 0,
													borderBottom: 0,
													borderLeft: 0
												}}
											>
												<table
													className="heading_block block-1"
													width="100%"
													border={0}
													cellPadding={0}
													cellSpacing={0}
													role="presentation"
													style={{
														msoTableLspace: "0pt",
														msoTableRspace: "0pt"
													}}
												>
													<tbody>
													<tr>
														<td
															className="pad"
															style={{
																paddingBottom: 5,
																paddingTop: 25,
																textAlign: "center",
																width: "100%"
															}}
														>
															<h2
																style={{
																	margin: 0,
																	color: "#555555",
																	direction: "ltr",
																	fontFamily:
																		"Arial, Helvetica Neue, Helvetica, sans-serif",
																	fontSize: 24,
																	fontWeight: "normal",
																	letterSpacing: "normal",
																	lineHeight: "120%",
																	textAlign: "center",
																	marginTop: 0,
																	marginBottom: 0,
																	msoLineHeightAlt: "28.799999999999997px"
																}}
															>
																<span className="tinyMce-placeholder"/>
															</h2>
														</td>
													</tr>
													</tbody>
												</table>
												<table
													className="paragraph_block block-2"
													width="100%"
													border={0}
													cellPadding={0}
													cellSpacing={0}
													role="presentation"
													style={{
														msoTableLspace: "0pt",
														msoTableRspace: "0pt",
														wordBreak: "break-word"
													}}
												>
													<tbody>
													<tr>
														<td
															className="pad"
															style={{
																paddingBottom: 20,
																paddingLeft: 30,
																paddingRight: 30,
																paddingTop: 20
															}}
														>
															<div
																style={{
																	color: "#737487",
																	fontFamily:
																		"Arial, Helvetica Neue, Helvetica, sans-serif",
																	fontSize: 18,
																	lineHeight: "180%",
																	textAlign: "left",
																	msoLineHeightAlt: "32.4px"
																}}
															>
																<p style={{margin: 0}}>
																	<div
																		dangerouslySetInnerHTML={{__html: sanitize(closing)}}/>
																</p>
															</div>
														</td>
													</tr>
													</tbody>
												</table>
												<table
													className="image_block block-3"
													width="100%"
													border={0}
													cellPadding={0}
													cellSpacing={0}
													role="presentation"
													style={{
														msoTableLspace: "0pt",
														msoTableRspace: "0pt"
													}}
												>
													<tbody>
													<tr>
														<td
															className="pad"
															style={{
																paddingBottom: 35,
																paddingTop: 10,
																width: "100%"
															}}
														>
															<div
																className="alignment"
																style={{lineHeight: 10}}
															>
																<div style={{maxWidth: 541}}>
																	<img
																		src="https://d1oco4z2z1fhwp.cloudfront.net/templates/default/4016/divider.png"
																		style={{
																			display: "block",
																			height: "auto",
																			border: 0,
																			width: "100%"
																		}}
																		width={541}
																		height="auto"
																		alt="img"
																	/>
																</div>
															</div>
														</td>
													</tr>
													</tbody>
												</table>
											</td>
										</tr>
										</tbody>
									</table>
								</td>
							</tr>
							</tbody>
						</table>
						<table
							className="row row-5"
							align="center"
							width="100%"
							border={0}
							cellPadding={0}
							cellSpacing={0}
							role="presentation"
							style={{
								msoTableLspace: "0pt",
								msoTableRspace: "0pt",
								backgroundColor: color
							}}
						>
							<tbody>
							<tr>
								<td>
									<table
										className="row-content stack"
										align="center"
										border={0}
										cellPadding={0}
										cellSpacing={0}
										role="presentation"
										style={{
											msoTableLspace: "0pt",
											msoTableRspace: "0pt",
											backgroundPosition: "center top",
											color: "#000000",
											width: 600,
											margin: "0 auto"
										}}
										width={600}
									>
										<tbody>
										<tr>
											<td
												className="column column-1"
												width="100%"
												style={{
													msoTableLspace: "0pt",
													msoTableRspace: "0pt",
													fontWeight: 400,
													textAlign: "left",
													verticalAlign: "top",
													borderTop: 0,
													borderRight: 0,
													borderBottom: 0,
													borderLeft: 0
												}}
											>
												<table
													className="image_block block-1"
													width="100%"
													border={0}
													cellPadding={0}
													cellSpacing={0}
													role="presentation"
													style={{
														msoTableLspace: "0pt",
														msoTableRspace: "0pt"
													}}
												>
													<tbody>
													<tr>
														<td
															className="pad"
															style={{
																width: "100%",
																paddingRight: 0,
																paddingLeft: 0
															}}
														>
															<div
																className="alignment"
																style={{lineHeight: 10}}
															>
																<div
																	className="fullWidth"
																	style={{maxWidth: 600}}
																>
																	<img
																		src="https://d1oco4z2z1fhwp.cloudfront.net/templates/default/4016/bottom-rounded.png"
																		style={{
																			display: "block",
																			height: "auto",
																			border: 0,
																			width: "100%"
																		}}
																		width={600}
																		height="auto"
																		alt="template img"
																	/>
																</div>
															</div>
														</td>
													</tr>
													</tbody>
												</table>
												<table
													className="text_block block-2"
													width="100%"
													border={0}
													cellPadding={0}
													cellSpacing={0}
													role="presentation"
													style={{
														msoTableLspace: "0pt",
														msoTableRspace: "0pt",
														wordBreak: "break-word"
													}}
												>
													<tbody>
													<tr>
														<td
															className="pad"
															style={{
																paddingBottom: 5,
																paddingLeft: 5,
																paddingRight: 5,
																paddingTop: 30
															}}
														>
															<div style={{fontFamily: "sans-serif"}}>
																<div
																	className=""
																	style={{
																		fontSize: 12,
																		fontFamily:
																			"Arial, Helvetica Neue, Helvetica, sans-serif",
																		msoLineHeightAlt:
																			"14.399999999999999px",
																		color: "#fdfdfd",
																		lineHeight: "1.2"
																	}}
																>
																	<p
																		style={{
																			margin: 0,
																			fontSize: 14,
																			textAlign: "center",
																			msoLineHeightAlt: "16.8px"
																		}}
																	>
                                        <span style={{fontSize: 12}}>
                                          © 2024 BizCalendarKit |
                                          contact@bizcalendarkit.com
                                        </span>
																	</p>
																</div>
															</div>
														</td>
													</tr>
													</tbody>
												</table>
												<table
													className="text_block block-3"
													width="100%"
													border={0}
													cellPadding={0}
													cellSpacing={0}
													role="presentation"
													style={{
														msoTableLspace: "0pt",
														msoTableRspace: "0pt",
														wordBreak: "break-word"
													}}
												>
													<tbody>
													<tr>
														<td
															className="pad"
															style={{
																paddingBottom: 35,
																paddingLeft: 10,
																paddingRight: 10,
																paddingTop: 5
															}}
														>
															<div style={{fontFamily: "sans-serif"}}>
																<div
																	className=""
																	style={{
																		fontSize: 12,
																		fontFamily:
																			"Arial, Helvetica Neue, Helvetica, sans-serif",
																		msoLineHeightAlt:
																			"14.399999999999999px",
																		color: "#ffffff",
																		lineHeight: "1.2"
																	}}
																>
																	<p
																		style={{
																			margin: 0,
																			fontSize: 12,
																			msoLineHeightAlt:
																				"14.399999999999999px"
																		}}
																	>
																		&nbsp;
																	</p>
																</div>
															</div>
														</td>
													</tr>
													</tbody>
												</table>
											</td>
										</tr>
										</tbody>
									</table>
								</td>
							</tr>
							</tbody>
						</table>
					</td>
				</tr>
				</tbody>
			</table>
		</>

	);
};
EmailTemplatePreview.propTypes = {
	title: PropTypes.string,
	body: PropTypes.string,
	closing: PropTypes.string,
};
