import esES from "dayjs/locale/es";
import plPL from "dayjs/locale/pl";
import frFR from "dayjs/locale/fr";
import itIT from "dayjs/locale/it";
import roRO from "dayjs/locale/ro";
import deDE from "dayjs/locale/de";
import viVN from "dayjs/locale/vi";
import zhCN from "dayjs/locale/zh-cn";
import enUS from "dayjs/locale/en-gb";
import arAR from "dayjs/locale/ar";

export const getLocaleDayjs = (lang) => {
    switch (lang) {
        case 'en':
            return enUS;
        case 'es':
            return esES;
        case 'pl':
            return plPL;
        case 'fr':
            return frFR;
        case 'it':
            return itIT;
        case 'ro':
            return roRO;
        case 'de':
            return deDE;
        case 'vi':
            return viVN;
        case 'zh':
            return zhCN;
        case 'ar':
            return arAR;
        default:
            return enUS;
    }
}
