import PropTypes from 'prop-types';
import {useFormContext, Controller} from 'react-hook-form';
// @mui
import {MobileDatePicker} from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from 'dayjs';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {getLocaleDayjs} from "../../utils/dayjs";
import {getPickerLocaleTexts} from "../../utils/pickers";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {useLocales} from "../../locales";

// ----------------------------------------------------------------------

export default function RHFDatePicker({name, helperText, onChange, ...other}) {
	const { currentLang } = useLocales();
	const {control} = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			variant="filled"
			render={({field, fieldState: {error}}) => (
				<LocalizationProvider
					dateAdapter={AdapterDayjs}
					adapterLocale={getLocaleDayjs(currentLang.value)}
					localeText={getPickerLocaleTexts(currentLang.value)}
				>
					<MobileDatePicker
						{...field}
						fullWidth
						slotProps={{textField: {variant: "filled"}}}
						value={field.value || dayjs('')}
						onChange={(newValue) => {
							if (onChange) onChange(newValue);
							field.onChange(newValue)
						}}
						error={!!error}
						helperText={error ? error?.message : helperText}
						{...other}
					/>
				</LocalizationProvider>
			)}
		/>
	);
}

RHFDatePicker.propTypes = {
	helperText: PropTypes.object,
	name: PropTypes.string,
	onChange: PropTypes.func,
};
