import PropTypes from 'prop-types';
import React from 'react';
// @mui
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
// _mock
// components
import {endpoints, fetcher} from "../../utils/axios";
import useSWR from "swr";
import LinearProgress from "@mui/material/LinearProgress";
import CustomAttributeCustomerCreateForm from "./create/CustomAttributeCustomerCreateForm";
import {useLocales} from "../../locales";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

// ----------------------------------------------------------------------

export default function CustomAttributesCustomerQuickEditForm({field, open, handleUpdate, onClose}) {
	const {t} = useLocales();
	const {data: current, isLoading} = useSWR(endpoints.customAttributes.customer.get(field), fetcher);

	if (isLoading) {
		return (
			<Box sx={{width: '100%'}}>
				<LinearProgress/>
			</Box>
		)
	}

	return (
		<Dialog
			fullWidth
			maxWidth={false}
			open={open}
			onClose={onClose}
			PaperProps={{
				sx: {maxWidth: 720},
			}}
		>
			<DialogTitle>{t('modals.customAttributes.customer.edit.title')}</DialogTitle>
			<IconButton
				aria-label="close"
				onClick={onClose}
				sx={(theme) => ({
					position: 'absolute',
					right: 8,
					top: 8,
					color: theme.palette.grey[500],
				})}
			>
				<CloseIcon />
			</IconButton>
			<DialogContent>
				<Box sx={{mb: 2}}>
					<CustomAttributeCustomerCreateForm current={current} handleUpdate={handleUpdate}/>
				</Box>
			</DialogContent>
		</Dialog>
	);
}

CustomAttributesCustomerQuickEditForm.propTypes = {
	field: PropTypes.string,
	onClose: PropTypes.func,
	handleUpdate: PropTypes.func,
	open: PropTypes.bool,
};
