import React from 'react';
import {AddressElement, CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import Dialog from "@mui/material/Dialog";
import PropTypes from "prop-types";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {useAuthContext} from "../../../auth/hooks";
import axios, {endpoints} from "../../../utils/axios";
import {useLocales} from "../../../locales";

const addPaymentMethodToOrganisation = (pmId) => axios.post(endpoints.subscriptions.addNewPaymentMethod, {
	paymentMethodId: pmId,
});

const AddPaymentMethod = ({open, handleClose, onAdded, onCancelled}) => {
	const { t } = useLocales();
	const {user} = useAuthContext();
	const stripe = useStripe();
	const elements = useElements();
	const [isCardComplete, setCardComplete] = React.useState(false);
	const [isAddressComplete, setAddressComplete] = React.useState(false);
	const [loading, setLoading] = React.useState(false);

	const handlePaymentMethod = async () => {
		if (isCardComplete && isAddressComplete) {
			setLoading(true);
			const addressElement = elements.getElement(AddressElement);
			const paymentElement = elements.getElement(CardElement);
			const address = await addressElement.getValue();

			const {error: paymentMethodError, paymentMethod} = await stripe.createPaymentMethod({
				type: 'card',
				card: paymentElement,
				billing_details: {
					...address.value,
					email: user.email,
				},
			});

			if (paymentMethodError) {
				console.error(paymentMethodError.message);
				setLoading(false);
				return;
			}

			await addPaymentMethodToOrganisation(paymentMethod.id);

			if (onAdded) {
				await onAdded(paymentMethod);
				handleClose();
				setLoading(false);
			}
		}
	}

	return (
		<Dialog open={open} fullWidth>
			<DialogContent>
				<Typography variant="h4" align="center" sx={{mt: 3}}><strong>{t('dialogs.titles.paymentMethod')}</strong></Typography>
				<Typography variant="body2" align="center" sx={{mb: 3}}>{t('dialogs.subtitles.paymentMethod')}</Typography>
				<Stack spacing={3} sx={{m: 1}}>
					<CardElement onChange={event => setCardComplete(event.complete)}/>
					<AddressElement options={{mode: 'billing'}} onChange={event => setAddressComplete(event.complete)}/>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button onClick={async () => {
					if (onCancelled) {
						await onCancelled();
					}
					if (!loading) {
						handleClose();
					}
				}}>{t('buttons.cancel')}</Button>
				<LoadingButton
					variant="contained"
					onClick={handlePaymentMethod}
					disabled={!isCardComplete || !isAddressComplete}
					loading={loading}
				>
					{t('buttons.addPaymentMethod')}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};
AddPaymentMethod.propTypes = {
	open: PropTypes.bool,
	handleClose: PropTypes.func,
	onAdded: PropTypes.func,
	onCancelled: PropTypes.func,
};

export default AddPaymentMethod;
