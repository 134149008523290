import React from 'react';
import {Helmet} from "react-helmet-async";
import AppointmentCancellationView from "../../sections/appointments/canncelation";

export default function AppointmentCancellationPage() {
	return (
		<>
			<Helmet>
				<title>Appointment Cancellation - BizCalendarKit</title>
			</Helmet>

			<AppointmentCancellationView />
		</>
	);
};
