import React from 'react';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import Iconify from "../../components/iconify";
import {green, red} from "@mui/material/colors";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import CelebrationIcon from '@mui/icons-material/Celebration';
import moment from "moment";
import {useAuthContext} from "../../auth/hooks";
import {useLocales} from "../../locales";

export default function PricingUpgrade({open, onClose, plan, data}) {
	const { t } = useLocales();
	const {subscription} = useAuthContext();
	const message = t(`components.pricing.${data?.isUpgrade ? "upgradedTo" : "downgradedTo"}`, { plan: t(`plans.${plan.subscription.toLowerCase()}.title`) });
	const hasPending = !!subscription?.pending?.startDate;
	const isFuture = subscription?.pending?.startDate > moment().unix();
	const effectiveDate = hasPending && isFuture && t('components.pricing.startingFromDate', { date: moment(subscription.pending.startDate * 1000).format('D MMM YYYY') });
	return (<Dialog
		fullWidth
		maxWidth="xs"
		open={open}
		onClose={onClose}
	>
		<DialogContent>
			<Container sx={{mt: 5}}>
				{data?.isUpgrade && (
					<Typography align="center" gutterBottom>
						<CelebrationIcon
							sx={{fontSize: 80}}
							color="primary"
						/>
					</Typography>
				)}
				<Typography variant="h5" align="center" gutterBottom>
					{message}
					{" "}
					{effectiveDate}
				</Typography>
				<Box sx={{mt: 4}}>
					{plan?.lists.map((item) => (<Tooltip key={item.text} title={item.tooltip} placement="left">
						<Stack
							key={item}
							spacing={1}
							direction="row"
							alignItems="center"
							sx={{
								typography: 'body2',
							}}
						>
							<Iconify icon="mdi:check-bold" width={16} sx={{mr: 1, color: green[500]}}/>
							{item.text}
						</Stack>
					</Tooltip>))}
					{plan?.notIncluded?.map((item) => (<Tooltip key={item.text} title={item.tooltip} placement="top">
						<Stack
							key={item}
							spacing={1}
							direction="row"
							alignItems="center"
							sx={{
								typography: 'body2',
							}}
						>
							<Iconify icon="ic:baseline-cancel" width={16} sx={{mr: 1, color: red[500]}}/>
							{item.text}
						</Stack>
					</Tooltip>))}
				</Box>
			</Container>
		</DialogContent>

		<DialogActions>
			<Button onClick={onClose}>{t('buttons.close')}</Button>
		</DialogActions>
	</Dialog>);
};
PricingUpgrade.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	plan: PropTypes.object,
	data: PropTypes.object,
};
