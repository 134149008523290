import {Helmet} from 'react-helmet-async';
import {useLocales} from "../../../locales";
import {useAuthContext} from "../../../auth/hooks";
import NoAccessPage from "../../../sections/access/NoAccess";
import EntityCreateContainer from "../../../sections/entity/create/EntityCreateContainer";
import {useParams} from "../../../routes/hook";
import {capitalize} from "@mui/material";
import {formatEntityName} from "../../../sections/entity/create/EntityCreateForm";

export default function EntityNew() {
	const {t} = useLocales();
	const params = useParams();
	const { entity: rawEntity } = params;
	const entity = formatEntityName(rawEntity);

	const authContext = useAuthContext();
	const canAccess = ['ADMIN', 'MANAGER'].includes(authContext?.user?.role);

	if (!canAccess) {
		return (
			<NoAccessPage/>
		);
	}

	return (
		<>
			<Helmet>
				<title>{capitalize(entity)} - {t('appName')}</title>
				<meta name="description" content={t('seo.entitiesAdd.description')}/>
			</Helmet>

			<EntityCreateContainer/>
		</>
	);
}
