import React from 'react';
import {Helmet} from "react-helmet-async";
import AppointmentReminderTemplateSmsView from "../../../../sections/templates/sms/AppointmentReminderTemplateSmsView";
import {useLocales} from "../../../../locales";

export default function AppointmentReminderTemplateSmsPage() {
	const { t } = useLocales();
	return (
		<>
			<Helmet>
				<title>{t('seo.appointmentReminderTemplate.title')} - {t('appName')}</title>
			</Helmet>

			<AppointmentReminderTemplateSmsView/>
		</>
	);
};
