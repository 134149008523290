import axios, {endpoints} from "../../utils/axios";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import FormProvider, {RHFSwitch} from "../hook-form";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import {useLocales} from "../../locales";
import { useSnackbar } from 'src/components/snackbar';

export default function NotificationsForm({ configurationData, update }) {
	const { t } = useLocales();
	const { enqueueSnackbar } = useSnackbar();

	const Schema = Yup.object().shape({
		notifications: Yup.object().shape({
			appointment: Yup.object().shape({
				created: Yup.object().shape({
					email: Yup.boolean(),
					sms: Yup.boolean(),
				}),
				modifications: Yup.object().shape({
					email: Yup.boolean(),
					sms: Yup.boolean(),
				}),
				cancelled: Yup.object().shape({
					email: Yup.boolean(),
					sms: Yup.boolean(),
				}),
				reminder: Yup.object().shape({
					email: Yup.boolean(),
					sms: Yup.boolean(),
				}),
			}),
		}),
	});

	const methods = useForm({
		resolver: yupResolver(Schema), defaultValues: {
			notifications: configurationData?.notifications,
		}
	});

	const {
		handleSubmit,
	} = methods;

	const submit = handleSubmit(async data => {
		try {
			const updatedConfiguration = await axios.patch(endpoints.configurations.update, data);
			update(updatedConfiguration);
			enqueueSnackbar(t('successes.updated'), {variant: 'success'});
		} catch (error) {
			console.error(error);
		}

	}, error => {
	});


	const fields = [
		{field: 'appointmentCreated', name: 'notifications.appointment.created'},
		{field: 'appointmentUpdated', name: 'notifications.appointment.modifications'},
		{field: 'appointmentCancelled', name: 'notifications.appointment.cancelled'},
		{field: 'appointmentReminder', name: 'notifications.appointment.reminder'},
	];

	return (
		<FormProvider methods={methods} onSubmit={submit}>

			<Stack sx={{mt: 5, mb: 2}} spacing={2}>
				{fields.map(({field, name}, index) => (
					<>
						<Stack
							direction="row"
							spacing={2}
						>
							<Stack minWidth={{
								sm: 400,
								lg: 450,
							}}>
								<Typography variant="h6">{t(`fields.${field}`)}</Typography>
								<Typography variant="caption">{t(`subtitles.${field}`)}</Typography>
							</Stack>
							<Stack direction={{
								xs: 'column',
								md: 'row',
								lg: 'row',
							}} spacing={2}>
								<RHFSwitch name={`${name}.email`} label={t('fields.email')}/>
								<RHFSwitch name={`${name}.sms`} label={t('fields.sms')}/>
							</Stack>
						</Stack>

						{fields.length - 1 !== index && <Divider/>}
					</>
				))
				}
			</Stack>
			{ fields.m}
			<Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 3}}>
				<Button
					color="inherit"
					size="large"
					type="submit"
					variant="contained"
					onClick={submit}
				>
					{t('buttons.update')}
				</Button>
			</Box>
		</FormProvider>
	)
}

NotificationsForm.propTypes = {
	configurationData: PropTypes.object,
	update: PropTypes.func,
}
