import {deDE, enUS, esES, frFR, itIT, plPL, roRO, viVN, zhCN} from "@mui/x-date-pickers";


export const getPickerLocaleTexts = (lang) => {
    switch (lang) {
        case 'en':
            return enUS.components.MuiLocalizationProvider.defaultProps.localeText;
        case 'es':
            return esES.components.MuiLocalizationProvider.defaultProps.localeText;
        case 'pl':
            return plPL.components.MuiLocalizationProvider.defaultProps.localeText;
        case 'fr':
            return frFR.components.MuiLocalizationProvider.defaultProps.localeText;
        case 'it':
            return itIT.components.MuiLocalizationProvider.defaultProps.localeText;
        case 'ro':
            return roRO.components.MuiLocalizationProvider.defaultProps.localeText;
        case 'de':
            return deDE.components.MuiLocalizationProvider.defaultProps.localeText;
        case 'vi':
            return viVN.components.MuiLocalizationProvider.defaultProps.localeText;
        case 'zh':
            return zhCN.components.MuiLocalizationProvider.defaultProps.localeText;
        case 'ar':
            return enUS.components.MuiLocalizationProvider.defaultProps.localeText;
        default:
            return enUS.components.MuiLocalizationProvider.defaultProps.localeText;
    }
}
