import React from 'react';
import {Helmet} from "react-helmet-async";
import AppointmentReminderTemplateEmailView
	from "../../../../sections/templates/emails/AppointmentReminderTemplateEmailView";
import {useLocales} from "../../../../locales";

export default function AppointmentReminderTemplateEmailPage() {
	const { t } = useLocales();
	return (
		<>
			<Helmet>
				<title>{t('seo.appointmentReminderTemplate.title')} - {t('appName')}</title>
			</Helmet>

			<AppointmentReminderTemplateEmailView/>
		</>
	);
};
