import React from 'react';
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import LockPersonIcon from '@mui/icons-material/LockPerson';
import {Helmet} from "react-helmet-async";
import {useLocales} from "../../locales";

function NoAccessPage() {
	const { t } = useLocales();
	return (
		<>
			<Helmet>
				<title>You do not have access</title>
			</Helmet>

			<Container
				sx={{
					pt: 4,
					minHeight: 1,
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Stack spacing={2} sx={{
					alignItems: 'center',
					flexWrap: 'wrap',
					mb: 5
				}}>
					<LockPersonIcon sx={{fontSize: 50}}/>
					<Typography variant="h4">{t('titles.noAccess')}</Typography>
					<Typography variant="subtitle1">{t('subtitles.noAccess')}</Typography>
				</Stack>
			</Container>
		</>
	);
}

export default NoAccessPage;
