import { fakerAR, fakerZH_CN, fakerDE, fakerES, faker, fakerFR, fakerIT, fakerPL, fakerRO, fakerVI } from '@faker-js/faker'; // Use 'faker' directly

const translations = {
	serviceName: {
		en: 'Consultation',
		ar: 'استشارة',
		cn: '咨询',
		de: 'Beratung',
		es: 'Consulta',
		fr: 'Consultation',
		it: 'Consultazione',
		pl: 'Konsultacja',
		ro: 'Consultație',
		vi: 'Tư vấn'
	}
};

const getLocaleFaker = (locale) => {
	switch (locale) {
		case 'ar':
			return fakerAR;
		case 'zh-cn':
			return fakerZH_CN;
		case 'de':
			return fakerDE;
		case 'es':
			return fakerES;
		case 'fr':
			return fakerFR;
		case 'it':
			return fakerIT;
		case 'pl':
			return fakerPL;
		case 'ro':
			return fakerRO;
		case 'vi':
			return fakerVI;
		default:
			return faker;
	}
}

export const getName = (locale) => {
	const localeFaker = getLocaleFaker(locale);
	return `${localeFaker.person.firstName()} ${localeFaker.person.lastName()}`;
};

export const getEmail = (locale) => {
	const localeFaker = getLocaleFaker(locale);
	return localeFaker.internet.email();
};

export const getPhoneNumber = (locale) => {
	const localeFaker = getLocaleFaker(locale);
	return localeFaker.phone.number({ style: "international" });
};

export const getServiceName = (locale) => translations.serviceName[locale];

export const getServicePrice = (locale) => {
	const localeFaker = getLocaleFaker(locale);
	return localeFaker.commerce.price();
}

export const getServiceDuration = () => Math.floor(Math.random() * (90 - 10 + 1)) + 10
