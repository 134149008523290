import PropTypes from 'prop-types';
import {useCallback, useEffect, useState} from 'react';
import * as Yup from 'yup';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DialogActions from '@mui/material/DialogActions';
import Typography from "@mui/material/Typography";
// utils
// api
// components
import Iconify from 'src/components/iconify';
import {useSnackbar} from 'src/components/snackbar';
import FormProvider, {RHFAutocomplete, RHFTextField} from 'src/components/hook-form';
import useSWR from "swr";
import axios, {endpoints, fetcher} from "../../utils/axios";
import {useLocales} from "../../locales";
import {DateCalendar} from "@mui/x-date-pickers/DateCalendar";
import {fTimestamp} from "../../utils/format-time";
import {Grid} from "@mui/material";
import Chip from "@mui/material/Chip";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {red} from "@mui/material/colors";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";

// ----------------------------------------------------------------------

const formatTime = (timestamp) => {
	// Create a Date object from the timestamp (assuming the timestamp is in milliseconds)
	const date = moment.unix(timestamp).toDate();

	// Extract hours and minutes
	let hours = date.getHours();
	let minutes = date.getMinutes();

	minutes = minutes < 10 ? `0${minutes}` : minutes;
	hours = hours < 10 ? `0${hours}` : hours;

	// Return the formatted time string
	return `${hours}:${minutes}`;
}

function addMinutesToUnix(timestamp, minutes) {
	return timestamp + minutes * 60;
}

export default function CalendarForm({currentEvent, onClose, createEvent, updateEvent, deleteEvent}) {
	const {t} = useLocales();

	const [shouldSelectHall, setShouldSelectHall] = useState(false);


	const EventSchema = Yup.object().shape({
		title: Yup.string().max(255).required(),
		description: Yup.string().max(1000),
		url: Yup.string().max(100),
		allDay: Yup.boolean(),
		start: Yup.mixed(),
		end: Yup.mixed(),
		dateTime: Yup.mixed().required(),
		service: Yup.object({
			value: Yup.string().required()
		}),
		hall: shouldSelectHall
			? Yup.object({value: Yup.string().required()})
			: Yup.string().nullable(),
		customers: Yup.array().of(Yup.object({
			value: Yup.string().required()
		})).min(1),
		employee: shouldSelectHall
			? Yup.string().nullable()
			: Yup.object({value: Yup.string().required()})
	});

	const methods = useForm({
		resolver: yupResolver(EventSchema),
		defaultValues: {
			...currentEvent,
			customers: currentEvent?.customers?.map(c => ({value: c.id})) || [],
			service: {value: currentEvent?.service},
			hall: {value: currentEvent?.hall},
			employee: {value: currentEvent?.employee},
			date: new Date(),
		},
	});

	const {
		reset,
		watch,
		control,
		handleSubmit,
		formState: {isSubmitting, errors},
		setValue
	} = methods;

	const [openSlotSelect, setOpenSlotSelect] = useState(false);
	const {enqueueSnackbar} = useSnackbar();
	const [employeeParams, setEmployeeParams] = useState({});
	const [hallParams] = useState({});
	const {data: customers} = useSWR([endpoints.customers.find, {params: {active: true}}], fetcher);
	const {data: services} = useSWR([endpoints.services.find, {params: {active: true}}], fetcher);
	const {data: employees} = useSWR([endpoints.employees.find, {params: {active: true, ...employeeParams}}], fetcher);
	const {data: halls} = useSWR([endpoints.halls.find, {params: {active: true, ...hallParams}}], fetcher);
	const [availableSlots, setAvailableSlots] = useState([]);
	const [availableEmployees, setAvailableEmployees] = useState([]);
	const [availableHalls, setAvailableHalls] = useState([]);
	const serviceValue = watch('service');
	const dateValue = watch('date');
	const dateTimeValue = watch('dateTime');
	const employeeValue = watch('employee');
	const hallValue = watch('hall');
	const customersValue = watch('customers');
	const startValue = watch('start');
	const endValue = watch('end');

	const [loadingCalendar, setLoadingCalendar] = useState(false);
	const [loadingSlots, setLoadingSlots] = useState(false);
	const [viewRange, setViewRange] = useState({
		fromDate: moment(new Date()).startOf('month').toDate(),
		toDate: moment(new Date()).endOf('month').toDate()
	});
	const [days, setDays] = useState({});

	const fetch = async (endpoint, config, loader) => {
		loader(true);
		const response = await axios.get(endpoint, config);
		loader(false);
		return response;
	}

	const freeSlots = async (service, date, duration = 60) => {
		const response = await fetch(endpoints.calendar.freeSlots, {
			params: {
				date,
				duration,
				service,
			}
		}, setLoadingSlots);
		return response.data.slots;
	}

	const freeDays = async (service, fromDate, toDate, duration = 60) => {
		const response = await fetch(endpoints.calendar.freeDays, {
			params: {
				fromDate,
				toDate,
				duration,
				service,
			}
		}, setLoadingCalendar);
		return response.data;
	}


	const handleMonthChange = async (date) => {
		const startOfMonth = moment(date).startOf('month').toDate();
		const endOfMonth = moment(date).endOf('month').toDate();
		setViewRange({fromDate: startOfMonth, toDate: endOfMonth});
		setValue('dateTime', null)
	};

	const populateFreeDays = async () => {
		if (serviceValue?.value && viewRange.fromDate && viewRange.toDate) {
			const data = await freeDays(serviceValue?.value, viewRange.fromDate, viewRange.toDate, serviceValue?.duration || 60);
			setDays({...days, ...data});
		}
	}

	useEffect(() => {
		if (serviceValue) {
			setShouldSelectHall(serviceValue.halls?.length > 0);
		}
	}, [serviceValue])

	useEffect(() => {
		async function fetchData() {
			await populateFreeDays();
		}

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [viewRange, serviceValue, employeeValue, hallValue]);

	useEffect(() => {
		if (serviceValue?.value && serviceValue?.duration) {
			setOpenSlotSelect(true);
			setEmployeeParams({
				services: [serviceValue.value]
			})
		}
	}, [serviceValue]);

	useEffect(() => {
		async function fetchData() {
			const date = new Date(dateValue);
			const beginningOfDay = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
			const slots = await freeSlots(serviceValue?.value, beginningOfDay, serviceValue?.duration || 60);
			setAvailableSlots(slots);
			const allEmployees = [...new Set(slots.flatMap(slot => slot.availableEmployees))];
			const allHalls = [...new Set(slots.flatMap(slot => slot.availableHalls))];
			setAvailableEmployees(allEmployees);
			setAvailableHalls(allHalls);
		}

		if (serviceValue?.duration) {
			fetchData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [serviceValue, dateValue, employeeValue, hallValue]);

	useEffect(() => {
		if (serviceValue?.label && (employeeValue?.label || hallValue?.label)) {
			setValue(
				'title',
				`${serviceValue?.capacity < 2 && customersValue.length ? `${customersValue?.[0]?.label} | ` : ""}${serviceValue.label} | ${employeeValue ? employeeValue.label : hallValue.label}`
			)
		}
	}, [serviceValue, employeeValue, customersValue, setValue, hallValue]);

	const dateError = startValue && endValue ? startValue > endValue : false;
	const onSubmit = handleSubmit(async (data) => {
		const eventData = {
			id: data?.id,
			customers: data?.customers.map(c => c.value),
			service: data?.service?.value,
			employee: data?.employee?.value,
			color: data?.color,
			title: data?.title,
			description: data?.description,
			allDay: data?.allDay,
			start: data?.dateTime,
			url: data?.url,
			hall: data?.hall?.value,
			end: addMinutesToUnix(data?.dateTime, data?.service?.duration)
		};

		try {
			if (!dateError) {
				if (currentEvent?.id) {
					await updateEvent(eventData);
					enqueueSnackbar('The event has been updated!');
				} else {
					await createEvent(eventData);
					enqueueSnackbar('The event has been created!');
				}
				onClose();
				reset();
			}
		} catch (error) {
			console.error(error);
		}
	});


	const onDelete = useCallback(async () => {
		try {
			await deleteEvent(`${currentEvent?.id}`);
			enqueueSnackbar('Delete success!');
			onClose();
		} catch (error) {
			console.error(error);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentEvent.id, enqueueSnackbar, onClose]);

	/**
	 *
	 * @param field
	 * @param item
	 * @return {undefined|"primary"|"error"}
	 */
	const getSlotColor = (field, item) => {
		if (field.value === item) {
			return "primary";
		}
		if (errors?.dateTime) {
			return "error";
		}
		return undefined;
	}

	return (
		<FormProvider methods={methods} onSubmit={onSubmit}>
			<Stack spacing={3} sx={{px: 3}}>
				<RHFAutocomplete
					name="service"
					label={t('pages.calendar.fields.service')}
					getOptionLabel={(option) => {
						const service = services?.find(c => c.id === option.value);
						if (service) {
							return `${service.name} (${service.duration} minutes)`
						}
						return service?.name || "";
					}}
					isOptionEqualToValue={(option, value) => option.value === value.value}
					options={services?.map(service => ({
						label: service.name,
						value: service.id,
						duration: service.duration,
						price: service.price,
						currency: service.currency,
						capacity: service.capacity,
						halls: service.halls,
					})) || []}
					onChange={() => {
						setValue('dateTime', null);
						setValue('employee', null);
					}}
					renderOption={(props, option) => (
						<li {...props}>
							<Stack>
								<Typography variant="body1">{option.label} ({option.duration} minutes)</Typography>
								{option.price > 0 && <Typography sx={{fontSize: 12}}>
									{option.price} {option.currency}
								</Typography>}
							</Stack>
						</li>
					)}
					helperText={errors?.service?.message}
				/>
				{openSlotSelect && (
					<Grid sx={{
						backgroundColor: errors?.dateTime ? red[50] : "rgba(145, 158, 171, 0.08)",
						padding: 1.5,
						borderRadius: 1,
						height: 430
					}}>
						<Controller
							name="date"
							control={control}
							render={({field, fieldState}) => (
								<DateCalendar
									{...field}
									loading={loadingCalendar}
									renderLoading={() => (
										<CircularProgress/>
									)}
									disablePast
									shouldDisableDate={(date) => {
										if (date instanceof Date) {
											const isAvailable = days[date?.toISOString().split('T')[0]];
											return !isAvailable;
										}
										return true;
									}}
									onMonthChange={handleMonthChange}
									onChange={async (newValue) => {
										if (newValue) {
											setValue('dateTime', null);
											field.onChange(fTimestamp(newValue));
										}
									}}
								/>
							)}
						/>

						{errors?.dateTime && (
							<Grid container justifyContent="center" sx={{mb: 2}}>
								<Typography color="error"
								            variant="body2">{errors.dateTime.message}</Typography>
							</Grid>
						)}

						<Controller
							name="dateTime"
							control={control}
							render={({field}) => (
								loadingSlots || loadingCalendar ? (<Grid container spacing={1} justifyContent="center">
										{Array.from({length: 8}).map((_) => (
											<Grid item>
												<Skeleton variant="rectangle" width={56.59} height={32}
												          sx={{borderRadius: 1}}/>
											</Grid>
										))}
									</Grid>) :
									<Grid container spacing={1} justifyContent="center"
									>
										{availableSlots?.filter(slot => {
											if (!employeeValue?.value) return true;
											return slot.availableEmployees.includes(employeeValue.value);
										}).map(item => (
											<Grid item>
												<Chip
													icon={field.value === item.start ? <CheckCircleIcon/> : undefined}
													color={getSlotColor(field, item.start)}
													label={formatTime(item.start)}
													onClick={() => {
														if (field.value === item.start) {
															field.onChange(null);
															return;
														}
														field.onChange(item.start);
														setAvailableEmployees(item.availableEmployees);
														setAvailableHalls(item.availableHalls);
													}}/>
											</Grid>
										))}
										{!availableSlots || !availableSlots?.length && (
											<Grid item>
												<Chip
													color="error"
													label={t('pages.calendar.fields.errors.noSlots')}
												/>
											</Grid>
										)}
									</Grid>


							)}
						/>
					</Grid>
				)}
				{shouldSelectHall && <RHFAutocomplete
					name="hall"
					label={t('fields.halls')}
					getOptionLabel={(option) => {
						const hall = halls?.find(c => c.id === option.value);
						return hall?.name || "";
					}}
					onChange={(event, newSelection) => {
						const newHallId = newSelection?.value;
						const selectedSlot = availableSlots.find(slot => slot.start === dateTimeValue);
						if (selectedSlot && !selectedSlot.availableHalls.includes(newHallId)) {
							setValue('dateTime', null)
						}
					}}
					isOptionEqualToValue={(option, value) => option.value === value.value}
					options={halls?.filter(hall => {
						if (!dateTimeValue) return true;
						return availableHalls.includes(hall.id);
					})
						.map(hall => ({
							label: hall.name,
							value: hall.id,
							location: hall.location
						})) || []}
					renderOption={(props, option) => (
						<li {...props}>
							<Stack>
								<Typography variant="body1">{option.label}</Typography>
								<Typography sx={{fontSize: 12}}>
									{option.location}
								</Typography>
							</Stack>
						</li>
					)}
				/>}
				<RHFAutocomplete
					name="employee"
					label={t('pages.calendar.fields.employee')}
					getOptionLabel={(option) => {
						const employee = employees?.find(c => c.id === option.value);
						return employee?.name || "";
					}}
					onChange={(event, newSelection) => {
						const newEmployeeId = newSelection?.value;
						const selectedSlot = availableSlots.find(slot => slot.start === dateTimeValue);
						if (selectedSlot && !selectedSlot.availableEmployees.includes(newEmployeeId)) {
							setValue('dateTime', null)
						}
					}}
					isOptionEqualToValue={(option, value) => option.value === value.value}
					options={employees?.filter(employee => {
						if (!dateTimeValue) return true;
						return availableEmployees.includes(employee.id);
					})
						.map(employee => ({
							label: employee.name,
							value: employee.id,
							phoneNumber: employee.phoneNumber,
							email: employee.email,
						})) || []}
					renderOption={(props, option) => (
						<li {...props}>
							<Stack>
								<Typography variant="body1">{option.label}</Typography>
								<Typography sx={{fontSize: 12}}>
									{option.email}
								</Typography>
								<Typography sx={{fontSize: 12}}>
									{option.phoneNumber}
								</Typography>
							</Stack>
						</li>
					)}
				/>


				<RHFAutocomplete
					multiple
					getOptionDisabled={() => customersValue?.length >= serviceValue?.capacity}
					name="customers"
					label={t('pages.calendar.fields.customers')}
					getOptionLabel={(option) => {
						const customer = customers?.find(c => c.id === option.value);
						return customer?.name || "";
					}}
					isOptionEqualToValue={(option, value) => option.value === value.value}
					options={customers?.map(customer => ({
						label: customer.name,
						value: customer.id,
						phoneNumber: customer.phoneNumber,
						email: customer.email,
					})) || []}
					renderOption={(props, option) => (
						<li {...props}>
							<Stack>
								<Typography variant="body1">{option.label}</Typography>
								<Typography sx={{fontSize: 12}}>
									{option.email}
								</Typography>
								<Typography sx={{fontSize: 12}}>
									{option.phoneNumber}
								</Typography>
							</Stack>
						</li>
					)}
					helperText={errors?.customer?.value?.message || serviceValue?.capacity && t('errors.maxCustomers', {capacity: serviceValue?.capacity})}
				/>
				<RHFTextField name="title" label={t('pages.calendar.fields.title')}/>
				<RHFTextField name="description" label={t('pages.calendar.fields.description')}/>
				<RHFTextField name="url" label={t('fields.url')}/>

			</Stack>

			<DialogActions>
				{!!currentEvent?.id && (
					<Tooltip title="Delete Event">
						<IconButton onClick={onDelete}>
							<Iconify icon="solar:trash-bin-trash-bold"/>
						</IconButton>
					</Tooltip>
				)}

				<Box sx={{flexGrow: 1}}/>

				<Button variant="outlined" color="inherit" onClick={onClose}>
					{t('buttons.cancel')}
				</Button>

				<LoadingButton
					type="submit"
					variant="contained"
					loading={isSubmitting}
					disabled={dateError}
				>
					{currentEvent?.id ? t('pages.calendar.buttons.update') : t('pages.calendar.buttons.add')}
				</LoadingButton>
			</DialogActions>
		</FormProvider>
	);
}

CalendarForm.propTypes = {
	currentEvent: PropTypes.object,
	onClose: PropTypes.func,
	createEvent: PropTypes.func,
	updateEvent: PropTypes.func,
	deleteEvent: PropTypes.func,
};
