import React from 'react';
import Container from "@mui/material/Container";
import AppointmentTemplateView from "./components/AppointmentTemplateView";
import {useSettingsContext} from "../../../components/settings";

export default function AppointmentCreatedTemplateEmailView() {
	const settings = useSettingsContext();

	return (
		<Container maxWidth={settings.themeStretch ? false : 'lg'}>
			<AppointmentTemplateView
				type='email'
				name='appointment-created'
			/>
		</Container>
	);
};
