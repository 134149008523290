import React from 'react';
import {Helmet} from "react-helmet-async";
import PasswordResetView from "../../../sections/password-reset/password-reset-view";
import {useLocales} from "../../../locales";

export default function PasswordResetPage() {
	const { t } = useLocales();
	return (
		<>
			<Helmet>
				<title>{t('seo.resetPassword.title')} - {t('appName')}</title>
				<meta name="description" content={t('seo.resetPassword.description')}/>
			</Helmet>

			<PasswordResetView/>
		</>
	);
};
