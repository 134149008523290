import {Helmet} from 'react-helmet-async';
import {useLocales} from "../../../locales";
import {capitalize} from "@mui/material";
import EntityListView from "../../../sections/entity/EntityListView";
import {useParams} from "../../../routes/hook";
import {formatEntityName} from "../../../sections/entity/create/EntityCreateForm";

export default function EntityListPage() {
	const {t} = useLocales();
	const params = useParams();

	const { entity: rawEntity } = params;
	const entity = formatEntityName(rawEntity);

	return (
		<>
			<Helmet>
				<title>{capitalize(entity)} - {t('appName')}</title>
				<meta name="description" content={t('seo.entities.description')}/>
			</Helmet>
			<EntityListView/>
		</>
	);
}
