import PropTypes from 'prop-types';
import {useEffect} from 'react';
// @mui
import List from '@mui/material/List';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
// hooks
import {useBoolean} from 'src/hooks/use-boolean';
// routes
import {usePathname, useRouter} from 'src/routes/hook';
// components
import Logo from 'src/components/logo';
import SvgColor from 'src/components/svg-color';
import Scrollbar from 'src/components/scrollbar';
//
import NavList from './nav-list';
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import {paths} from "../../../../routes/paths";
import {LoginButton} from "../../../_common";
import {useAuthContext} from "../../../../auth/hooks";
import {useLocales} from "../../../../locales";

// ----------------------------------------------------------------------

export default function NavMobile({ offsetTop, data }) {
    const { t } = useLocales()
    const { logout, authenticated, unauthenticated } = useAuthContext();
    const router = useRouter();
  const pathname = usePathname();

  const nav = useBoolean();

  useEffect(() => {
    if (nav.value) {
      nav.onFalse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <>
      <IconButton
        onClick={nav.onTrue}
        sx={{
          ml: 1,
          ...(offsetTop && {
            color: 'text.primary',
          }),
        }}
      >
        <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
      </IconButton>

      <Drawer
        open={nav.value}
        onClose={nav.onFalse}
        PaperProps={{
          sx: {
            pb: 5,
            width: 260,
          },
        }}
      >
        <Scrollbar>
          <Logo sx={{ mx: 2.5, my: 3 }} />

          <List component="nav" disablePadding>
            {data.map((link) => (
              <NavList key={link.title} item={link} />
            ))}
          </List>
            <Stack
                alignItems="center"
                direction={{ xs: 'column', md: 'row-reverse' }} // Stacks vertically on small screens
                spacing={1}
                sx={{ mt: 3}}
            >
                {authenticated && (
                    <>
                        <Button
                            variant="outlined"
                            onClick={logout}
                        >
                            {t('buttons.logout')}
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => router.push(paths.dashboard.root)}
                        >
                            {t('buttons.dashboard')}
                        </Button>
                    </>
                )}

                {unauthenticated && (
                    <>
                        <Button
                            variant="contained"
                            target="_blank"
                            rel="noopener"
                            href={paths.auth.register}
                        >
                            {t('buttons.createAccount')}
                        </Button>

                        <LoginButton />
                    </>
                )}
            </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}

NavMobile.propTypes = {
  data: PropTypes.array,
  offsetTop: PropTypes.bool,
};
