import PropTypes from 'prop-types';
import {useCallback} from 'react';
// @mui
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
// components
import Iconify from 'src/components/iconify';
import {usePopover} from 'src/components/custom-popover';
import {useLocales} from "../../locales";
import Button from "@mui/material/Button";
import FilterAltIcon from '@mui/icons-material/FilterAlt';

// ----------------------------------------------------------------------

export default function EntityTableToolbar({
	                                           filters,
	                                           onFilters,
	                                           onOpenFilters,
                                           }) {
	const {t} = useLocales();
	const popover = usePopover();

	const handleFilterName = useCallback(
		(event) => {
			onFilters('name', event.target.value);
		},
		[onFilters]
	);

	return (
		<>
			<Stack
				spacing={2}
				alignItems={{xs: 'flex-end', md: 'center'}}
				direction={{
					xs: 'column',
					md: 'row',
				}}
				sx={{
					p: 2.5,
					pr: {xs: 2.5, md: 1},
				}}
			>
				<Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{width: 1}}>
					<TextField
						fullWidth
						value={filters.name}
						onChange={handleFilterName}
						placeholder={t('pages.services.searchBar.placeholder')}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Iconify icon="eva:search-fill" sx={{color: 'text.disabled'}}/>
								</InputAdornment>
							),
						}}
					/>
					<Button
						variant="contained"
						onClick={onOpenFilters}
						startIcon={<FilterAltIcon/>}
					>
						Filters
					</Button>
					<IconButton onClick={popover.onOpen}>
						<Iconify icon="eva:more-vertical-fill"/>
					</IconButton>
				</Stack>
			</Stack>

			{/*
      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose();
          }}
        >
          <Iconify icon="solar:printer-minimalistic-bold" />
          Print
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
          }}
        >
          <Iconify icon="solar:import-bold" />
          Import
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
          }}
        >
          <Iconify icon="solar:export-bold" />
          Export
        </MenuItem>
      </CustomPopover>
      */}
		</>
	);
}

EntityTableToolbar.propTypes = {
	filters: PropTypes.object,
	onFilters: PropTypes.func,
	onOpenFilters: PropTypes.func,
};
