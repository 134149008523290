import PropTypes from 'prop-types';
import React, {useEffect, useState, useCallback} from 'react';
// @mui
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
// components
import Iconify from 'src/components/iconify';
import {Upload} from 'src/components/upload';
import {useLocales} from "../../locales";
import axios, {endpoints} from "../../utils/axios";
import {mutate} from "swr";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// ----------------------------------------------------------------------
const maxFileSizeMb = 100;

export default function FileManagerNewFolderDialog({
	                                                   open,
	                                                   onClose,
	                                                   onCreate,
	                                                   onUpdate,
	                                                   folderName,
	                                                   onChangeFolderName,
	                                                   ...other
                                                   }) {
	const {t} = useLocales();
	const [files, setFiles] = useState([]);
	const [uploading, setUploading] = useState(false);

	useEffect(() => {
		if (!open) {
			setFiles([]);
		}
	}, [open]);

	const handleDrop = useCallback(
		(acceptedFiles) => {
			const newFiles = acceptedFiles.map((file) =>
				Object.assign(file, {
					preview: URL.createObjectURL(file),
					sizeMb: (file.size / (1024 * 1024)).toFixed(2),
					allowed: file.size <= maxFileSizeMb * 1024 * 1024,
				})
			);
			setFiles([...files, ...newFiles]);
		},
		[files]
	);

	const handleUpload = async () => {
		if (!files.length) return;

		try {
			const uploadPromises = files.filter(f => f.allowed).map(async (file) => {
				const formData = new FormData();
				formData.append('file', file);
				setUploading(true)
				const response = await axios.post(endpoints.files.upload, formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				});
				const fileData = response.data;
				await mutate(endpoints.files.find, (data) => [ ...data, fileData], false);

			});

			// Run all uploads in parallel
			await Promise.all(uploadPromises);
		} catch (error) {
			setUploading(false)
			console.error('Error uploading files:', error);
		}
		setUploading(false);
		onClose();
	};


	const handleRemoveFile = (inputFile) => {
		const filtered = files.filter((file) => file !== inputFile);
		setFiles(filtered);
	};

	const handleRemoveAllFiles = () => {
		setFiles([]);
	};

	return (
		<Dialog fullWidth maxWidth="sm" open={open} onClose={onClose} {...other}>
			<DialogTitle sx={{p: (theme) => theme.spacing(3, 3, 2, 3)}}> {t('titles.uploadFiles')} </DialogTitle>
			<Backdrop
				sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={uploading}
			>
				<Box display="flex" flexDirection="column" alignItems="center">
					<CircularProgress/>
					<Typography variant="h6">
						{t('loaders.uploadingFiles')}
					</Typography>
				</Box>
			</Backdrop>
			<DialogContent dividers sx={{pt: 1, pb: 0, border: 'none'}}>
				{(onCreate || onUpdate) && (
					<TextField
						fullWidth
						label="Folder name"
						value={folderName}
						onChange={onChangeFolderName}
						sx={{mb: 3}}
					/>
				)}

				<Upload multiple files={files} onDrop={handleDrop} onRemove={handleRemoveFile}/>
			</DialogContent>

			<DialogActions>
				<Button
					variant="contained"
					startIcon={<Iconify icon="eva:cloud-upload-fill"/>}
					onClick={handleUpload}
					disabled={uploading}
				>
					{t('buttons.upload')}
				</Button>

				{!!files.length && (
					<Button variant="outlined" color="inherit" onClick={handleRemoveAllFiles}>
						{t('buttons.deleteAllFiles')}
					</Button>
				)}

				{(onCreate || onUpdate) && (
					<Stack direction="row" justifyContent="flex-end" flexGrow={1}>
						<Button variant="soft" onClick={onCreate || onUpdate}>
							{onUpdate ? 'Save' : 'Create'}
						</Button>
					</Stack>
				)}
			</DialogActions>
		</Dialog>
	);
}

FileManagerNewFolderDialog.propTypes = {
	folderName: PropTypes.string,
	onChangeFolderName: PropTypes.func,
	onClose: PropTypes.func,
	onCreate: PropTypes.func,
	onUpdate: PropTypes.func,
	open: PropTypes.bool,
};
