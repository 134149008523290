import {useEffect, useState} from "react";
// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import * as Yup from "yup";
//
import CustomAttributeCustomerCreateForm from "./CustomAttributeCustomerCreateForm";
import axios, {endpoints} from "../../../utils/axios";
import {useLocales} from "../../../locales";

export default function CustomAttributesCustomerCreateContainer() {
    const { t } = useLocales();
  const settings = useSettingsContext();

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading={t('pages.entities.add')}
        links={[
          {
            name: t('breadcrumbs.dashboard'),
            href: paths.dashboard.root,
          },
          {
            name: t('breadcrumbs.entities'),
            href: paths.dashboard.customers.root,
          },
          { name: t('pages.entities.title') },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
        <CustomAttributeCustomerCreateForm />
    </Container>
  );
}
