import { Helmet } from 'react-helmet-async';
// sections
import Onboarding from "../sections/onboarding";
import {useLocales} from "../locales";

// ----------------------------------------------------------------------

export default function OnboardingPage() {
	const { t } = useLocales();
	return (
		<>
			<Helmet>
				<title>{t('seo.onboarding.title')} - {t('appName')}</title>
			</Helmet>

			<Onboarding title={t('pages.onboarding.title')} />
		</>
	);
}
