import React, {useState} from 'react';
import BusinessHours from "./BusinessHours";
import PropTypes from "prop-types";
import {useLocales} from "../../locales";

const Configurations = ({
	                        handleNext,
	                        handleBack,
	                        setErrorMsg,
	                        hasBack,
	                        organisationData,
	                        setOrganisationData,
	                        configurationData,
	                        setConfigurationData
                        }) => {
	const {t} = useLocales();
	const [activeStep, setActiveStep] = useState(0);

	const steps = [
		{label: t('components.onboarding.businessHours.label'), component: BusinessHours},
		// {label: t('components.onboarding.customisation.label'), component: Customisation},
	];

	const handleConfigurationNext = () => {
		if (activeStep === steps.length - 1) {
			return handleNext();
		}
		return setActiveStep((prevActiveStep) => prevActiveStep + 1);
	}

	const handleConfigurationBack = () => {
		if (activeStep === 0) {
			return handleBack();
		}
		return setActiveStep((prevActiveStep) => prevActiveStep - 1);
	}

	const renderStepComponent = (step) => {
		const Component = steps[step].component;
		return <Component
			stepper
			handleNext={handleConfigurationNext}
			handleBack={handleConfigurationBack}
			setErrorMsg={setErrorMsg}
			hasBack={hasBack}
			organisationData={organisationData}
			setOrganisationData={setOrganisationData}
			configurationData={configurationData}
			setConfigurationData={setConfigurationData}
		/>;
	};


	return (
		<>
			{renderStepComponent(activeStep)}
		</>
	);
};
Configurations.propTypes = {
	handleNext: PropTypes.func,
	handleBack: PropTypes.func,
	setErrorMsg: PropTypes.func,
	hasBack: PropTypes.bool,
	organisationData: PropTypes.object,
	setOrganisationData: PropTypes.func,
	configurationData: PropTypes.object,
	setConfigurationData: PropTypes.func,
}

export default Configurations;
