import {createContext, useContext} from 'react';

// ----------------------------------------------------------------------

export const PaymentsContext = createContext({});

export const usePaymentsContext = () => {
	const context = useContext(PaymentsContext);

	if (!context) throw new Error('usePaymentsContext must be use inside PaymentsProvider');

	return context;
};
