import * as React from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {useGetEvents} from "../../api/calendar";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import CalendarForm from "../../sections/calendar/calendar-form";
import {useLocales} from "../../locales";
import {useState} from "react";
import CustomersCreateForm from "../../sections/customers/create/CustomersCreateForm";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import useSWR from "swr";
import {endpoints, fetcher} from "../../utils/axios";

export default function SpeedDialActions() {
	const {t} = useLocales();

	const {data: customAttributes} = useSWR(endpoints.customAttributes.customer.find, fetcher, false);

	const [openCreateAppointment, setOpenCreateAppointment] = useState(false);
	const [openCreateCustomer, setOpenCreateCustomer] = useState(false);

	const {
		createEvent,
	} = useGetEvents();

	const actions = [
		{icon: <PersonAddIcon/>, name: t("pages.customers.add"), handler: () => setOpenCreateCustomer(true)},
		{
			icon: <EditCalendarIcon/>,
			name: t('pages.calendar.addAppointment'),
			handler: () => setOpenCreateAppointment(true)
		}
	];

	return (<>

			<Box sx={{position: 'fixed', bottom: 16, right: 16}}>
				<SpeedDial
					ariaLabel="SpeedDial basic example"
					sx={{position: 'absolute', bottom: 16, right: 16}}
					icon={<SpeedDialIcon/>}
				>
					{actions.map((action) => (
						<SpeedDialAction
							key={action.name}
							icon={action.icon}
							tooltipTitle={action.name}
							onClick={() => action.handler()}
						/>
					))}
				</SpeedDial>
			</Box>
			<Dialog
				fullWidth
				maxWidth="xs"
				open={openCreateAppointment}
				onClose={() => setOpenCreateAppointment(false)}
			>
				<IconButton
					aria-label="close"
					onClick={() => setOpenCreateAppointment(false)}
					sx={(theme) => ({
						position: 'absolute',
						right: 8,
						top: 8,
						color: theme.palette.grey[500],
					})}
				>
					<CloseIcon/>
				</IconButton>
				<DialogTitle sx={{minHeight: 76}}>
					{t('pages.calendar.addAppointment')}
				</DialogTitle>

				<CalendarForm
					currentEvent={{}}
					createEvent={createEvent}
					onClose={() => setOpenCreateAppointment(false)}
				/>
			</Dialog>
			<Dialog
				fullWidth
				maxWidth="sm"
				open={openCreateCustomer}
				onClose={() => setOpenCreateCustomer(false)}
				PaperProps={{
					style: {
						overflow: 'hidden',
					},
				}}
			>
				<IconButton
					aria-label="close"
					onClick={() => setOpenCreateCustomer(false)}
					sx={(theme) => ({
						position: 'absolute',
						right: 8,
						top: 8,
						color: theme.palette.grey[500],
					})}
				>
					<CloseIcon/>
				</IconButton>
				<DialogTitle sx={{minHeight: 76}}>
					{t("pages.customers.add")}
				</DialogTitle>
				<CustomersCreateForm removeCard customAttributes={customAttributes}/>
			</Dialog>
		</>
	);
}
