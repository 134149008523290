import { Helmet } from 'react-helmet-async';
import Organisations from "../sections/organisations";
import {useLocales} from "../locales";
// sections

// ----------------------------------------------------------------------

export default function OrganisationsPage() {
	const { t } = useLocales();
	return (
		<>
			<Helmet>
				<title>{t('seo.organisations.title')} - {t('appName')}</title>
				<meta name="description" content={t('seo.organisations.description')}/>
			</Helmet>

			<Organisations/>
		</>
	);
}
