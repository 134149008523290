import React from 'react';
import {Helmet} from "react-helmet-async";
import AppointmentUpdatedTemplateSmsView from "../../../../sections/templates/sms/AppointmentUpdatedTemplateSmsView";
import {useLocales} from "../../../../locales";

export default function AppointmentUpdatedTemplateSmsPage() {
	const  { t } = useLocales()
	return (
		<>
			<Helmet>
				<title>{t('seo.appointmentUpdatedTemplate.title')} - {t('appName')}</title>
			</Helmet>

			<AppointmentUpdatedTemplateSmsView/>
		</>
	);
};
