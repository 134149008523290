import PropTypes from 'prop-types';
// @mui
import {alpha} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
// assets
import {PlanBusinessIcon, PlanFreeIcon, PlanPremiumIcon, PlanStarterIcon} from 'src/assets/icons';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import {green, red} from "@mui/material/colors";
import Tooltip from "@mui/material/Tooltip";
import {useState} from "react";
import PricingPreview from "./PricingPreview";
import PricingUpgrade from "./PricingUpgade";
import {useLocales} from "../../locales";

// ----------------------------------------------------------------------


export default function PricingCard({card, sx, noButton, ...other}) {
	const { t } = useLocales();
	const [previewDialog, setPreviewDialog] = useState(false);
	const [upgradeDialog, setUpgradeDialog] = useState({open: false, isUpgrade: null, paymentDate: null});
	const {subscription, price, caption, lists, button, selected, notIncluded, pending} = card;

	const basic = card.isFree;
	const starter = card.isStartup;
	const business = card.isBusiness;
	const premium = card.isEnterprise;

	const handleSelect = (planId) => setPreviewDialog(true);

	const handleClose = ({updated, isUpgrade, paymentDate}) => {
		setPreviewDialog(false);
		setTimeout(() => {
			if (updated) {
				setUpgradeDialog({open: true, isUpgrade, paymentDate});
			}
		}, 100);
	}

	const renderIcon = (<Stack direction="row" alignItems="center" justifyContent="space-between">
		<Box sx={{width: 48, height: 48}}>
			{basic && <PlanFreeIcon/>}
			{starter && <PlanStarterIcon/>}
			{business && <PlanBusinessIcon/>}
			{premium && <PlanPremiumIcon/>}
		</Box>

		{business && <Label color="info">POPULAR</Label>}
	</Stack>);

	const renderSubscription = (<Stack spacing={1} sx={{ height: 120 }}>
		<Typography variant="h4" sx={{textTransform: 'capitalize'}}>
			{subscription}
		</Typography>
		<Typography variant="subtitle2" sx={{ minHeight: 150}}>{caption}</Typography>
	</Stack>);

	const renderPrice = basic ? (<Typography variant="h2">{t('components.pricing.free')}</Typography>) : (<Stack direction="row">
		<Typography variant="h4">€</Typography>
		<Typography variant="h2">{price}</Typography>

		<Typography
			component="span"
			sx={{alignSelf: 'center', color: 'text.disabled', ml: 1, typography: 'body2'}}
		>
			/ {t('components.pricing.month')}
		</Typography>
	</Stack>);

	const renderList = (<Stack spacing={2}>
		<Stack direction="row" alignItems="center" justifyContent="space-between">
			<Box component="span" sx={{typography: 'overline'}}>
				{t('components.pricing.features')}
			</Box>
		</Stack>

		{lists?.map((item) => (<Tooltip key={item.text} title={item.tooltip} placement="top">
			<Stack
				key={item}
				spacing={1}
				direction="row"
				alignItems="center"
				sx={{
					typography: 'body2',
				}}
			>
				<Iconify icon="mdi:check-bold" width={16} sx={{mr: 1, color: green[500]}}/>
				{item.text}
			</Stack>
		</Tooltip>))}
		{notIncluded?.map((item) => (<Tooltip key={item.text} title={item.tooltip} placement="top">
			<Stack
				key={item}
				spacing={1}
				direction="row"
				alignItems="center"
				sx={{
					typography: 'body2',
				}}
			>
				<Iconify icon="ic:baseline-cancel" width={16} sx={{mr: 1, color: red[500]}}/>
				{item.text}
			</Stack>
		</Tooltip>))}
	</Stack>);

	return (
		<>
			<Stack
				spacing={5}
				sx={{
					p: 5, borderRadius: 2, boxShadow: (theme) => ({
						xs: theme.customShadows.card, md: 'none',
					}), ...(starter && {
						borderTopRightRadius: {md: 0}, borderBottomRightRadius: {md: 0},
					}), ...((starter || premium || business) && {
						boxShadow: (theme) => ({
							xs: theme.customShadows.card,
							md: `-40px 40px 80px 0px ${alpha(theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.common.black, 0.16)}`,
						}),
					}), ...sx,
				}}
				{...other}
			>
				{renderIcon}

				{renderSubscription}

				{renderPrice}

				<Divider sx={{borderStyle: 'dashed'}}/>

				{renderList}

				{ !noButton && (
					<Button
						fullWidth
						size="large"
						variant="contained"
						disabled={selected}
						color={pending ? 'info' : 'primary'}
						onClick={() => {
							if (pending) {
								return;
							}
							handleSelect(card.id)
						}}
					>
						{button}
					</Button>
				) }
			</Stack>
			{previewDialog && (
				<PricingPreview
					open={previewDialog}
					onClose={handleClose}
					plan={card}
					planId={card.id}
				/>
			)}
			{upgradeDialog && (
				<PricingUpgrade
					open={upgradeDialog.open}
					data={upgradeDialog}
					onClose={() => setUpgradeDialog({open: false, isUpgrade: null, paymentDate: null})}
					plan={card}
				/>
			)}
		</>
	);
}

PricingCard.propTypes = {
	card: PropTypes.object, sx: PropTypes.object,
	noButton: PropTypes.bool,
};
