// utils
import {paramCase} from 'src/utils/change-case';
import {_id, _postTitles} from 'src/_mock/assets';

// ----------------------------------------------------------------------

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
	AUTH: '/auth', AUTH_DEMO: '/auth-demo', DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
	invitation: `${ROOTS.DASHBOARD}/invitation`,
	appointment: {
		cancellation: '/appointment/cancellation',
	},
	organisations: '/organisations',
	onboarding: '/onboarding',
	organisation: {
		create: '/organisation/create'
	},
	termsAndConditions: '/terms-and-conditions',
	comingSoon: '/coming-soon',
	maintenance: '/maintenance',
	pricing: '/pricing',
	payment: '/payment',
	about: '/about-us',
	contact: '/contact-us',
	privacyPolicy: '/privacy-policy',
	faqs: '/faqs',
	page403: '/403',
	page404: '/404',
	page500: '/500',
	components: '/components',
	docs: 'https://docs.minimals.cc',
	changelog: 'https://docs.minimals.cc/changelog',
	zoneUI: 'https://mui.com/store/items/zone-landing-page/',
	minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
	freeUI: 'https://mui.com/store/items/minimal-dashboard-free/',
	figma: 'https://www.figma.com/file/kAYnYYdib0aQPNKZpgJT6J/%5BPreview%5D-Minimal-Web.v5.0.0?type=design&node-id=0%3A1&t=Al4jScQq97Aly0Mn-1',
	product: {
		root: `/product`, checkout: `/product/checkout`, details: (id) => `/product/${id}`, demo: {
			details: `/product/${MOCK_ID}`,
		},
	},
	post: {
		root: `/post`, details: (title) => `/post/${paramCase(title)}`, demo: {
			details: `/post/${paramCase(MOCK_TITLE)}`,
		},
	}, // AUTH
	auth: {
		login: `${ROOTS.AUTH}/login`, register: `${ROOTS.AUTH}/register`, resetPassword: `${ROOTS.AUTH}/reset-password`,
	},
	authDemo: {
		classic: {
			login: `${ROOTS.AUTH_DEMO}/classic/login`,
			register: `${ROOTS.AUTH_DEMO}/classic/register`,
			forgotPassword: `${ROOTS.AUTH_DEMO}/classic/forgot-password`,
			newPassword: `${ROOTS.AUTH_DEMO}/classic/new-password`,
			verify: `${ROOTS.AUTH_DEMO}/classic/verify`,
		}, modern: {
			login: `${ROOTS.AUTH_DEMO}/modern/login`,
			register: `${ROOTS.AUTH_DEMO}/modern/register`,
			forgotPassword: `${ROOTS.AUTH_DEMO}/modern/forgot-password`,
			newPassword: `${ROOTS.AUTH_DEMO}/modern/new-password`,
			verify: `${ROOTS.AUTH_DEMO}/modern/verify`,
		},
	}, // DASHBOARD
	dashboard: {
		storage: {
			files: `${ROOTS.DASHBOARD}/storage/files`
		},
		entityConfigurations: {
			root: `${ROOTS.DASHBOARD}/settings/entity-configurations`, new: `${ROOTS.DASHBOARD}/settings/entity-configurations/add`,
		},
		entities: {
			root: (entity) => `${ROOTS.DASHBOARD}/entities/${entity}`, new: (entity) => `${ROOTS.DASHBOARD}/entities/${entity}/add`,
		},
		halls: {
			root: `${ROOTS.DASHBOARD}/halls`, new: `${ROOTS.DASHBOARD}/halls/add`,
		},
		root: ROOTS.DASHBOARD,
		customAttributes: {
			customer: `${ROOTS.DASHBOARD}/settings/custom-attributes/customer`,
			customerAdd: `${ROOTS.DASHBOARD}/settings/custom-attributes/customer/add`,
			service: `${ROOTS.DASHBOARD}/settings/custom-attributes/service`,
			serviceAdd: `${ROOTS.DASHBOARD}/settings/custom-attributes/service/add`,
			employee: `${ROOTS.DASHBOARD}/settings/custom-attributes/employee`,
			employeeAdd: `${ROOTS.DASHBOARD}/settings/custom-attributes/employee/add`,
			hall: `${ROOTS.DASHBOARD}/settings/custom-attributes/hall`,
			hallAdd: `${ROOTS.DASHBOARD}/settings/custom-attributes/hall/add`,
			appointment: `${ROOTS.DASHBOARD}/settings/custom-attributes/appointment`,
			appointmentAdd: `${ROOTS.DASHBOARD}/settings/custom-attributes/appointment/add`,
		},
		clients: {
			root: `${ROOTS.DASHBOARD}/customers`, new: `${ROOTS.DASHBOARD}/customers/add`,
		},
		services: {
			root: `${ROOTS.DASHBOARD}/services`, new: `${ROOTS.DASHBOARD}/services/add`,
		},
		customers: {
			root: `${ROOTS.DASHBOARD}/customers`, new: `${ROOTS.DASHBOARD}/customers/add`,
		},
		employees: {
			root: `${ROOTS.DASHBOARD}/employees`, new: `${ROOTS.DASHBOARD}/employees/add`,
		},
		appointments: `${ROOTS.DASHBOARD}/appointments`,
		settings: {
			entityConfigurations: {
				root: `${ROOTS.DASHBOARD}/settings/entity-configurations`, new: `${ROOTS.DASHBOARD}/settings/entity-configurations/add`,
			},
			entities: {
				root: `${ROOTS.DASHBOARD}/settings/entities`, new: `${ROOTS.DASHBOARD}/settings/entities/add`,
			},
			customAttributes: {
				customer: `${ROOTS.DASHBOARD}/settings/custom-attributes/customer`,
			},
			notifications: `${ROOTS.DASHBOARD}/settings/notifications`,
			organisationDetails: `${ROOTS.DASHBOARD}/settings/organisation-details`,
			businessHours: `${ROOTS.DASHBOARD}/settings/business-hours`,
			customisation: `${ROOTS.DASHBOARD}/settings/customisation`,
			subscription: `${ROOTS.DASHBOARD}/settings/subscription`,
			payment: `${ROOTS.DASHBOARD}/settings/payment`,
		},

		templates: {
			emails: {
				appointment: {
					created: `${ROOTS.DASHBOARD}/templates/emails/appointment/created`,
					reminder: `${ROOTS.DASHBOARD}/templates/emails/appointment/reminder`,
					updated: `${ROOTS.DASHBOARD}/templates/emails/appointment/updated`,
					cancelled: `${ROOTS.DASHBOARD}/templates/emails/appointment/cancelled`,
				},
			},
			sms: {
				appointment: {
					created: `${ROOTS.DASHBOARD}/templates/sms/appointment/created`,
					reminder: `${ROOTS.DASHBOARD}/templates/sms/appointment/reminder`,
					updated: `${ROOTS.DASHBOARD}/templates/sms/appointment/updated`,
					cancelled: `${ROOTS.DASHBOARD}/templates/sms/appointment/cancelled`,
				},
			},
		},


		// Others
		mail: `${ROOTS.DASHBOARD}/mail`,
		chat: `${ROOTS.DASHBOARD}/chat`,
		blank: `${ROOTS.DASHBOARD}/blank`,
		kanban: `${ROOTS.DASHBOARD}/kanban`,
		fileManager: `${ROOTS.DASHBOARD}/file-manager`,
		permission: `${ROOTS.DASHBOARD}/permission`,
		general: {
			app: `${ROOTS.DASHBOARD}/app`,
			ecommerce: `${ROOTS.DASHBOARD}/ecommerce`,
			analytics: `${ROOTS.DASHBOARD}/analytics`,
			banking: `${ROOTS.DASHBOARD}/banking`,
			booking: `${ROOTS.DASHBOARD}/booking`,
			file: `${ROOTS.DASHBOARD}/file`,
		},
		user: {
			root: `${ROOTS.DASHBOARD}/user`,
			new: `${ROOTS.DASHBOARD}/user/new`,
			list: `${ROOTS.DASHBOARD}/user/list`,
			cards: `${ROOTS.DASHBOARD}/user/cards`,
			profile: `${ROOTS.DASHBOARD}/user/profile`,
			account: `${ROOTS.DASHBOARD}/user/account`,
			edit: (id) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
			demo: {
				edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
			},
		},
		product: {
			root: `${ROOTS.DASHBOARD}/product`,
			new: `${ROOTS.DASHBOARD}/product/new`,
			details: (id) => `${ROOTS.DASHBOARD}/product/${id}`,
			edit: (id) => `${ROOTS.DASHBOARD}/product/${id}/edit`,
			demo: {
				details: `${ROOTS.DASHBOARD}/product/${MOCK_ID}`, edit: `${ROOTS.DASHBOARD}/product/${MOCK_ID}/edit`,
			},
		},
		invoice: {
			root: `${ROOTS.DASHBOARD}/invoice`,
			new: `${ROOTS.DASHBOARD}/invoice/new`,
			details: (id) => `${ROOTS.DASHBOARD}/invoice/${id}`,
			edit: (id) => `${ROOTS.DASHBOARD}/invoice/${id}/edit`,
			demo: {
				details: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}`, edit: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}/edit`,
			},
		},
		post: {
			root: `${ROOTS.DASHBOARD}/post`,
			new: `${ROOTS.DASHBOARD}/post/new`,
			details: (title) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}`,
			edit: (title) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}/edit`,
			demo: {
				details: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}`,
				edit: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}/edit`,
			},
		},
		order: {
			root: `${ROOTS.DASHBOARD}/order`, details: (id) => `${ROOTS.DASHBOARD}/order/${id}`, demo: {
				details: `${ROOTS.DASHBOARD}/order/${MOCK_ID}`,
			},
		},
		job: {
			root: `${ROOTS.DASHBOARD}/job`,
			new: `${ROOTS.DASHBOARD}/job/new`,
			details: (id) => `${ROOTS.DASHBOARD}/job/${id}`,
			edit: (id) => `${ROOTS.DASHBOARD}/job/${id}/edit`,
			demo: {
				details: `${ROOTS.DASHBOARD}/job/${MOCK_ID}`, edit: `${ROOTS.DASHBOARD}/job/${MOCK_ID}/edit`,
			},
		},
		tour: {
			root: `${ROOTS.DASHBOARD}/tour`,
			new: `${ROOTS.DASHBOARD}/tour/new`,
			details: (id) => `${ROOTS.DASHBOARD}/tour/${id}`,
			edit: (id) => `${ROOTS.DASHBOARD}/tour/${id}/edit`,
			demo: {
				details: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}`, edit: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}/edit`,
			},
		},
	},
};
