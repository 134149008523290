import {Helmet} from 'react-helmet-async';
// sections
import {PaymentView} from 'src/sections/payment/view';
import {useLocales} from "../../locales";

// ----------------------------------------------------------------------

export default function PaymentPage() {
	const { t } = useLocales();
	return (
		<>
			<Helmet>
				<title>{t('seo.paymentMethods.title')} - {t('appName')}</title>
				<meta name="description" content={t('seo.paymentMethods.description')}/>
			</Helmet>

			<PaymentView/>
		</>
	);
}
