import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
// components
import Scrollbar from 'src/components/scrollbar';
//
import ChatMessageItem from './chat-message-item';
import useMessagesScroll from "./hooks/use-messages-scroll";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import ReactTypingEffect from 'react-typing-effect';

// ----------------------------------------------------------------------

export default function ChatMessageList({messages = [], loading}) {
	const {messagesEndRef} = useMessagesScroll(messages);

	return (
		<Scrollbar ref={messagesEndRef} sx={{px: 3, py: 5, height: 1}}>
			<Box>
				{messages.map((message) => (
					<ChatMessageItem
						key={message.id}
						message={message}
					/>
				))}
				{loading && (
					<Stack direction="row" sx={{mb: 5}} spacing={1}>
						<Avatar>
							<SupportAgentIcon/>
						</Avatar>

						<Stack alignItems="flex-end">
							<Stack
								direction="row"
								alignItems="center"
								sx={{
									position: 'relative',
									'&:hover': {
										'& .message-actions': {
											opacity: 1,
										},
									},
								}}
							>
								<Stack
									sx={{
										p: 1.5,
										maxWidth: 320,
										borderRadius: 1,
										typography: 'body2',
										bgcolor: 'background.neutral'
									}}
								>
									<ReactTypingEffect/>
								</Stack>
							</Stack>
						</Stack>
					</Stack>
				)}
			</Box>
		</Scrollbar>
	);
}

ChatMessageList.propTypes = {
	messages: PropTypes.array,
	loading: PropTypes.bool
};
