import PropTypes from 'prop-types';
import 'src/utils/highlight';
import ReactQuill from 'react-quill';
// @mui
import {alpha} from '@mui/material/styles';
//
import {StyledEditor} from './styles';
import Toolbar, {formats} from './toolbar';
import Typography from "@mui/material/Typography";

// ----------------------------------------------------------------------

export default function Editor({
	                               id = 'minimal-quill',
	                               error,
	                               simple = false,
	                               helperText,
	                               sx,
	                               label,
	template,
	                               ...other
                               }) {
	const modules = {
		toolbar: {
			container: `#${id}`,
		},
		history: {
			delay: 500,
			maxStack: 100,
			userOnly: true,
		},
		syntax: true,
		clipboard: {
			matchVisual: false,
		},
	};

	return (
		<>
			<StyledEditor
				sx={{
					...(error && {
						border: (theme) => `solid 1px ${theme.palette.error.main}`,
						'& .ql-editor': {
							bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
						},
					}),
					...sx,
				}}
			>
				{ label && <Typography
					variant="subtitle2"
					sx={{
						margin: 2,
						lineHeight: 1,
						fontSize: '1rem',
						fontFamily: 'Public Sans, sans-serif',
						fontWeight: 600,
						color: '#637381'
				}}
				>{label}</Typography> }
				<Toolbar id={id} isSimple={simple} template={template}/>

				<ReactQuill
					modules={modules}
					formats={formats}
					placeholder="Write something awesome..."
					{...other}
				/>
			</StyledEditor>

			{helperText && helperText}
		</>
	);
}

Editor.propTypes = {
	error: PropTypes.bool,
	helperText: PropTypes.object,
	id: PropTypes.string,
	simple: PropTypes.bool,
	sx: PropTypes.object,
	label: PropTypes.string,
	template: PropTypes.bool,
};
