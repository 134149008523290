import {useState} from "react";
import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {green, red} from "@mui/material/colors";
// hooks
import {useBoolean} from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import CustomPopover, {usePopover} from 'src/components/custom-popover';
import {ConfirmDialog} from 'src/components/custom-dialog';
//
import EntitiesQuickEditForm from './EntityQuickEditForm';
import {useLocales} from "../../locales";
import {Actions, hasPermission} from "../../utils/roles";
import {useAuthContext} from "../../auth/hooks";


// ----------------------------------------------------------------------

const formatDateToDDMMYYYY = date => date.toLocaleDateString().replace(/\//g, '/');

export default function EntitiesTableRow({key, tableHead, row, selected, onEditRow, onSelectRow, onDeleteRow}) {
	const confirm = useBoolean();
	const {t} = useLocales();
	const {user} = useAuthContext();

	const canUpdate = hasPermission(user, Actions.entityConfigurations.edit);
	const canDelete = hasPermission(user, Actions.entityConfigurations.delete);

	const quickEdit = useBoolean();

	const popover = usePopover();
	const [dataRow, setDataRow] = useState(row);

	const renderValue = (item) => {
		if (!row) {
			return null;
		}
		if (item.type === "boolean") {
			return dataRow[item.id] ? <CheckCircleIcon style={{color: green[400]}}/> :
				<CancelIcon style={{color: red[400]}}/>
		}
		if (item.type === "date") {
			return formatDateToDDMMYYYY(new Date(dataRow[item.id]));
		}
		if (item.type === "datetime") {
			return new Date(dataRow[item.id]).toLocaleString();
		}
		if (item.append) {
			return `${dataRow[item.id]}${item.append}`;
		}
		return dataRow[item.id];
	}

	const updateRow = (data) => {
		setDataRow(data);
		quickEdit.onFalse();
	}

	return (
		<>
			<TableRow hover selected={selected} key={key}>
				<TableCell padding="checkbox">
					<Checkbox checked={selected} onClick={onSelectRow}/>
				</TableCell>

				{tableHead.map(item => (<TableCell>{renderValue(item, dataRow)}</TableCell>))}

				<TableCell align="right" sx={{px: 1, whiteSpace: 'nowrap'}}>
					{canUpdate && (
						<Tooltip title={t('buttons.update')} placement="top" arrow>
							<IconButton color={quickEdit.value ? 'inherit' : 'default'} onClick={quickEdit.onTrue}>
								<Iconify icon="solar:pen-bold"/>
							</IconButton>
						</Tooltip>
					)}

					{canDelete && (
						<IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
							<Iconify icon="eva:more-vertical-fill"/>
						</IconButton>
					)}
				</TableCell>
			</TableRow>

			{quickEdit.value && (
				<EntitiesQuickEditForm
					entity={row.entity}
					id={row.id}
					key={key}
					open={quickEdit.value}
					handleUpdate={(newData) => updateRow(newData)}
					onClose={quickEdit.onFalse}
				/>
			)}


			<CustomPopover
				key={key}
				open={popover.open}
				onClose={popover.onClose}
				arrow="right-top"
			>
				{canDelete && (
					<MenuItem
						onClick={() => {
							confirm.onTrue();
							popover.onClose();
						}}
						sx={{color: 'error.main'}}
					>
						<Iconify icon="solar:trash-bin-trash-bold"/>
						{t('buttons.delete')}
					</MenuItem>
				)}
			</CustomPopover>


			<ConfirmDialog
				key={key}
				open={confirm.value}
				onClose={confirm.onFalse}
				title={t('dialogs.titles.delete')}
				content={t('dialogs.subtitles.delete')}
				action={
					<Button variant="contained" color="error" onClick={onDeleteRow}>
						{t('buttons.delete')}
					</Button>
				}
			/>
		</>
	);
}

EntitiesTableRow.propTypes = {
	key: PropTypes.string,
	onDeleteRow: PropTypes.func,
	onEditRow: PropTypes.func,
	onSelectRow: PropTypes.func,
	row: PropTypes.object,
	selected: PropTypes.bool,
	tableHead: PropTypes.array,
};
