// @mui
import { enUS, frFR, zhCN, viVN, arSA, roRO, esES, itIT, deDE, plPL } from '@mui/material/locale';

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: 'flagpack:gb-nir',
  },
  {
    label: 'Română',
    value: 'ro',
    systemValue: roRO,
    icon: 'flagpack:ro',
  },
  {
    label: 'Français',
    value: 'fr',
    systemValue: frFR,
    icon: 'flagpack:fr',
  },
  {
    label: 'Español',
    value: 'es',
    systemValue: esES,
    icon: 'flagpack:es',
  },
  {
    label: 'Italiano',
    value: 'it',
    systemValue: itIT,
    icon: 'flagpack:it',
  },
  {
    label: 'Deutsch',
    value: 'de',
    systemValue: deDE,
    icon: 'flagpack:de',
  },
  {
    label: 'Polski',
    value: 'pl',
    systemValue: plPL,
    icon: 'flagpack:pl',
  },
  {
    label: 'Tiếng Việt',
    value: 'vi',
    systemValue: viVN,
    icon: 'flagpack:vn',
  },
  {
    label: '中文',
    value: 'cn',
    systemValue: zhCN,
    icon: 'flagpack:cn',
  },
  {
    label: 'العربية',
    value: 'ar',
    systemValue: arSA,
    icon: 'flagpack:sa',
  },
];

export const defaultLang = allLangs[0]; // English

// GET MORE COUNTRY FLAGS
// https://icon-sets.iconify.design/flagpack/
// https://www.dropbox.com/sh/nec1vwswr9lqbh9/AAB9ufC8iccxvtWi3rzZvndLa?dl=0
