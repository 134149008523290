// @mui
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// _mock
//
import PricingCard from './pricing-card';
import {AuthContext} from "../../auth/context/jwt";
import React, {useContext} from "react";
import useSWR from "swr";
import {endpoints, fetcher} from "src/utils/axios";
import moment from "moment";
import {useLocales} from "../../locales";
import PropTypes from "prop-types";
import {acceptedSMSCountries} from "../../data/twilio";

// ----------------------------------------------------------------------

export default function PricingView({ noButton }) {
	const { t } = useLocales();
	const {subscription} = useContext(AuthContext);

	const {data: allPlans} = useSWR(endpoints.plans.find, fetcher);

	const getId = (title) => allPlans?.find(plan => plan.title === title)?._id;

	const getButtonText = (subscriptionName) => {
		if (subscription?.pending) {
			const isPending = subscription.pending.plan === getId(subscriptionName);
			const isFuture = subscription.pending.startDate > Date.now() / 1000;
			if (isPending && isFuture) {
				return `${t('buttons.from')} ${moment(subscription.pending.startDate * 1000).format('D MMM')}`;
			}
		}
		const isSelected = subscription?.plan?.title === subscriptionName;
		return isSelected ? t('buttons.current') : t('buttons.choose');
	}
	const plans = [
		{
			id: getId('Basic'),
			subscription: t('plans.basic.title'),
			price: 0,
			caption: t('plans.basic.description'),
			isFree: true,
			lists: t('plans.basic.advantages', {returnObjects: true}),
			notIncluded: t('plans.basic.disadvantages', {returnObjects: true}),
			pending: subscription?.pending?.plan === getId('Basic'),
			selected: subscription?.plan?._id === getId('Basic'),
			button: getButtonText('Basic'),

		},
		{
			id: getId('Startup'),
			subscription: t('plans.startup.title'),
			price: 29.90,
			caption: t('plans.startup.description'),
			isStartup: true,
			lists: t('plans.startup.advantages', {returnObjects: true}),
			notIncluded: t('plans.startup.disadvantages', {returnObjects: true}),
			pending: subscription?.pending?.plan === getId('Startup'),
			selected: subscription?.plan?._id === getId('Startup'),
			button: getButtonText('Startup'),
		},
		{
			id: getId('Business'),
			subscription: t('plans.business.title'),
			price: 149.90,
			caption: t('plans.business.description'),
			isBusiness: true,
			lists: t('plans.business.advantages', {returnObjects: true}),
			notIncluded: [],
			pending: subscription?.pending?.plan === getId('Business'),
			selected: subscription?.plan?._id === getId('Business'),
			button: getButtonText('Business'),
		},
		{
			id: getId('Enterprise'),
			subscription: t('plans.enterprise.title'),
			price: 399.90,
			caption: t('plans.enterprise.description'),
			isEnterprise: true,
			lists: t('plans.enterprise.advantages', {returnObjects: true}),
			notIncluded: [],
			pending: subscription?.pending?.plan === getId('Enterprise'),
			selected: subscription?.plan?._id === getId('Enterprise'),
			button: getButtonText('Enterprise'),
		},
	];

	return (
		<Container
			sx={{
				pt: 4,
				pb: 4,
				minHeight: 1,
				width: '100%'
			}}
		>
			<Typography align="center" variant="h5" sx={{mb: 5}}>
				{t('titles.pricingPlans')}
			</Typography>

			{/*
      <Box sx={{ mt: 9, mb: 5, position: 'relative' }}>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Typography variant="overline">MONTHLY</Typography>

          <Switch sx={{ mx: 1 }} />

          <Box sx={{ position: 'relative' }}>
            <Stack direction="row" sx={{ position: 'absolute', left: 12, bottom: 12 }}>
              {arrow}
              <Box
                component="span"
                sx={{
                  whiteSpace: 'nowrap',
                  color: 'success.main',
                  typography: 'overline',
                }}
              >
                save 10%
              </Box>
            </Stack>

            <Typography variant="overline">YEARLY</Typography>
          </Box>
        </Stack>
      </Box>
      */}

			<Box
				gap={{xs: 2, md: 0}}
				display="grid"
				alignItems={{md: 'center'}}
				gridTemplateColumns={{md: 'repeat(4, 10fr)'}}
				sx={{width: '100%'}}
			>

				{plans.map((card, index) => (
					<PricingCard key={card.subscription} card={card} index={index} noButton={noButton}/>
				))}
			</Box>
			<Typography variant="body2" sx={{mt: 5}}>{t('captions.quotaSubtraction')}</Typography>
			<Typography variant="body2">{t('subtitles.supportedSmsCountries', { countries: acceptedSMSCountries.map(c=>c.country).join(', ')})}</Typography>
		</Container>
	);
}
PricingView.propTypes = {
	noButton: PropTypes.bool
};
