import React, {useCallback} from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as Yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import FormProvider, {RHFUpload,} from "../../components/hook-form";
import axios, {endpoints} from "../../utils/axios";
import Iconify from "../../components/iconify";
import {useLocales} from "../../locales";
import Stack from "@mui/material/Stack";
import BaseOptions from "../../components/settings/drawer/base-option";
import LayoutOptions from "../../components/settings/drawer/layout-options";
import Tooltip from "@mui/material/Tooltip";
import StretchOptions from "../../components/settings/drawer/stretch-options";
import PresetsOptions from "../../components/settings/drawer/presets-options";
import {grey} from "@mui/material/colors";
import {useSettingsContext} from "../../components/settings";
import {useSnackbar} from "../../components/snackbar";

const Schema = Yup.object().shape({});

const labelStyles = {
	mb: 1.5,
	color: 'text.disabled',
	fontWeight: 'fontWeightSemiBold',
};

function Customisation({
	                       stepper = false,
	                       handleNext,
	                       handleBack,
	                       setErrorMsg,
	                       hasBack,
	                       configurationData,
	                       update,
	                       setConfigurationData,
                       }) {

	const settings = useSettingsContext();
	const {enqueueSnackbar} = useSnackbar();
	const locales = useLocales();
	const {t} = locales;
	const methods = useForm({
		resolver: yupResolver(Schema),
		defaultValues: configurationData
	});

	const {
		handleSubmit,
		setValue,
	} = methods;

	const submit = handleSubmit(async data => {
		try {
			const response = await axios.patch(endpoints.configurations.update, data);
			update(response.data);
			await settings.onUpdateAll(response.data);
			if (setConfigurationData) {
				setConfigurationData(response.data);
			}
			if (!stepper) {
				enqueueSnackbar(t('successes.updated'), {variant: 'success'});
			}
		} catch (error) {
			console.error(error);
			if (setErrorMsg) {
				setErrorMsg(typeof error === 'string' ? error : error.message);
			}
		}
		if (handleNext) {
			handleNext();
		}
	});

	const handleDrop = useCallback(
		async (acceptedFiles) => {
			const file = acceptedFiles[0];
			if (file) {
				const formData = new FormData();
				formData.append("file", file);

				try {
					await axios.post(endpoints.configurations.logo, formData, {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
				} catch (error) {
					console.error(error);
				}
			}

			const newFile = Object.assign(file, {
				preview: URL.createObjectURL(file),
			});

			if (newFile) {
				setValue('logo', newFile, {shouldValidate: true});
			}
		},
		[setValue]
	);

	return (
		<Box sx={{mt: 4}}>
			<FormProvider methods={methods} onSubmit={submit}>
				<Stack spacing={3} sx={{bgColor: grey[500]}}>
					<RHFUpload
						title={t('components.customisation.logoUpload')}
						name="logo"
						maxSize={3145728}
						onDrop={handleDrop}
						onDelete={() => setValue('logo', null, {shouldValidate: true})}
					/>

					<div>
						<Typography variant="caption" component="div" sx={{...labelStyles}}>
							{t('components.onboarding.customisation.theme.mode')}
						</Typography>

						<Controller
							name="themeMode"
							render={({field}) => (
								<BaseOptions
									value={field.value}
									onChange={(newValue) => field.onChange(newValue)}
									options={['light', 'dark']}
									icons={['sun', 'moon']}
								/>
							)}
						/>
					</div>

					<div>
						<Typography variant="caption" component="div" sx={{...labelStyles}}>
							{t('components.onboarding.customisation.theme.contrast')}
						</Typography>

						<Controller
							name="themeContrast"
							render={({field}) => (
								<BaseOptions
									value={field.value}
									onChange={(newValue) => field.onChange(newValue)}
									options={['default', 'bold']}
									icons={['contrast', 'contrast_bold']}
								/>
							)}
						/>

					</div>

					<div>
						<Typography variant="caption" component="div" sx={{...labelStyles}}>
							{t('components.onboarding.customisation.theme.direction')}
						</Typography>

						<Controller
							name="themeDirection"
							render={({field}) => (
								<BaseOptions
									value={field.value}
									onChange={(newValue) => field.onChange(newValue)}
									options={['ltr', 'rtl']}
									icons={['align_left', 'align_right']}
								/>
							)}
						/>
					</div>

					<div>
						<Typography variant="caption" component="div" sx={{...labelStyles}}>
							{t('components.onboarding.customisation.theme.layout')}
						</Typography>

						<Controller
							name="themeLayout"
							render={({field}) => (
								<LayoutOptions
									value={field.value}
									onChange={(newValue) => field.onChange(newValue)}
									options={['vertical', 'horizontal', 'mini']}
								/>
							)}
						/>
					</div>

					<div>
						<Typography
							variant="caption"
							component="div"
							sx={{
								...labelStyles,
								display: 'inline-flex',
								alignItems: 'center',
							}}
						>
							{t('components.onboarding.customisation.theme.stretch')}
							<Tooltip title="Only available at large resolutions > 1600px (xl)">
								<Iconify icon="eva:info-outline" width={16} sx={{ml: 0.5}}/>
							</Tooltip>
						</Typography>

						<Controller
							name="themeStretch"
							render={({field}) => (
								<StretchOptions
									value={field.value}
									onChange={() => {
										field.onChange(!field.value)
									}}
								/>
							)}
						/>
					</div>

					<div>
						<Typography variant="caption" component="div" sx={{...labelStyles}}>
							{t('components.onboarding.customisation.theme.presets')}
						</Typography>

						<Controller
							name="themeColorPresets"
							render={({field}) => (
								<PresetsOptions
									value={field.value}
									onChange={(newValue) => field.onChange(newValue)}
								/>
							)}
						/>
					</div>
				</Stack>
				{!stepper && (
					<Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 3}}>
						<Button
							color="inherit"
							size="large"
							type="submit"
							variant="contained"
							onClick={submit}
						>
							{t('buttons.update')}
						</Button>
					</Box>
				)}
				{stepper && (
					<Box sx={{display: 'flex', flexDirection: 'row', pt: 2, mt: 3}}>
						<Button
							size="large"
							variant="outlined"
							disabled={!hasBack}
							onClick={handleBack}
							sx={{mr: 1}}
						>
							{t('buttons.back')}
						</Button>
						<Box sx={{flex: '1 1 auto'}}/>
						<Button
							color="inherit"
							size="large"
							type="submit"
							variant="contained"
							onClick={submit}
						>
							{t('buttons.next')}
						</Button>
					</Box>
				)}
			</FormProvider>
		</Box>
	);
}

Customisation.propTypes = {
	stepper: PropTypes.bool,
	handleNext: PropTypes.func,
	handleBack: PropTypes.func,
	setErrorMsg: PropTypes.func,
	hasBack: PropTypes.bool,
	configurationData: PropTypes.object,
	setConfigurationData: PropTypes.func,
	update: PropTypes.func,
};

export default Customisation;
