// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
// routes
import {paths} from 'src/routes/paths';
import {usePathname} from 'src/routes/hook';
import {RouterLink} from 'src/routes/components';
// _mock
// components
import Logo from 'src/components/logo';
import {useLocales} from "../../locales";

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

export default function Footer() {
	const {t} = useLocales();
	const pathname = usePathname();

	const LINKS = [
		{
			headline: t('appName'),
			children: [
				{name: t('components.footer.links.pricing'), href: paths.pricing},
				{name: t('components.footer.links.about'), href: paths.about},
				{name: t('components.footer.links.contact'), href: paths.contact},
				{name: t('components.footer.links.faq'), href: paths.faqs},
			],
		},
		{
			headline: t('components.footer.headlines.legal'),
			children: [
				{name: t('components.footer.links.terms'), href: paths.termsAndConditions},
				{name: t('components.footer.links.privacy'), href: paths.privacyPolicy},
			],
		},
		{
			headline: t('components.footer.headlines.contact'),
			children: [{name: 'contact@bizcalendarkit.com', href: 'mailto:contact@bizcalendarkit.com'}],
		},
	];

	const isHome = pathname === '/';

	const simpleFooter = (
		<Box
			component="footer"
			sx={{
				py: 5,
				textAlign: 'center',
				position: 'relative',
				bgcolor: 'background.default',
			}}
		>
			<Container>
				<Logo sx={{mb: 1, mx: 'auto'}}/>

				<Typography variant="caption" component="div">
					© {t('components.footer.rightsReserved')}
					<Link href="https://bizcalendarkit.com/"> BizCalendarKit </Link>
				</Typography>
			</Container>
		</Box>
	);

	const mainFooter = (
		<Box
			component="footer"
			sx={{
				position: 'relative',
				bgcolor: 'background.default',
			}}
		>
			<Divider/>

			<Container
				sx={{
					pt: 10,
					pb: 5,
					textAlign: {xs: 'center', md: 'unset'},
				}}
			>
				<Logo sx={{mb: 3}}/>

				<Grid
					container
					justifyContent={{
						xs: 'center',
						md: 'space-between',
					}}
				>
					<Grid xs={8} md={3}>
						<Typography
							variant="body2"
							sx={{
								maxWidth: 270,
								mx: {xs: 'auto', md: 'unset'},
							}}
						>
							{t('components.footer.description')}
						</Typography>

						{/*
						<Stack
							direction="row"
							justifyContent={{xs: 'center', md: 'flex-start'}}
							sx={{
								mt: 3,
								mb: {xs: 5, md: 0},
							}}
						>
							{_socials.map((social) => (
								<IconButton
									key={social.name}
									sx={{
										'&:hover': {
											bgcolor: alpha(social.color, 0.08),
										},
									}}
								>
									<Iconify color={social.color} icon={social.icon}/>
								</IconButton>
							))}
						</Stack>
						 */}
					</Grid>

					<Grid xs={12} md={6}>
						<Stack spacing={5} direction={{xs: 'column', md: 'row'}}>
							{LINKS.map((list) => (
								<Stack
									key={list.headline}
									spacing={2}
									alignItems={{xs: 'center', md: 'flex-start'}}
									sx={{width: 1}}
								>
									<Typography component="div" variant="overline">
										{list.headline}
									</Typography>

									{list.children.map((link) => (
										<Link
											key={link.name}
											component={RouterLink}
											href={link.href}
											color="inherit"
											variant="body2"
										>
											{link.name}
										</Link>
									))}
								</Stack>
							))}
						</Stack>
					</Grid>
				</Grid>

				<Typography variant="body2" sx={{mt: 10}}>
					© {new Date().getFullYear()}. {t('components.footer.rightsReserved')}
				</Typography>
			</Container>
		</Box>
	);

	return isHome ? simpleFooter : mainFooter;
}
