import {
    addDays,
    addHours,
    addMinutes,
    addMonths,
    addSeconds,
    addWeeks,
    addYears,
    differenceInDays,
    differenceInHours,
    differenceInMinutes,
    differenceInMonths,
    differenceInSeconds,
    differenceInWeeks,
    format,
    formatDistanceToNow,
    fromUnixTime,
    getTime,
    getUnixTime
} from 'date-fns';
import { enUS, fr, es, ro, vi, ar, zhCN } from 'date-fns/locale';

// ----------------------------------------------------------------------

function getLocale(locale) {
    switch (locale) {
        case 'en':
            return enUS;
        case 'ro':
            return ro;
        case 'fr':
            return fr;
        case 'es':
            return es;
        case 'vi':
            return vi;
        case 'ar':
            return ar;
        case 'cn':
            return zhCN;
        default:
            return enUS;
    }

}

export function fDate(date, newFormat, locale = "en") {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm, { locale: getLocale(locale) }) : '';
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy, HH:mm';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fUnixTimestamp(date) {
    return date ? getTime(new Date(date)) / 1000 : '';

}

export function fValidateTimeString(timeString) {
    const regex = /^(\d+)([smwhd])$/;
    return !!timeString.match(regex);
}

export function fTimestampAdd(timeString, unixTimestamp) {
    const valid = fValidateTimeString(timeString);
    const regex = /^(\d+)([smwhd])$/;

    if (!valid) {
        return null;
    }

    const match = timeString.match(regex);
    const value = parseInt(match[1], 10);
    const unit = match[2];
    let date = fromUnixTime(unixTimestamp / 1000);

    switch (unit) {
        case 's':
            date = addSeconds(date, value);
            break;
        case 'm':
            date = addMinutes(date, value);
            break;
        case 'h':
            date = addHours(date, value);
            break;
        case 'd':
            date = addDays(date, value);
            break;
        case 'w':
            date = addWeeks(date, value);
            break;
        case 'mo':
            date = addMonths(date, value);
            break;
        case 'y':
            date = addYears(date, value);
            break;
        default:
            throw new Error('Invalid time unit');
    }

    return getUnixTime(date) * 1000;
}

export function fDifferenceValueAndUnit(timestamp1, timestamp2) {
    const date1 = fromUnixTime(timestamp1/ 1000);
    const date2 = fromUnixTime(timestamp2/ 1000);

    const diffInMonths = differenceInMonths(date2, date1);
    if (diffInMonths !== 0) return { value: diffInMonths, unit: 'mo' };

    const diffInWeeks = differenceInWeeks(date2, date1);
    if (diffInWeeks !== 0) return { value: diffInWeeks, unit: 'w' };

    const diffInDays = differenceInDays(date2, date1);
    if (diffInDays !== 0) return { value: diffInDays, unit: 'd' };

    const diffInHours = differenceInHours(date2, date1);
    const remainingMinutesAfterHours = differenceInMinutes(date2, date1) % 60;
    if (diffInHours !== 0 || remainingMinutesAfterHours !== 0) {
        if (diffInHours >= 1) {
            return { value: diffInHours + remainingMinutesAfterHours / 60, unit: 'h' };
        }
    }

    const diffInMinutes = differenceInMinutes(date2, date1);
    const remainingSecondsAfterMinutes = differenceInSeconds(date2, date1) % 60;
    if (diffInMinutes !== 0 || remainingSecondsAfterMinutes !== 0) {
        return { value: diffInMinutes + remainingSecondsAfterMinutes / 60, unit: 'm' };
    }

    const diffInSeconds = differenceInSeconds(date2, date1);
    if (diffInSeconds !== 0) return { value: diffInSeconds, unit: 's' };


    return { value: 0, unit: 's' };
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}
