import { Helmet } from 'react-helmet-async';
// sections
import Onboarding from "../sections/onboarding";
import {useLocales} from "../locales";

// ----------------------------------------------------------------------

export default function CreateOrganisationPage() {
	const { t } = useLocales();
	return (
		<>
			<Helmet>
				<title>{t('seo.organisationsAdd.title')} - {t('appName')} </title>
				<meta name="description" content={t('seo.organisationsAdd.description')}/>
			</Helmet>

			<Onboarding title={t('pages.organisationsAdd.title')}/>
		</>
	);
}
