import * as Yup from 'yup';
import {useForm} from 'react-hook-form';
import {useEffect, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
// routes
import {useRouter, useSearchParams} from 'src/routes/hook';
// config
// hooks
import {useBoolean} from 'src/hooks/use-boolean';

// components
import Iconify from 'src/components/iconify';
import FormProvider, {RHFTextField} from 'src/components/hook-form';
import axios, {endpoints} from "../../utils/axios";
import {paths} from "../../routes/paths";
import {useLocales} from "../../locales";
// ----------------------------------------------------------------------

const reset = async (email, password, token) => {
	const response = await axios.post(endpoints.auth.resetPasswordChange, {email, password, token});
	return response.data;
}

export default function PasswordResetView() {
	const { t } = useLocales();
	const router = useRouter();
	const password = useBoolean();
	const [errorMsg, setErrorMsg] = useState('');
	const searchParams = useSearchParams();

	const token = searchParams.get('token');
	const email = searchParams.get('email');

	useEffect(() => {
		if (!token || !email) {
			router.push(paths.auth.login);
		}
	}, [token, email, router]);


	const Schema = Yup.object().shape({
		password: Yup.string().required('Password is mandatory'),
	});

	const defaultValues = {
		password: '',
	};

	const methods = useForm({
		resolver: yupResolver(Schema),
		defaultValues,
	});

	const {
		handleSubmit,
		formState: {isSubmitting},
	} = methods;

	const onSubmit = handleSubmit(async (data) => {
		try {
			await reset?.(email, data?.password, token);
			router.push(paths.auth.login);
		} catch (error) {
			console.error(error);
			const code = error?.code;
			if (code === "user_not_found") {
				setErrorMsg(t('errors.user_not_found'));
			}
			else if (code === "activation_expired") {
				setErrorMsg(t('errors.activation_expired'));
			}
			else if (code === "invalid_token") {
				setErrorMsg(t('errors.invalid_token'));
			}
			else {
				setErrorMsg(typeof error === 'string' ? error : error.message || error.code);
			}
		}
	});

	const renderHead = (
		<Stack spacing={2} sx={{mb: 5}}>
			<Typography variant="h4">{t('titles.newPassword')}</Typography>
		</Stack>
	);

	const renderForm = (
		<Stack spacing={2.5}>
			{!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}
			<RHFTextField
				name="password"
				label={t('fields.password')}
				type={password.value ? 'text' : 'password'}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton onClick={password.onToggle} edge="end">
								<Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}/>
							</IconButton>
						</InputAdornment>
					),
				}}
			/>

			<LoadingButton
				fullWidth
				color="inherit"
				size="large"
				type="submit"
				variant="contained"
				loading={isSubmitting}
			>
				{t('buttons.resetPassword')}
			</LoadingButton>

		</Stack>
	);

	return (
		<FormProvider methods={methods} onSubmit={onSubmit}>
			{renderHead}
			{renderForm}
		</FormProvider>
	);
}
