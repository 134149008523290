import PropTypes from 'prop-types';
import {Controller, useFormContext} from 'react-hook-form';
// @mui
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";

// ----------------------------------------------------------------------

export default function RHFTextField({name, helperText, type, onChange, ...other}) {
	const {control} = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			render={({field, fieldState: {error}}) => (
				<TextField
					{...field}
					variant="filled"
					fullWidth
					type={type}
					value={type === 'number' && field.value === 0 ? '' : field.value}
					onChange={(event) => {
						if (onChange) {
							onChange(event);
						}
						if (type === 'number') {
							field.onChange(Number(event.target.value));
						} else {
							field.onChange(event.target.value);
						}
					}}
					error={!!error}
					helperText={
						<Box
							component="span"
							sx={{display: "flex", justifyContent: "space-between"}}
						>
							<span>{error ? error?.message : helperText}</span>
							{other?.maxChars && (
								<span>{field.value?.length}/{other?.maxChars}</span>
							)
							}
						</Box>
					}
					{...other}
				/>
			)}
		/>
	);
}

RHFTextField.propTypes = {
	helperText: PropTypes.object,
	name: PropTypes.string,
	type: PropTypes.string,
	onChange: PropTypes.func,
};
