import {useEffect, useState} from 'react';
// @mui
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// routes
import {useRouter} from 'src/routes/hook';
// config
import {PATH_AFTER_ONBOARDING} from 'src/config-global';
// components;
import Container from "@mui/material/Container";
import Stepper from "@mui/material/Stepper";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import {StepLabel} from "@mui/material";
import Button from "@mui/material/Button";
import OrganisationDetails from "./OrganisationDetails";
import PropTypes from "prop-types";
import Configurations from "./Customisations";
import {useLocales} from "../../locales";


// ----------------------------------------------------------------------


export default function Onboarding({title}) {
	const {t} = useLocales();
	const router = useRouter();

	const [errorMsg, setErrorMsg] = useState('');
	const [activeStep, setActiveStep] = useState(0);
	const [organisationData, setOrganisationData] = useState(null);
	const [configurationData, setConfigurationData] = useState(null);
	const [hasBack, setHasBack] = useState(false);

	useEffect(() => {
		setHasBack(activeStep !== 0);
	}, [activeStep]);

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	}

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const Finish = () => (
		<Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
			<Typography variant="h5" sx={{mt: 5, mb: 3}}>{t('components.onboarding.finish.description')}</Typography>
			<Button variant="contained"
			        onClick={() => router.push(PATH_AFTER_ONBOARDING)}>{t('components.onboarding.finish.button')}</Button>
		</Box>
	)

	const steps = [
		{label: t('components.onboarding.steps.companyDetails'), component: OrganisationDetails},
		{label: t('components.onboarding.steps.configurations'), component: Configurations},
		{label: '', hidden: true, component: Finish},
	];

	const renderStepComponent = (step) => {
		const Component = steps[step].component;
		return <Component
			stepper
			handleNext={handleNext}
			handleBack={handleBack}
			setErrorMsg={setErrorMsg}
			hasBack={hasBack}
			organisationData={organisationData}
			setOrganisationData={setOrganisationData}
			configurationData={configurationData}
			setConfigurationData={setConfigurationData}
		/>;
	};

	return (
		<Container sx={{mt: 10, width: '100%'}}>
			<Stack spacing={2} sx={{mb: 5}}>
				<Typography variant="h4" align="center">{title}</Typography>
				<Typography variant="body2" align="center">{t('components.onboarding.description')}</Typography>
			</Stack>
			<Stepper activeStep={activeStep}>
				{steps.filter(step => !step.hidden).map(step => step.label).map((label) => (
					<Step key={label}>
						<StepLabel>{label}</StepLabel>
					</Step>
				))}
			</Stepper>
			{!!errorMsg && <Alert severity="error" sx={{mt: 2}}>{errorMsg}</Alert>}

			{renderStepComponent(activeStep)}
		</Container>
	);
}
Onboarding.propTypes = {
	title: PropTypes.string,
};
