import {useState} from 'react';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import ChatMessageInput from "./chat-message-input";
import ChatMessageList from "./chat-message-list";
import PropTypes from "prop-types";
//


// ----------------------------------------------------------------------


export default function AiChatView({ sendPrompt, messages}) {
	const [loading, setLoading] = useState(false);

	const handleSendMessage = async (message) => {
		setLoading(true);
		try {
			await sendPrompt(message);
			setLoading(false);
		} catch (error) {
			console.error(error);
		}
	}

	const renderMessages = (
		<Stack
			sx={{
				width: 1,
				height: 1,
				overflow: 'hidden',
			}}
		>
			<ChatMessageList messages={messages} loading={loading} />
			<ChatMessageInput
				handleSendMessage={handleSendMessage}
				loading={loading}
			/>
		</Stack>
	);

	return (
		<Container maxWidth>
			<Stack direction="row" sx={{ height: '81vh' }}>
				<Stack
					sx={{
						width: 1,
						height: 1,
						overflow: 'hidden',
					}}
				>
					<Stack
						direction="row"
						sx={{
							width: 1,
							height: 1,
							overflow: 'hidden',
							borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
						}}
					>
						{renderMessages}
					</Stack>
				</Stack>
			</Stack>
		</Container>
	);
}
AiChatView.propTypes = {
	messages: PropTypes.array,
	sendPrompt: PropTypes.func,
}
