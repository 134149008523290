// @mui
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PropTypes from "prop-types";
import {useLocales} from "../../locales";

// ----------------------------------------------------------------------

export default function PaymentBillingAddress({billingDetails}) {
	const { t } = useLocales();
	return (
		<div>
			<Typography variant="h6">{t('components.payment.billingDetails.title')}</Typography>

			<Stack spacing={3} mt={5}>
				<TextField InputLabelProps={{shrink: true}} disabled fullWidth label={t('fields.name')}
				           value={billingDetails?.name}/>
				<TextField InputLabelProps={{shrink: true}} disabled fullWidth label={t('fields.address')}
				           value={billingDetails?.address.line1}/>
				<TextField InputLabelProps={{shrink: true}} disabled fullWidth label={t('fields.city')}
				           value={billingDetails?.address.city}/>
				<TextField InputLabelProps={{shrink: true}} disabled fullWidth label={t('fields.postalCode')}
				           value={billingDetails?.address.postal_code}/>
				<TextField InputLabelProps={{shrink: true}} disabled fullWidth label={t('fields.country')}
				           value={billingDetails?.address.country}/>
			</Stack>
		</div>
	);
}
PaymentBillingAddress.defaultProps = {
	billingDetails: null
};
PaymentBillingAddress.propTypes = {
	billingDetails: PropTypes.object
};
