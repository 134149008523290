import PropTypes from 'prop-types';
import {useCallback, useEffect, useMemo, useState} from 'react';
// hooks
// utils
//
import {PaymentsContext} from './payments-context';
import AddPaymentMethod from "../components/AddPaymentMethod";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import {useLocales} from "../../../locales";
import useStripeWithLocale from "../../../hooks/use-stripe";

// ----------------------------------------------------------------------



export function PaymentsProvider({children}) {
	const { currentLang } = useLocales();
	const stripePromise = useStripeWithLocale(currentLang.value);

	const [addPaymentMethodComponent, setAddPaymentMethodComponent] = useState({open: false});

	const addPaymentMethod = useCallback(
		async ({onAdded = undefined, onCancelled = undefined}) => {
			setAddPaymentMethodComponent({open: true, onAdded, onCancelled});
		},
		[]
	);

	const cancelPaymentMethod = useCallback(
		async () => {
			setAddPaymentMethodComponent({open: false});
		},
		[]
	);

	const memoizedValue = useMemo(
		() => ({
			addPaymentMethod, cancelPaymentMethod
		}),
		[addPaymentMethod, cancelPaymentMethod]
	);

	return (
		<PaymentsContext.Provider value={memoizedValue} options={{mode: 'setup'}}>
			<Elements stripe={stripePromise} options={{
				appearance: {
					theme: 'flat',
				}
			}}>
				{children}
				<AddPaymentMethod
					open={addPaymentMethodComponent.open}
					handleClose={() => {
						setAddPaymentMethodComponent(prev => ({...prev, open: false}))
					}}
					onAdded={addPaymentMethodComponent.onAdded}
					onCancelled={addPaymentMethodComponent.onCancelled}
				/>
			</Elements>
		</PaymentsContext.Provider>
	);
}

PaymentsProvider.propTypes = {
	children: PropTypes.node,
};
