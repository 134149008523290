import PropTypes from 'prop-types';
import {useCallback, useState} from 'react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
// hooks
import {useBoolean} from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import CancelIcon from '@mui/icons-material/Cancel';
//
import AddPaymentMethod from "../../components/payments/components/AddPaymentMethod";
import axios, {endpoints} from "../../utils/axios";
import {useSWRConfig} from "swr";
import Skeleton from "@mui/material/Skeleton";
import {useLocales} from "../../locales";

export default function PaymentMethods({paymentMethods, isLoading}) {
	const { t } = useLocales();
	const newCard = useBoolean();
	const [openAddCard, setOpenAddCard] = useState(false);
	const {mutate} = useSWRConfig();
	const [loading, setLoading] = useState(false);

	const revalidatePaymentMethods = async () => {
		setLoading(true);
		await mutate(endpoints.subscriptions.paymentMethod);
		setLoading(false);
	};

	const handleChangeMethod = useCallback(async (newValue) => {
		try {
			setLoading(true);
			await axios.patch(endpoints.subscriptions.paymentMethod, {
				paymentMethodId: newValue,
			});
			await revalidatePaymentMethods();
		} catch (e) {
			setLoading(false);
			console.error(e);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleDeleteMethod = useCallback(async (newValue) => {
		try {
			setLoading(true);
			await axios.patch(endpoints.subscriptions.paymentMethodRemove, {
				paymentMethodId: newValue,
			});
			await revalidatePaymentMethods();
		} catch (e) {
			setLoading(false);
			console.error(e);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Stack spacing={5}>
				<Typography variant="h6">	{t('components.payment.methods.title')}</Typography>

				{(isLoading || loading) && Array.from({length: paymentMethods?.length || 1}, (_, index) => (
					<Skeleton key={index} variant="rounded" height={152}/>
				))}

				<Stack spacing={3}>
					{
						!loading && paymentMethods?.map(({card, selected, id, ...other}) =>
							<OptionItem
								canDelete={paymentMethods.length > 1}
								key={card.fingerprint}
								option={card}
								selected={selected}
								isCredit
								onOpen={newCard.onTrue}
								handleSelect={() => handleChangeMethod(id)}
								handleDelete={() => handleDeleteMethod(id)}
								{...other}
							/>
						)
					}

					<Button
						size="small"
						color="primary"
						startIcon={<Iconify icon="mingcute:add-line"/>}
						onClick={() => setOpenAddCard(true)}
					>
						{t('components.payment.methods.card.add')}
					</Button>
				</Stack>
			</Stack>

			<AddPaymentMethod
				open={openAddCard}
				handleClose={() => {
					setOpenAddCard(false)
					setLoading(false)
				}}
				onAdded={revalidatePaymentMethods}
			/>
		</>
	);
}
PaymentMethods.propTypes = {
	paymentMethods: PropTypes.array,
	isLoading: PropTypes.bool,
};

// ----------------------------------------------------------------------

function OptionItem({option, selected, isCredit, onOpen, handleSelect, handleDelete, ...other}) {
	const { t } = useLocales();
	const {value} = option;

	return (
		<Paper

			variant="outlined"
			key={value}
			sx={{
				p: 2.5,
				cursor: 'pointer',
				...(selected && {
					boxShadow: (theme) => `0 0 0 2px ${theme.palette.text.primary}`,
				}),
			}}
			{...other}
		>
			<ListItemText
				primary={
					<Stack direction="row" alignItems="center">
						<Iconify
							onClick={() => {
								if (selected) return;
								handleSelect();
							}}
							icon={selected ? 'eva:checkmark-circle-2-fill' : 'eva:radio-button-off-fill'}
							width={24}
							sx={{mr: 2, color: selected ? 'primary.main' : 'text.secondary'}}
						/>

						<Box component="span" sx={{flexGrow: 1}}>
							Debit Card
						</Box>

						<Stack spacing={1} direction="row" alignItems="center">
							{option.brand === 'visa' && <Iconify icon="logos:visa" width={24}/>}
							{option.brand === 'mastercard' && <Iconify icon="logos:mastercard" width={24}/>}
							{value === 'paypal' && <Iconify icon="logos:paypal" width={24}/>}
							{value === 'cash' && <Iconify icon="solar:wad-of-money-bold" width={24}/>}
						</Stack>
					</Stack>
				}
				primaryTypographyProps={{typography: 'subtitle2'}}
			/>

			<Typography variant="body1" sx={{color: 'text.secondary', pt: 2}}>
				{other?.billing_details.name}
			</Typography>

			{isCredit && (
				<Stack
					spacing={2.5}
					direction="row"
					justifyContent="space-between"
				>
					<Typography sx={{color: 'text.secondary'}}>
						**** **** **** {option.last4}
					</Typography>
					<Typography sx={{color: 'text.secondary'}}>
						{option.exp_month} / {option.exp_year}
					</Typography>


				</Stack>
			)}
			<Box sx={{display: 'flex', flexDirection: 'row-reverse', mt: 1}}>
				<Button
					size="small"
					color="error"
					startIcon={<CancelIcon/>}
					onClick={handleDelete}
					disabled={!other.canDelete || selected}
				>
					{t('components.payment.methods.card.remove')}
				</Button>
			</Box>
		</Paper>
	);
}

OptionItem.propTypes = {
	isCredit: PropTypes.bool,
	onOpen: PropTypes.func,
	option: PropTypes.object,
	selected: PropTypes.bool,
	handleSelect: PropTypes.func,
	handleDelete: PropTypes.func,
	canDelete: PropTypes.bool,
};
