import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
// @mui
import FormHelperText from '@mui/material/FormHelperText';
//
import Editor from '../editor';
import Box from "@mui/material/Box";

// ----------------------------------------------------------------------

export default function RHFEditor({name, helperText, ...other}) {
	const {
		control, watch, setValue, formState: {isSubmitSuccessful},
	} = useFormContext();

	const values = watch();

	useEffect(() => {
		if (values[name] === '<p><br></p>') {
			setValue(name, '', {
				shouldValidate: !isSubmitSuccessful,
			});
		}
	}, [isSubmitSuccessful, name, setValue, values]);

	return (<Controller
		name={name}
		control={control}
		render={({field, fieldState: {error}}) => (<Editor
			id={name}
			value={field.value}
			onChange={field.onChange}
			error={!!error}

			helperText={<Box
				component="span"
				sx={{display: "flex", justifyContent: "space-between"}}
			>
				<FormHelperText
					error={!!error}
					sx={{px: 2}}
				>
					{error ? error?.message : helperText}
				</FormHelperText>
				{other?.maxChars && (
					<FormHelperText
						error={field.value?.length > other?.maxChars}
						sx={{px: 2}}
					>
						{field.value?.length}/{other?.maxChars}
					</FormHelperText>
				)}
			</Box>}
			{...other}
		/>)}
	/>);
}

RHFEditor.propTypes = {
	helperText: PropTypes.string, name: PropTypes.string,
};
