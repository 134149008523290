import PropTypes from 'prop-types';
// @mui
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import {useAuthContext} from "../../auth/hooks";
import {useLocales} from "../../locales";

// ----------------------------------------------------------------------

export default function PaymentSummary({sx, ...other}) {
	const { t } = useLocales();
	const {subscription} = useAuthContext();

	const renderPrice = (
		<Stack direction="row" justifyContent="flex-end">
			<Typography variant="h4">{subscription.plan.currency}</Typography>

			<Typography variant="h2">{subscription.plan.price}</Typography>

			<Typography
				component="span"
				sx={{alignSelf: 'center', color: 'text.disabled', ml: 1, typography: 'body2'}}
			>
				/ {t('components.pricing.month')}
			</Typography>
		</Stack>
	);

	return (
		<Box
			sx={{
				p: 5,
				borderRadius: 2,
				bgcolor: 'background.neutral',
				...sx,
			}}
			{...other}
		>
			<Typography variant="h6" sx={{mb: 5}}>
				{t('components.payment.summary.title')}
			</Typography>

			<Stack spacing={2.5}>
				<Stack direction="row" justifyContent="space-between">
					<Typography variant="body2" sx={{color: 'text.secondary'}}>
						{t('components.payment.summary.subscription')}
					</Typography>

					<Label color="primary">{t(`plans.${subscription.plan.title.toLowerCase()}.title`)}</Label>
				</Stack>

				{renderPrice}

				<Divider sx={{borderStyle: 'dashed'}}/>

				<Stack direction="row" alignItems="center" justifyContent="space-between">
					<Typography variant="subtitle1">{t('components.payment.summary.totalBilled')}</Typography>

					<Typography variant="subtitle1">{subscription.plan.currency} {subscription.plan.price}*</Typography>
				</Stack>
				<Typography component="div" variant="caption" sx={{color: 'text.secondary'}}>
					{t('components.payment.summary.plusTaxes')}
				</Typography>

				<Divider sx={{borderStyle: 'dashed'}}/>
			</Stack>

			<Stack alignItems="center" spacing={1} sx={{mt: 2}}>
				<Stack direction="row" alignItems="center" spacing={1}>
					<Iconify icon="solar:shield-check-bold" sx={{color: 'success.main'}}/>
					<Typography variant="subtitle2">{t('components.payment.summary.security.title')}</Typography>
				</Stack>

				<Typography variant="caption" sx={{color: 'text.disabled', textAlign: 'center'}}>
					{t('components.payment.summary.security.description')}
				</Typography>
			</Stack>
		</Box>
	);
}

PaymentSummary.propTypes = {
	sx: PropTypes.object,
};
