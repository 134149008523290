import {Helmet} from 'react-helmet-async';
import {useLocales} from "../../../locales";
import PricingView from "../../../sections/pricing/view";
import {useAuthContext} from "../../../auth/hooks";
import NoAccessPage from "../../../sections/access/NoAccess";

export default function SubscriptionPage() {
	const authContext = useAuthContext();
	const canAccess = ['ADMIN', 'MANAGER'].includes(authContext?.user?.role);
	const {t} = useLocales();

	if (!canAccess) {
		return (
			<NoAccessPage/>
		);
	}

	return (
		<>
			<Helmet>
				<title>{t('seo.subscription.title')} - {t('appName')}</title>
				<meta name="description" content={t('seo.subscription.description')}/>
			</Helmet>

			<PricingView/>
		</>
	);
}
