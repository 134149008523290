import PropTypes from 'prop-types';
import React from 'react';
// @mui
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
// _mock
// components
import {endpoints, fetcher} from "../../utils/axios";
import useSWR from "swr";
import LinearProgress from "@mui/material/LinearProgress";
import EntityCreateForm from "./create/EntityCreateForm";
import {useLocales} from "../../locales";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {AttributeTypesEnum} from "../../data/attribute";
import dayjs from "dayjs";

// ----------------------------------------------------------------------

export default function EntityQuickEditForm({id, entity, open, handleUpdate, onClose}) {
	const {t} = useLocales();
	const {data: entityConfiguration} = useSWR(endpoints.entityConfigurations.type(entity), fetcher);
	const {data: currentEntity, isLoading} = useSWR(endpoints.entities.get(entity, id), fetcher);
	const current = currentEntity?.attributes.reduce((acc, item) => {
		const configurationItem = entityConfiguration?.attributes.find(attr => attr.field === item.field);
		if (configurationItem) {
			if ([AttributeTypesEnum.date, AttributeTypesEnum.datetime].includes(configurationItem.type)) {
				item.value = dayjs(item.value);
			}
		}
		acc.attributes[item.field] = item.value;
		return acc;
	}, { id: currentEntity.id, attributes: {}, active: currentEntity?.active, entity: currentEntity?.entity })

	if (isLoading) {
		return (
			<Box sx={{width: '100%'}}>
				<LinearProgress/>
			</Box>
		)
	}

	return (
		<Dialog
			fullWidth
			maxWidth={false}
			open={open}
			onClose={onClose}
			PaperProps={{
				sx: {maxWidth: 720},
			}}
		>
			<DialogTitle>{t('modals.entities.edit.title')}</DialogTitle>
			<IconButton
				aria-label="close"
				onClick={onClose}
				sx={(theme) => ({
					position: 'absolute',
					right: 8,
					top: 8,
					color: theme.palette.grey[500],
				})}
			>
				<CloseIcon />
			</IconButton>
			<DialogContent>
				<Box sx={{mb: 2}}>
					<EntityCreateForm current={current} handleUpdate={handleUpdate}/>
				</Box>
			</DialogContent>
		</Dialog>
	);
}

EntityQuickEditForm.propTypes = {
	id: PropTypes.string,
	entity: PropTypes.string,
	onClose: PropTypes.func,
	handleUpdate: PropTypes.func,
	open: PropTypes.bool,
};
