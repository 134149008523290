import React, {useState} from 'react';
import {useRouter, useSearchParams} from "../../routes/hook";
import useSWR from "swr";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import axios, {endpoints, fetcher} from "../../utils/axios";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import {paths} from "../../routes/paths";
import Alert from "@mui/material/Alert";
import {useLocales} from "../../locales";

function InvitationView(props) {
	const searchParams = useSearchParams();
	const {t} = useLocales();
	const router = useRouter();
	const id = searchParams.get('id');
	const token = searchParams.get('token');
	const {data, isLoading} = useSWR(endpoints.invitations.get(id), fetcher);
	const [error, setError] = useState('');
	const [errorCode, setErrorCode] = useState('');

	const handleAccept = async () => {
		try {
			await axios.post(endpoints.invitations.accept(id), {token});
			router.push(paths.organisations);
		} catch (e) {
			setError(t(`errors.${e.code}`));
			setErrorCode(e.code);
		}
	}

	const handelReject = () => {
		router.push(paths.organisations);
	}

	if (isLoading) {
		return (
			<Box sx={{width: '100%'}}>
				<LinearProgress/>
			</Box>
		)
	}

	if (!data) {
		return (
			<Box sx={{width: '100%'}}>
				<Alert variant="filled" severity="error" sx={{mb: 1}}>
					{t('errors.invitation_not_found')}
				</Alert>
				<Button variant="contained" fullWidth onClick={() => router.push(paths.organisations)}>
					{t('buttons.goToOrganisations')}
				</Button>
			</Box>
		)
	}

	const organisation = data?.organisation;

	return (
		<Container>
			<Paper>
				{!!error && (<Alert variant="filled" severity="error" sx={{mb: 5}}>
					{error}
					{errorCode === "invitation_already_accepted" && (
						<Button
							onClick={() => router.push(paths.organisations)}
							variant="contained" sx={{ml: 3}}>
							{t('buttons.goToOrganisations')
							}</Button>)}
				</Alert>)}
				<Typography variant="h4">{t('titles.invitation')}</Typography>
				<Typography variant="body1">
					{t('subtitles.invitation', {organization: organisation?.name})}
				</Typography>
				<LoadingButton
					onClick={handleAccept}
					variant="contained"
					fullWidth
					sx={{mt: 3, mb: 1}}
				>
					{t('buttons.accept')}
				</LoadingButton>
				<Button
					onClick={handelReject}
					variant="contained"
					fullWidth
					color="error"
				>
					{t('buttons.decline')}
				</Button>
			</Paper>
		</Container>
	);
}

export default InvitationView;
