import {Helmet} from 'react-helmet-async';
import {useLocales} from "../../../locales";
import CustomisationView from "../../../sections/settings/CustomisationView";
import {useAuthContext} from "../../../auth/hooks";
import NoAccessPage from "../../../sections/access/NoAccess";
import CustomerCustomAttributesListView
	from "../../../sections/customer-custom-attributes/CustomerCustomAttributesListView";

export default function CustomAttributesCustomer() {
	const {t} = useLocales();

	const authContext = useAuthContext();
	const canAccess = ['ADMIN', 'MANAGER'].includes(authContext?.user?.role);

	if (!canAccess) {
		return (
			<NoAccessPage/>
		);
	}

	return (
		<>
			<Helmet>
				<title>{t('seo.customAttributes.customer.title')} - {t('appName')}</title>
				<meta name="description" content={t('seo.customAttributes.customer.description')}/>
			</Helmet>

			<CustomerCustomAttributesListView />
		</>
	);
}
