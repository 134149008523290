// @mui
import {useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Badge, {badgeClasses} from '@mui/material/Badge';
// hooks
import {useOffSetTop} from 'src/hooks/use-off-set-top';
import {useResponsive} from 'src/hooks/use-responsive';
// theme
import {bgBlur} from 'src/theme/css';
// routes
import {paths} from 'src/routes/paths';
// components
import Logo from 'src/components/logo';
//
import {HEADER} from '../config-layout';
import {navConfig} from './config-navigation';
import NavMobile from './nav/mobile';
import NavDesktop from './nav/desktop';
//
import {HeaderShadow, LanguagePopover, LoginButton} from '../_common';
import {useLocales} from "../../locales";
import {useAuthContext} from "../../auth/hooks";
import {useRouter} from "../../routes/hook";

// ----------------------------------------------------------------------

export default function Header() {
	const { t } = useLocales();
	const theme = useTheme();
	const router = useRouter();
	const { logout, authenticated, unauthenticated } = useAuthContext();
	const navItems = navConfig.map((item) => ({ ...item, title: t(item.tKey) }));

	const mdUp = useResponsive('up', 'md');

	const offsetTop = useOffSetTop(HEADER.H_DESKTOP);

	return (
		<AppBar>
			<Toolbar
				disableGutters
				sx={{
					width: 'auto',
					height: {
						xs: HEADER.H_MOBILE,
						md: HEADER.H_DESKTOP,
					},
					transition: theme.transitions.create(['height'], {
						easing: theme.transitions.easing.easeInOut,
						duration: theme.transitions.duration.shorter,
					}),
					...(offsetTop && {
						...bgBlur({
							color: theme.palette.background.default,
						}),
						height: {
							md: HEADER.H_DESKTOP_OFFSET,
						},
					}),
				}}
			>
				<Container sx={{ height: 1, display: 'flex', alignItems: 'center' }}>
					{!mdUp && <NavMobile offsetTop={offsetTop} data={navConfig} />}
					<Badge
						sx={{
							[`& .${badgeClasses.badge}`]: {
								top: 8,
								right: -16,
							},
							mr: 8,
							ml: 2
						}}
					>
						<Logo />
					</Badge>

					{mdUp && <NavDesktop offsetTop={offsetTop} data={navItems} />}

					<Box sx={{ flexGrow: 1 }} />

					<Stack
						alignItems="center"
						direction={{ xs: 'column', md: 'row-reverse' }} // Stacks vertically on small screens
						spacing={2}
						sx={{ width: '100%', '& > *': { width: { xs: '100%', md: 'auto' } } }} // Full width on mobile
					>
						{authenticated && mdUp && (
							<>
								<Button
									variant="outlined"
									onClick={logout}
									sx={{ width: { xs: '100%', md: 'auto' } }} // Full width on mobile
								>
									{t('buttons.logout')}
								</Button>
								<Button
									variant="contained"
									onClick={() => router.push(paths.dashboard.root)}
									sx={{ width: { xs: '100%', md: 'auto' } }} // Full width on mobile
								>
									{t('buttons.dashboard')}
								</Button>
							</>
						)}

						{unauthenticated && mdUp && (
							<>
								<Button
									variant="contained"
									target="_blank"
									rel="noopener"
									href={paths.auth.register}
									sx={{ width: { xs: '100%', md: 'auto' } }} // Full width on mobile
								>
									{t('buttons.createAccount')}
								</Button>

								{mdUp && <LoginButton />}
							</>
						)}

						<LanguagePopover />

					</Stack>
				</Container>
			</Toolbar>

			{offsetTop && <HeaderShadow />}
		</AppBar>
	);
}
