import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
// hooks
import {useLocalStorage} from 'src/hooks/use-local-storage';
// utils
import {localStorageGetItem} from 'src/utils/storage-available';
//
import {SettingsContext} from './settings-context';
import axios, {endpoints} from "../../../utils/axios";
import {AuthContext} from "../../../auth/context/jwt";
import {setLocale} from "yup";
import {getYupLocale} from "../../../utils/yup-locales";
import {useLocales} from "../../../locales";
import moment from "moment";
import 'moment/locale/ar';
import 'moment/locale/zh-cn';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/pl';
import 'moment/locale/ro';
import 'moment/locale/vi';

// ----------------------------------------------------------------------

const updateConfiguration = async (name, value) => {
	await axios.patch(endpoints.configurations.update, {
		[name]: value
	});
}

export function SettingsProvider({children, defaultSettings}) {
	const {currentLang} = useLocales();
	const {configuration} = useContext(AuthContext);
	const [openDrawer, setOpenDrawer] = useState(false);

	const [settings, setSettings] = useLocalStorage('settings', defaultSettings);

	const isArabic = localStorageGetItem('i18nextLng') === 'ar';

	useEffect(() => {
		setLocale(getYupLocale(currentLang.value));
		moment.locale(currentLang.value === 'cn' ? 'zh-cn' : currentLang.value);
	}, [currentLang.value]);

	useEffect(() => {
		if (isArabic) {
			onChangeDirectionByLang('ar');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isArabic]);

	useEffect(() => {
		if (configuration) {
			setSettings(configuration);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [configuration]);

	const onUpdate = useCallback(
		async (name, value, apiUpdate = true) => {
			if (configuration) await updateConfiguration(name, value);
			setSettings((prevState) => ({
				...prevState,
				[name]: value,
			}));
		},
		[setSettings, configuration]
	);

	const onUpdateAll = useCallback(
		async (values) => {
			setSettings(values);
		},
		[setSettings]
	);

	useEffect(() => {
		let link = document.querySelector("link[rel~='icon']");
		if (!link) {
			link = document.createElement('link');
			link.rel = 'icon';
			document.getElementsByTagName('head')[0].appendChild(link);
		}
		link.href = `${process.env.REACT_APP_URL}/favicon/${configuration?.themeColorPresets}/favicon.ico`;
	}, [configuration,]);

	// Direction by lang
	const onChangeDirectionByLang = useCallback(
		(lang) => {
			onUpdate('themeDirection', lang === 'ar' ? 'rtl' : 'ltr', false);
		},
		[onUpdate]
	);

	// Reset
	const onReset = useCallback(() => {
		setSettings(defaultSettings);
	}, [defaultSettings, setSettings]);

	// Drawer
	const onToggleDrawer = useCallback(() => {
		setOpenDrawer((prev) => !prev);
	}, []);

	const onCloseDrawer = useCallback(() => {
		setOpenDrawer(false);
	}, []);

	const canReset = !isEqual(settings, defaultSettings);

	const memoizedValue = useMemo(
		() => ({
			...settings,
			onUpdate,
			// Direction
			onChangeDirectionByLang,
			// Reset
			canReset,
			onUpdateAll,
			onReset,
			// Drawer
			open: openDrawer,
			onToggle: onToggleDrawer,
			onClose: onCloseDrawer,
		}),
		[
			onReset,
			onUpdate,
			onUpdateAll,
			settings,
			canReset,
			openDrawer,
			onCloseDrawer,
			onToggleDrawer,
			onChangeDirectionByLang,
		]
	);
	return <SettingsContext.Provider value={memoizedValue}>{children}</SettingsContext.Provider>;
}

SettingsProvider.propTypes = {
	children: PropTypes.node,
	defaultSettings: PropTypes.object,
};
