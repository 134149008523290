import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import {useCallback, useContext, useEffect, useMemo} from 'react';
// @mui
import CssBaseline from '@mui/material/CssBaseline';
import {createTheme, ThemeProvider as MuiThemeProvider} from '@mui/material/styles';
// locales
import {useLocales} from 'src/locales';
// components
import {useSettingsContext} from 'src/components/settings';
// system
import {palette} from './palette';
import {shadows} from './shadows';
import {typography} from './typography';
import {customShadows} from './custom-shadows';
import {componentsOverrides} from './overrides';
// options
import {presets} from './options/presets';
import {darkMode} from './options/dark-mode';
import {contrast} from './options/contrast';
import RTL, {direction} from './options/right-to-left';
import {AuthContext} from "../auth/context/jwt";
import {localStorageGetItem} from "../utils/storage-available";

// ----------------------------------------------------------------------

export default function ThemeProvider({children}) {
	const {configuration} = useContext(AuthContext);
	const locales = useLocales();

	useEffect(() => {
		let link = document.querySelector("link[rel~='icon']");
		if (!link) {
			link = document.createElement('link');
			link.rel = 'icon';
			document.getElementsByTagName('head')[0].appendChild(link);
		}
		link.href = `${process.env.REACT_APP_URL}/favicon/${configuration?.themeColorPresets}/favicon.ico`;
	}, [configuration]);

	const {currentLang} = useLocales();

	const settings = useSettingsContext();

	const darkModeOption = darkMode(settings.themeMode);

	const presetsOption = presets(settings.themeColorPresets);

	const contrastOption = contrast(settings.themeContrast === 'bold', settings.themeMode);

	const directionOption = direction(settings.themeDirection);

	const baseOption = useMemo(
		() => ({
			palette: palette('light'),
			shadows: shadows('light'),
			customShadows: customShadows('light'),
			typography,
			shape: {borderRadius: 8},
		}),
		[]
	);

	const updateThemeColor = useCallback(() => {
		if (configuration?.color) {
			settings.onUpdate('themeColorPresets', configuration?.color);
		}
		if (configuration?.language) {
			const localLng =  localStorageGetItem('localLang');
			if (!localLng) {
				locales.onChangeLang(configuration?.language);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [configuration]);

	useEffect(() => {
		updateThemeColor();
	}, [updateThemeColor]);

	const memoizedValue = useMemo(
		() =>
			merge(
				// Base
				baseOption,
				// Direction: remove if not in use
				directionOption,
				// Dark mode: remove if not in use
				darkModeOption,
				// Presets: remove if not in use
				presetsOption,
				// Contrast: remove if not in use
				contrastOption.theme
			),
		[baseOption, directionOption, darkModeOption, presetsOption, contrastOption.theme]
	);

	const theme = createTheme(memoizedValue);

	theme.components = merge(componentsOverrides(theme), contrastOption.components);

	const themeWithLocale = useMemo(
		() => createTheme(theme, currentLang.systemValue),
		[currentLang.systemValue, theme]
	);

	return (
		<MuiThemeProvider theme={themeWithLocale}>
			<RTL themeDirection={settings.themeDirection}>
				<CssBaseline/>
				{children}
			</RTL>
		</MuiThemeProvider>
	);
}

ThemeProvider.propTypes = {
	children: PropTypes.node,
};
