import isEqual from 'lodash/isEqual';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import axios, {endpoints, fetcher} from 'src/utils/axios';

// @mui
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
// routes
import {paths} from 'src/routes/paths';
import {useParams, useRouter} from 'src/routes/hook';
import {RouterLink} from 'src/routes/components';
// hooks
import {useBoolean} from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import {ConfirmDialog} from 'src/components/custom-dialog';
import {useSettingsContext} from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
	emptyRows,
	getComparator,
	TableEmptyRows,
	TableHeadCustom,
	TableNoData,
	TablePaginationCustom,
	TableSelectedAction,
	useTable,
} from 'src/components/table';
//
import EntitiesTableRow from './EntityTableRow';
import EntityTableToolbar from './EntityTableToolbar';
import EntityFiltersResult from './EntityFiltersResult';
import {useLocales} from "../../locales";
import useSWR, {mutate} from "swr";
import {AuthContext} from "../../auth/context/jwt";
import {Actions, hasPermission} from "../../utils/roles";
import NoAccessPage from "../access/NoAccess";
import {capitalize} from "@mui/material";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import uuidv4 from "../../utils/uuidv4";
import {formatEntityName} from "./create/EntityCreateForm";
import EntityFilters from "./EntityFilters";
import PropTypes from "prop-types";


// ----------------------------------------------------------------------


const defaultFilters = {};

// ----------------------------------------------------------------------

const AddButton = ({label, link}) =>
	<Button
		component={RouterLink}
		href={link}
		variant="contained"
		startIcon={<Iconify icon="mingcute:add-line"/>}
	>
		{label}
	</Button>
AddButton.propTypes = {
	label: PropTypes.string,
	link: PropTypes.string,
}

export default function EntityListView() {
	const {user} = useContext(AuthContext);
	const table = useTable();
	const {t} = useLocales();

	const params = useParams();
	const { entity: rawEntity } = params;
	const entity = formatEntityName(rawEntity);

	const canViewList = hasPermission(user, Actions.entityConfigurations.view);
	const canAdd = hasPermission(user, Actions.entityConfigurations.add);
	const canDelete = hasPermission(user, Actions.entityConfigurations.delete);

	const openFilters = useBoolean();

	const {
		data: entityConfiguration,
		isLoading: isLoadingEntityConfiguration
	} = useSWR(endpoints.entityConfigurations.type(entity), fetcher, false);

	const TABLE_HEAD = [
		...(entityConfiguration?.attributes?.filter(a => a.primary).slice(0, 5).map((attribute) => ({
			id: attribute.field,
			label: attribute.name,
			type: attribute.type,
			editable: true,
			filtrable: true,
			valueGetter: (p) => p.row.title
		})) || []),
		{id: ''},
	];

	const settings = useSettingsContext();

	const router = useRouter();

	const confirm = useBoolean();


	const {data: records = [], isLoading: isLoadingEntities, mutate: mutateEntities} = useSWR(endpoints.entities.find(entity), fetcher, false);
	const [tableData, setTableData] = useState([]);

	useEffect(() => {
		if (records) {
			// Optionally log or process records here if needed
			setTableData(records.map(item => item?.attributes?.reduce((acc, {field, value}) => {
				acc.entity = item.entity;
				acc.id = item._id || item.id;
				acc[field] = value;
				return acc;
			}, {})));
		}
	}, [records]);

	const [filters, setFilters] = useState(defaultFilters);

	const dataFiltered = applyFilter({
		inputData: tableData,
		comparator: getComparator(table.order, table.orderBy),
		filters,
		tableHead: TABLE_HEAD,
	});

	const dataInPage = dataFiltered.slice(
		table.page * table.rowsPerPage,
		table.page * table.rowsPerPage + table.rowsPerPage
	);

	const canReset = !isEqual(defaultFilters, filters);

	const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

	const handleFilters = useCallback(
		(name, value) => {
			table.onResetPage();
			setFilters((prevState) => {
				const updatedFilters = { ...prevState, [name]: value };
				Object.keys(updatedFilters).forEach((key) => {
					if (updatedFilters[key] === '') {
						delete updatedFilters[key];
					}
				});

				return updatedFilters;
			});
		},
		[table]
	);

	const handleSubmitFilters = useCallback((options) => {
		const queryParams = Object.entries(filters)
			.reduce((acc, [key, value]) => {
				if (value !== '' && value !== 'all' && !(Array.isArray(value) && value.length === 0)) {
					acc[key] = value.value || value;
				}
				return acc;
			}, {});

		console.log('here we go', queryParams);
		// Trigger SWR re-fetch with the constructed query parameters
		mutateEntities(async () => {
			const { data } = await axios.get(endpoints.entities.find(entity), { params: options?.reset ? {} : queryParams });
			return data;
		}, false);
	}, [filters, entity, mutateEntities]);

	const handleDeleteRow = useCallback(
		async (id) => {
			await axios.delete(endpoints.entities.delete(entity, id))
			const deleteRow = tableData.filter((row) => row.id !== id);
			mutateEntities(() => deleteRow, false);
			table.onUpdatePageDeleteRow(dataInPage.length);
		},
		[dataInPage.length, table, tableData, entity, mutateEntities]
	);

	const handleDeleteRows = useCallback(async () => {
		const deleteRows = tableData.filter((row) => !table.selected.includes(row.id));
		await axios.delete(endpoints.entities.deleteBulk, {params: {ids: table.selected}});
		mutate(endpoints.entities.find, deleteRows, false);

		table.onUpdatePageDeleteRows({
			totalRows: tableData.length,
			totalRowsInPage: dataInPage.length,
			totalRowsFiltered: dataFiltered.length,
		});
	}, [dataFiltered.length, dataInPage.length, table, tableData]);

	const handleEditRow = useCallback(
		(id) => {
			router.push(paths.dashboard.user.edit(id));
		},
		[router]
	);

	// const handleFilterStatus = useCallback(
	// 	(event, newValue) => {
	// 		handleFilters('status', newValue);
	// 	},
	// 	[handleFilters]
	// );

	const handleResetFilters = useCallback(() => {
		setFilters({});
		handleSubmitFilters({ reset: true });
	}, [handleSubmitFilters]);


	if (!canViewList) {
		return <NoAccessPage/>
	}



	if (isLoadingEntities || isLoadingEntityConfiguration) {
		return (
			<Box sx={{width: '100%'}}>
				<LinearProgress/>
			</Box>
		)
	}

	return (
		<>
			<Container maxWidth={settings.themeStretch ? false : 'lg'}>
				<CustomBreadcrumbs
					heading={capitalize(entity)}
					links={[
						{name: t('breadcrumbs.dashboard'), href: paths.dashboard.root},
						{name: capitalize(entity), href: paths.dashboard.entities.root(entity)},
					]}
					action={canAdd && <AddButton link={paths.dashboard.entities.new(entity)} label={t('pages.entities.add', {entity})}/>}
					sx={{
						mb: {xs: 3, md: 5},
					}}
				/>

				<Card>
					<EntityTableToolbar
						filters={filters}
						onFilters={handleFilters}
						onOpenFilters={openFilters.onTrue}
					/>

					{canReset && (
						<EntityFiltersResult
							filters={filters}
							attributeConfigurations={entityConfiguration?.attributes}
							onFilters={handleFilters}
							onResetFilters={handleResetFilters}
							results={dataFiltered.length}
							sx={{p: 2.5, pt: 0}}
						/>
					)}

					<TableContainer sx={{position: 'relative', overflow: 'unset'}}>
						<TableSelectedAction
							numSelected={table.selected.length}
							rowCount={tableData.length}
							onSelectAllRows={(checked) =>
								table.onSelectAllRows(
									checked,
									tableData.map((row) => row.id)
								)
							}
							action={
								canDelete && (
									<Tooltip title="Delete">
										<IconButton color="primary" onClick={confirm.onTrue}>
											<Iconify icon="solar:trash-bin-trash-bold"/>
										</IconButton>
									</Tooltip>
								)
							}
						/>

						<Scrollbar>
							<Table sx={{minWidth: 960}}>
								<TableHeadCustom
									order={table.order}
									orderBy={table.orderBy}
									headLabel={TABLE_HEAD}
									rowCount={tableData.length}
									numSelected={table.selected.length}
									onSort={table.onSort}
									onSelectAllRows={(checked) =>
										table.onSelectAllRows(
											checked,
											tableData.map((row) => row.id)
										)
									}
								/>

								<TableBody>
									{
										dataFiltered
											.slice(
												table.page * table.rowsPerPage,
												table.page * table.rowsPerPage + table.rowsPerPage
											)
											.map((row) => (
												<EntitiesTableRow
													tableHead={TABLE_HEAD}
													key={`entities-${uuidv4()}`}
													row={row}
													selected={table.selected.includes(row?.id)}
													onSelectRow={() => table.onSelectRow(row.id)}
													onDeleteRow={() => handleDeleteRow(row.id)}
													onEditRow={() => handleEditRow(row.id)}
												/>
											))}

									<TableEmptyRows
										emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
									/>

									{!isLoadingEntities && <TableNoData notFound={notFound}/>}
								</TableBody>
							</Table>
						</Scrollbar>
					</TableContainer>

					<TablePaginationCustom
						count={dataFiltered.length}
						page={table.page}
						rowsPerPage={table.rowsPerPage}
						onPageChange={table.onChangePage}
						onRowsPerPageChange={table.onChangeRowsPerPage}
					/>
				</Card>
			</Container>

			<EntityFilters
				open={openFilters.value}
				onClose={openFilters.onFalse}
				filters={filters}
				onFilters={handleFilters}
				onResetFilters={handleResetFilters}
				attributes={entityConfiguration?.attributes}
				handleSubmitFilters={handleSubmitFilters}
			/>

			<ConfirmDialog
				open={confirm.value}
				onClose={confirm.onFalse}
				title={t('dialogs.titles.delete')}
				content={
					<>
						{t('dialogs.subtitles.deleteItems', {length: table.selected.length})}
					</>
				}
				action={
					<Button
						variant="contained"
						color="error"
						onClick={() => {
							handleDeleteRows();
							confirm.onFalse();
						}}
					>
						{t('buttons.delete')}
					</Button>
				}
			/>
		</>
	);
}

// ----------------------------------------------------------------------

function applyFilter({inputData, comparator, filters, tableHead}) {
	const {name,} = filters;

	const stabilizedThis = inputData.map((el, index) => [el, index]);

	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});

	inputData = stabilizedThis.map((el) => el[0]);

	if (name) {
		inputData = inputData.filter(
			(user) => {
				const keys = tableHead.map(t => t.id);
				return keys.some(key => {
					if (typeof user[key] === 'string' && user[key].toLowerCase) {
						const value = user[key].toLowerCase && user[key]?.toLowerCase();
						return value.indexOf(name?.toLowerCase()) !== -1;
					}
					return false;
				});
			}
		);
	}

	return inputData;
}
