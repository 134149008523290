import isEqual from 'lodash/isEqual';
import {useCallback, useContext, useState} from 'react';
import axios, {endpoints, fetcher} from 'src/utils/axios';

// @mui
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
// routes
import {paths} from 'src/routes/paths';
import {useRouter} from 'src/routes/hook';
import {RouterLink} from 'src/routes/components';
// hooks
import {useBoolean} from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import {ConfirmDialog} from 'src/components/custom-dialog';
import {useSettingsContext} from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
	emptyRows,
	getComparator,
	TableEmptyRows,
	TableHeadCustom,
	TableNoData,
	TablePaginationCustom,
	TableSelectedAction,
	useTable,
} from 'src/components/table';
//
import CustomersTableRow from './CustomersTableRow';
import CustomersTableToolbar from './CustomersTableToolbar';
import CustomersTableFiltersResult from './CustomersTableFiltersResult';
import {useLocales} from "../../locales";
import useSWR, {mutate} from "swr";
import {AuthContext} from "../../auth/context/jwt";
import {Features} from "../../assets/data/features";
import {canUseFeature} from "../../utils/feature-control";
import {Actions, hasPermission} from "../../utils/roles";
import NoAccessPage from "../access/NoAccess";


// ----------------------------------------------------------------------


const defaultFilters = {
	name: '',
	role: [],
	status: 'all',
};

// ----------------------------------------------------------------------


export default function CustomerCustomAttributesListView() {
	const {features, user} = useContext(AuthContext);
	const table = useTable();
	const {t} = useLocales();

	const canViewList = hasPermission(user, Actions.customers.view);
	const canAdd = hasPermission(user, Actions.customers.add);
	const canDelete = hasPermission(user, Actions.customers.delete);
	const canAddFeature = canUseFeature(features, Features.AddCustomer);

	const TABLE_HEAD = [
		{id: 'name', label: t('tableHeaders.name')},
		{id: 'type', label: t('tableHeaders.type')},
		{id: 'active', label: t('tableHeaders.active'), type: 'boolean'},
		{id: ''},
	];

	const settings = useSettingsContext();

	const router = useRouter();

	const confirm = useBoolean();


	const {data: tableData = [], isLoading} = useSWR(endpoints.customAttributes.customer.find, fetcher);

	const [filters, setFilters] = useState(defaultFilters);

	const dataFiltered = applyFilter({
		inputData: tableData,
		comparator: getComparator(table.order, table.orderBy),
		filters,
		tableHead: TABLE_HEAD,
	});

	const dataInPage = dataFiltered.slice(
		table.page * table.rowsPerPage,
		table.page * table.rowsPerPage + table.rowsPerPage
	);

	const canReset = !isEqual(defaultFilters, filters);

	const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

	const handleFilters = useCallback(
		(name, value) => {
			table.onResetPage();
			setFilters((prevState) => ({
				...prevState,
				[name]: value,
			}));
		},
		[table]
	);

	const handleDeleteRow = useCallback(
		async (field) => {
			await axios.delete(endpoints.customAttributes.customer.delete(field))
			const deleteRow = tableData.filter((row) => row.field !== field);
			mutate(endpoints.customAttributes.customer.find, deleteRow, false);
			table.onUpdatePageDeleteRow(dataInPage.length);
		},
		[dataInPage.length, table, tableData]
	);

	const handleDeleteRows = useCallback(async () => {
		const deleteRows = tableData.filter((row) => !table.selected.includes(row.id));
		axios.delete(endpoints.customAttributes.customer.deleteBulk, {params: {ids: table.selected}});
		mutate(endpoints.customers.find, deleteRows, false);

		table.onUpdatePageDeleteRows({
			totalRows: tableData.length,
			totalRowsInPage: dataInPage.length,
			totalRowsFiltered: dataFiltered.length,
		});
	}, [dataFiltered.length, dataInPage.length, table, tableData]);

	const handleEditRow = useCallback(
		(id) => {
			router.push(paths.dashboard.user.edit(id));
		},
		[router]
	);

	// const handleFilterStatus = useCallback(
	// 	(event, newValue) => {
	// 		handleFilters('status', newValue);
	// 	},
	// 	[handleFilters]
	// );

	const handleResetFilters = useCallback(() => {
		setFilters(defaultFilters);
	}, []);


	if (!canViewList) {
		return <NoAccessPage/>
	}

	const AddButton = () =>
		<Button
			component={RouterLink}
			href={paths.dashboard.customAttributes.customerAdd}
			variant="contained"
			startIcon={<Iconify icon="mingcute:add-line"/>}
			disabled={!canAddFeature}
		>
			{t('pages.customAttributes.customer.add')}
		</Button>

	return (
		<>
			<Container maxWidth={settings.themeStretch ? false : 'lg'}>
				<CustomBreadcrumbs
					heading={t('pages.customAttributes.customer.title')}
					links={[
						{name: t('breadcrumbs.dashboard'), href: paths.dashboard.root},
						{name: t('breadcrumbs.customAttributesCustomer'), href: paths.dashboard.customAttributes.customer},
					]}
					action={canAdd && <AddButton/>}
					sx={{
						mb: {xs: 3, md: 5},
					}}
				/>

				<Card>
					<CustomersTableToolbar
						filters={filters}
						onFilters={handleFilters}
					/>

					{canReset && (
						<CustomersTableFiltersResult
							filters={filters}
							onFilters={handleFilters}
							onResetFilters={handleResetFilters}
							results={dataFiltered.length}
							sx={{p: 2.5, pt: 0}}
						/>
					)}

					<TableContainer sx={{position: 'relative', overflow: 'unset'}}>
						<TableSelectedAction
							numSelected={table.selected.length}
							rowCount={tableData.length}
							onSelectAllRows={(checked) =>
								table.onSelectAllRows(
									checked,
									tableData.map((row) => row.id)
								)
							}
							action={
								canDelete && (
									<Tooltip title="Delete">
										<IconButton color="primary" onClick={confirm.onTrue}>
											<Iconify icon="solar:trash-bin-trash-bold"/>
										</IconButton>
									</Tooltip>
								)
							}
						/>

						<Scrollbar>
							<Table sx={{minWidth: 960}}>
								<TableHeadCustom
									order={table.order}
									orderBy={table.orderBy}
									headLabel={TABLE_HEAD}
									rowCount={tableData.length}
									numSelected={table.selected.length}
									onSort={table.onSort}
									onSelectAllRows={(checked) =>
										table.onSelectAllRows(
											checked,
											tableData.map((row) => row.id)
										)
									}
								/>

								<TableBody>
									{
										dataFiltered
											.slice(
												table.page * table.rowsPerPage,
												table.page * table.rowsPerPage + table.rowsPerPage
											)
											.map((row) => (
												<CustomersTableRow
													tableHead={TABLE_HEAD}
													key={row.field}
													row={row}
													selected={table.selected.includes(row.field)}
													onSelectRow={() => table.onSelectRow(row.field)}
													onDeleteRow={() => handleDeleteRow(row.field)}
													onEditRow={() => handleEditRow(row.field)}
												/>
											))}

									<TableEmptyRows
										emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
									/>

									{!isLoading && <TableNoData notFound={notFound}/>}
								</TableBody>
							</Table>
						</Scrollbar>
					</TableContainer>

					<TablePaginationCustom
						count={dataFiltered.length}
						page={table.page}
						rowsPerPage={table.rowsPerPage}
						onPageChange={table.onChangePage}
						onRowsPerPageChange={table.onChangeRowsPerPage}
					/>
				</Card>
			</Container>

			<ConfirmDialog
				open={confirm.value}
				onClose={confirm.onFalse}
				title={t('dialogs.titles.delete')}
				content={
					<>
						{t('dialogs.subtitles.deleteItems', {length: table.selected.length})}
					</>
				}
				action={
					<Button
						variant="contained"
						color="error"
						onClick={() => {
							handleDeleteRows();
							confirm.onFalse();
						}}
					>
						{t('buttons.delete')}
					</Button>
				}
			/>
		</>
	);
}

// ----------------------------------------------------------------------

function applyFilter({inputData, comparator, filters, tableHead}) {
	const {name, status, role} = filters;

	const stabilizedThis = inputData.map((el, index) => [el, index]);

	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});

	inputData = stabilizedThis.map((el) => el[0]);

	if (name) {
		inputData = inputData.filter(
			(user) => {
				const keys = tableHead.map(t => t.id);
				return keys.some(key => {
					if (typeof user[key] === 'string' && user[key].toLowerCase) {
						const value = user[key].toLowerCase && user[key]?.toLowerCase();
						return value.indexOf(name?.toLowerCase()) !== -1;
					}
					return false;
				});
			}
		);
	}

	if (status !== 'all') {
		inputData = inputData.filter((user) => user.status === status);
	}

	if (role.length) {
		inputData = inputData.filter((user) => role.includes(user?.role));
	}

	return inputData;
}
