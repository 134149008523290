import React from 'react';
import axios, {endpoints, fetcher} from "../../utils/axios";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import Iconify from "../../components/iconify";
import {green, grey, red} from "@mui/material/colors";
import useSWR from "swr";
import {CircularProgress} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import {usePaymentsContext} from "../../components/payments/context";
import LoadingButton from "@mui/lab/LoadingButton";
import {useAuthContext} from "../../auth/hooks";
import moment from "moment";
import {useLocales} from "../../locales";

const upgradePlanRequest = async (planId) => {
	const response = await axios.get(endpoints.subscriptions.upgrade(planId));
	return response.data;
};
const hasPaymentMethodRequest = async () => {
	const response = await axios.get(endpoints.subscriptions.paymentStatus);
	return response.data.hasPaymentMethod;
}

const PricingPreview = ({open, onClose, plan, planId}) => {
	const { t } = useLocales();
	const {fetchSubscription, fetchQuota} = useAuthContext();
	const {addPaymentMethod} = usePaymentsContext();
	const [loading, setLoading] = React.useState(false);

	const {data: planPreview, isLoading} = useSWR(endpoints.subscriptions.upgradePreview(planId), fetcher);

	const upgrade = async () => {
		setLoading(true);
		const hasPaymentMethod = await hasPaymentMethodRequest();
		if (!hasPaymentMethod) {
			addPaymentMethod({
				onAdded: async () => {
					await upgradePlanRequest(planId);
					onClose({updated: true, isUpgrade: planPreview?.isUpgrade, paymentDate: planPreview?.paymentDate});
					await fetchSubscription();
					await fetchQuota();
				},
				onCancelled: async () => {
					setLoading(false);
				},
			});
			return;
		}
		await upgradePlanRequest(planId);
		setLoading(false);
		await fetchSubscription();
		onClose({updated: true, isUpgrade: planPreview?.isUpgrade, paymentDate: planPreview?.paymentDate});
	}

	if (isLoading) {
		return (<Dialog
			fullWidth
			maxWidth="xs"
			open={open}
			onClose={onClose}
		>
			<DialogContent>
				<Box display="flex" flexDirection="column" alignItems="center" sx={{m: 5}}>
					<CircularProgress/>
				</Box>
			</DialogContent>
		</Dialog>);

	}

	return (<Dialog
		fullWidth
		maxWidth="xs"
		open={open}
		onClose={onClose}
	>
		<DialogContent>
			<Container sx={{mt: 5}}>
				<Typography variant="h4" align="center" gutterBottom>
					{planPreview?.isUpgrade ? t('components.pricing.upgradeTo') : t('components.pricing.downgradeTo')} <strong>{plan.subscription}</strong>
				</Typography>
				<Typography variant="h3" align="center" gutterBottom>{planPreview?.planPrice || "€0"} / {t('components.pricing.month')}</Typography>
				<Box sx={{mt: 4}}>
					{!planPreview?.isUpgrade && (
						<>
							<Typography variant="h6">
								{t('components.pricing.downgradeWarning.title')}
							</Typography>
							<Typography variant="body1" color="" sx={{mb: 3}}>
								{t('components.pricing.downgradeWarning.description')}
							</Typography>
						</>
					)}
					{
						plan.lists.map((item) => (
							<Tooltip key={item.text} title={item.tooltip} placement="left">
								<Stack
									key={item}
									spacing={1}
									direction="row"
									alignItems="center"
									sx={{
										typography: 'body2',
									}}
								>
									<Iconify icon="mdi:check-bold" width={16}
									         sx={{mr: 1, color: planPreview?.isUpgrade ? green[500] : grey[500]}}/>
									{item.text}
								</Stack>
							</Tooltip>))
					}
					{
						!planPreview?.isUpgrade &&

						plan.notIncluded?.map((item) => (
							<Tooltip key={item.text} title={item.tooltip} placement="top">
								<Stack
									key={item}
									spacing={1}
									direction="row"
									alignItems="center"
									sx={{
										typography: 'body2',
									}}
								>
									<Iconify icon="ic:baseline-cancel" width={16} sx={{mr: 1, color: red[500]}}/>
									{item.text}
								</Stack>
							</Tooltip>
						))


					}
				</Box>
				<Divider sx={{m: 1, mt: 2}}/>
				{planPreview?.lineItems?.filter((item) => {
					const value = parseFloat(item.amount.replace('€', ''));
					return value < 0;
				})?.map(item => (
					<Typography variant="body2" align="right"
					            gutterBottom>{t('components.pricing.unusedCreditSubscription')}: <strong>{item.amount}</strong></Typography>
				))}
				<Typography variant="h6" align="right" gutterBottom>
					{planPreview?.paymentDate ? `${t('components.pricing.startingFrom')} ${moment(planPreview.paymentDate * 1000).format('D MMM')}: ` : `${t('components.pricing.dueToday')}: `}
					{planPreview?.subtotal || "€0"}
				</Typography>
				<Divider sx={{m: 1}}/>
			</Container>
		</DialogContent>

		<DialogActions>
			<Button onClick={onClose}>{t('buttons.cancel')}</Button>
			<LoadingButton loading={loading} variant="contained" onClick={upgrade} autoFocus>
				{planPreview?.isUpgrade ? t('buttons.upgrade') : t('buttons.downgrade')}
			</LoadingButton>
		</DialogActions>
	</Dialog>);
};
PricingPreview.propTypes = {
	planId: PropTypes.string, open: PropTypes.bool, onClose: PropTypes.func, plan: PropTypes.shape({
		subscription: PropTypes.string,
		lists: PropTypes.arrayOf(PropTypes.shape({
			text: PropTypes.string, tooltip: PropTypes.string,
		})),
		notIncluded: PropTypes.arrayOf(PropTypes.shape({
			text: PropTypes.string, tooltip: PropTypes.string,
		})),
	}),
};

export default PricingPreview;
