import React from 'react';
import {Helmet} from "react-helmet-async";
import AppointmentCreatedTemplateEmailView
	from "../../../../sections/templates/emails/AppointmentCreatedTemplateEmailView";
import {useLocales} from "../../../../locales";

export default function AppointmentCreatedTemplateEmailPage() {
	const { t } = useLocales();
	return (
		<>
			<Helmet>
				<title>{t('seo.appointmentConfirmationTemplate.title')} - {t('appName')}</title>
			</Helmet>

			<AppointmentCreatedTemplateEmailView/>
		</>
	);
};
