import BusinessHours from "../onboarding/BusinessHours";
import {endpoints, fetcher} from "../../utils/axios";
import useSWR from "swr";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Container from "@mui/material/Container";
import CustomBreadcrumbs from "../../components/custom-breadcrumbs";
import {paths} from "../../routes/paths";
import {useSettingsContext} from "../../components/settings";
import {useLocales} from "../../locales";
import {Grid} from "@mui/material";
import Card from "@mui/material/Card";

export default function BusinessHoursView() {
	const {t} = useLocales();
	const settings = useSettingsContext();
	const {data: configurationData, isLoading} = useSWR(endpoints.configurations.get, fetcher);

	if (isLoading) {
		return (
			<Box sx={{width: '100%'}}>
				<LinearProgress/>
			</Box>
		)
	}

	return (
		<>
			<Container maxWidth={settings.themeStretch ? false : 'lg'}>
				<CustomBreadcrumbs
					heading={t('navigations.dashboard.settings.businessHours')}
					links={[
						{
							name: t('breadcrumbs.dashboard'),
							href: paths.dashboard.root,
						},
						{
							name: t('navigations.dashboard.settings.title'),
						},
						{name: t('navigations.dashboard.settings.businessHours')},
					]}
					sx={{
						mb: {xs: 3, md: 5},
					}}
				/>
				<Grid container spacing={3}>
					<Grid xs={12} md={12}>
						<Card sx={{p: 3}}>
							<BusinessHours configurationData={configurationData}/>
						</Card>
					</Grid>
				</Grid>
			</Container>
		</>
	)
}
