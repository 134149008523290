import {Navigate, useRoutes} from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
// config
// import { PATH_AFTER_LOGIN } from 'src/config-global';
//
import {HomePage, mainRoutes} from './main';
import {authRoutes} from './auth';
import {authDemoRoutes} from './auth-demo';
import {dashboardRoutes} from './dashboard';
import {componentsRoutes} from './components';
import {AuthGuard} from "../../auth/guard";
import AuthClassicLayout from "../../layouts/auth/classic";
import OnboardingPage from "../../pages/onboarding";
import {paths} from "../paths";
import OrganisationsPage from "../../pages/organisations";
import CreateOrganisationPage from "../../pages/new-organisation";
import AppointmentCancellationPage from "../../pages/appointment/cancellation";
import {useContext} from "react";
import {AuthContext} from "../../auth/context/jwt";
import InvitationPage from "../../pages/dashboard/InvitationPage";

// ----------------------------------------------------------------------

export default function Router() {
	const {features} = useContext(AuthContext);
	return useRoutes([
		// SET INDEX PAGE WITH SKIP HOME PAGE
		// {
		//   path: '/',
		//   element: <Navigate to={PATH_AFTER_LOGIN} replace />,
		// },

		// ----------------------------------------------------------------------

		// SET INDEX PAGE WITH HOME PAGE
		{
			path: '/',
			element: (
				<MainLayout>
					<HomePage/>
				</MainLayout>
			),
		},

		// Auth routes
		...authRoutes,
		...authDemoRoutes,

		{
			element:
				<AuthGuard>
					<AuthClassicLayout>
						<InvitationPage/>
					</AuthClassicLayout>
				</AuthGuard>,
			path: 'invitation',
		},

		// Dashboard routes
		...dashboardRoutes(features),

		// Main routes
		...mainRoutes,

		// Components routes
		...componentsRoutes,

		{
			path: paths.onboarding,
			element: (
				<AuthGuard>
					<AuthClassicLayout>
						<OnboardingPage/>
					</AuthClassicLayout>
				</AuthGuard>
			),
		},
		{
			path: paths.organisation.create,
			element: (
				<AuthGuard createOrganisation>
					<AuthClassicLayout>
						<CreateOrganisationPage/>
					</AuthClassicLayout>
				</AuthGuard>
			),
		},
		{
			path: paths.organisations,
			element: (
				<AuthGuard>
					<AuthClassicLayout>
						<OrganisationsPage/>
					</AuthClassicLayout>
				</AuthGuard>
			),
		},
		{
			path: paths.appointment.cancellation,
			element: (
				<MainLayout>
					<AppointmentCancellationPage/>
				</MainLayout>
			)
		},

		// No match 404
		{path: '*', element: <Navigate to="/404" replace/>},
	]);
}
