import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

// components
import Iconify from 'src/components/iconify';
import {useLocales} from "../../locales";
import {AttributeTypesEnum} from "../../data/attribute";
import moment from "moment/moment";

// ----------------------------------------------------------------------

export default function EntityFiltersResult({
	                                            filters,
	                                            attributeConfigurations,
	                                            onFilters,
	                                            //
	                                            onResetFilters,
	                                            //
	                                            results,
	                                            ...other
                                            }) {
	const {t, currentLang} = useLocales();


	return (
		<Stack spacing={1.5} {...other}>
			<Box sx={{typography: 'body2'}}>
				<Box component="span" sx={{color: 'text.secondary', ml: 0.25}}>
					{t('subtitles.resultsLength', {length: results})}
				</Box>
			</Box>

			<Stack flexGrow={1} spacing={1} direction="row" flexWrap="wrap" alignItems="center">
				{Object.keys(filters).map((filterKey) => {
					const attrConfig = attributeConfigurations.find((config) => config.field === filterKey);

					if (attrConfig.type === 'entity') {
						return (
							<>
								<Block label={`${attrConfig.name}: `}>
									<Chip size="small"
									      label={filters[filterKey].label}/>
								</Block>
							</>
						);
					}
					if ([AttributeTypesEnum.datetime, AttributeTypesEnum.date].includes(attrConfig.type)) {
						return (
							<>
								<Block label={`${attrConfig.name}: `}>
									{filters[filterKey].gte && (
									<Chip size="small"
									      label={moment(filters[filterKey].gte).locale(currentLang.value).format(attrConfig.type === AttributeTypesEnum.datetime ? 'LLL' : 'LL').toString()}/>
									)}
									{ filters[filterKey].lte && " - " }
									{filters[filterKey].lte && (
									<Chip size="small"
									      label={moment(filters[filterKey].lte).locale(currentLang.value).format(attrConfig.type === AttributeTypesEnum.datetime ? 'LLL' : 'LL').toString()}/>
									)}
								</Block>
							</>
						)
					}
					return (
						<>
							<Block label={`${attrConfig.name}: `}>
									<Chip size="small"
									      label={filters[filterKey]}/>
							</Block>
						</>
					);
				})}

				<Button
					color="error"
					onClick={onResetFilters}
					startIcon={<Iconify icon="solar:trash-bin-trash-bold"/>}
				>
					{t('buttons.deleteFilters')}
				</Button>
			</Stack>
		</Stack>
	);
}

EntityFiltersResult.propTypes = {
	filters: PropTypes.object,
	onFilters: PropTypes.func,
	onResetFilters: PropTypes.func,
	results: PropTypes.number,

	attributeConfigurations: PropTypes.array
};

// ----------------------------------------------------------------------

function Block({label, children, sx, ...other}) {
	return (
		<Stack
			component={Paper}
			variant="outlined"
			spacing={1}
			direction="row"
			sx={{
				p: 1,
				borderRadius: 1,
				overflow: 'hidden',
				borderStyle: 'dashed',
				...sx,
			}}
			{...other}
		>
			<Box component="span" sx={{typography: 'subtitle2'}}>
				{label}
			</Box>

			<Stack spacing={1} direction="row" flexWrap="wrap">
				{children}
			</Stack>
		</Stack>
	);
}

Block.propTypes = {
	children: PropTypes.node,
	label: PropTypes.string,
	sx: PropTypes.object,
};
