export const hasRole = (userRole, requiredRoles) => requiredRoles.includes(userRole);

export const Roles = {
	Specialist: 'SPECIALIST',
	Receptionist: 'RECEPTIONIST',
	Manager: 'MANAGER',
	Admin: 'ADMIN',
	Owner: 'OWNER',
};

export const AdministrativeRoles = [Roles.Admin, Roles.Manager];
export const ManagementRoles = [Roles.Admin, Roles.Manager, Roles.Receptionist];

export const Actions = {
	halls: {
		add: 'ADD_HALLS',
		view: 'VIEW_HALLS',
		edit: 'EDIT_HALLS',
		delete: 'DELETE_HALLS',
	},
	customers: {
		add: 'ADD_CUSTOMERS',
		view: 'VIEW_CUSTOMERS',
		edit: 'EDIT_CUSTOMERS',
		delete: 'DELETE_CUSTOMERS',
	},
	appointments: {
		add: 'ADD_APPOINTMENTS',
		view: 'VIEW_APPOINTMENTS',
		edit: 'EDIT_APPOINTMENTS',
		delete: 'DELETE_APPOINTMENTS',
		logs: {
			view: 'VIEW_APPOINTMENT_LOGS',
		}
	},
	services: {
		add: 'ADD_SERVICES',
		view: 'VIEW_SERVICES',
		edit: 'EDIT_SERVICES',
		delete: 'DELETE_SERVICES',
	},
	employees: {
		add: 'ADD_EMPLOYEES',
		view: 'VIEW_EMPLOYEES',
		edit: 'EDIT_EMPLOYEES',
		delete: 'DELETE_EMPLOYEES',
	},
	entities: {
		view: 'VIEW_ENTITIES',
		add: 'ADD_ENTITIES',
		edit: 'EDIT_ENTITIES',
		delete: 'DELETE_ENTITIES',
	},
	entityConfigurations: {
		view: 'VIEW_ENTITY_CONFIGURATIONS',
		add: 'ADD_ENTITY_CONFIGURATIONS',
		edit: 'EDIT_ENTITY_CONFIGURATIONS',
		delete: 'DELETE_ENTITY_CONFIGURATIONS',
	},
}

export const RolePermissions = {
	[Roles.Admin]: [
		Actions.customers.add, Actions.customers.view, Actions.customers.edit, Actions.customers.delete,
		Actions.appointments.add, Actions.appointments.view, Actions.appointments.edit, Actions.appointments.delete,
		Actions.services.add, Actions.services.view, Actions.services.edit, Actions.services.delete,
		Actions.employees.add, Actions.employees.view, Actions.employees.edit, Actions.employees.delete,
		Actions.halls.add, Actions.halls.view, Actions.halls.edit, Actions.halls.delete,
		Actions.entities.view, Actions.entities.add, Actions.entities.edit, Actions.entities.delete,
		Actions.entityConfigurations.view, Actions.entityConfigurations.add, Actions.entityConfigurations.edit, Actions.entityConfigurations.delete,
	],
	[Roles.Manager]: [
		Actions.customers.add, Actions.customers.view, Actions.customers.edit, Actions.customers.delete,
		Actions.appointments.view, Actions.appointments.edit, Actions.appointments.delete,
		Actions.services.view, Actions.services.edit, Actions.services.delete,
		Actions.employees.add, Actions.employees.view, Actions.employees.edit, Actions.employees.delete,
		Actions.halls.add, Actions.halls.view, Actions.halls.edit, Actions.halls.delete,
		Actions.entities.view, Actions.entities.add, Actions.entities.edit, Actions.entities.delete,
		Actions.entityConfigurations.view, Actions.entityConfigurations.add, Actions.entityConfigurations.edit, Actions.entityConfigurations.delete,
	],
	[Roles.Receptionist]: [
		Actions.customers.add, Actions.customers.view, Actions.customers.edit, Actions.customers.delete,
		Actions.appointments.add, Actions.appointments.view, Actions.appointments.edit, Actions.appointments.delete,
		Actions.services.view,
		Actions.halls.view,
		Actions.entities.view, Actions.entities.add, Actions.entities.edit, Actions.entities.delete,
	],
	[Roles.Specialist]: [
		Actions.customers.view, Actions.customers.add,
		Actions.appointments.view, Actions.appointments.add,
		Actions.services.view,
		Actions.halls.view,
		Actions.entities.view, Actions.entities.add, Actions.entities.edit, Actions.entities.delete,
	],
}

export const hasPermission = (user, action) => {
	const role = user?.role;
	const permissions = RolePermissions[role];
	return permissions?.includes(action);
}
