import { useCallback } from 'react';
import { useLocales } from "../locales";

// ----------------------------------------------------------------------

export function useFileTypes() {
	const { t } = useLocales();
	const translation = useCallback((key) => t(`fileKinds.${key}`), [t]);

	return [
		{
			label: translation('image'),
			kind: 'image',
			types: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg+xml', 'image/bmp', 'image/webp', 'image/tiff', 'image/heif']
		},
		{
			label: translation('document'),
			kind: 'document',
			types: [
				'application/pdf',
				'application/msword',
				'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
				'application/rtf',
				'application/vnd.oasis.opendocument.text',
				'application/vnd.apple.pages'
			]
		},
		{
			label: translation('spreadsheet'),
			kind: 'spreadsheet',
			types: [
				'application/vnd.ms-excel',
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				'application/vnd.oasis.opendocument.spreadsheet',
				'application/vnd.google-apps.spreadsheet'
			]
		},
		{
			label: translation('presentation'),
			kind: 'presentation',
			types: [
				'application/vnd.ms-powerpoint',
				'application/vnd.openxmlformats-officedocument.presentationml.presentation',
				'application/vnd.oasis.opendocument.presentation',
				'application/vnd.apple.keynote'
			]
		},
		{
			label: translation('video'),
			kind: 'video',
			types: [
				'video/mp4',
				'video/mpeg',
				'video/quicktime',
				'video/x-msvideo',
				'video/x-matroska',
				'video/webm',
				'video/3gpp',
				'video/x-flv'
			]
		},
		{
			label: translation('audio'),
			kind: 'audio',
			types: [
				'audio/mpeg',
				'audio/wav',
				'audio/aac',
				'audio/ogg',
				'audio/mp4',
				'audio/webm',
				'audio/x-ms-wma',
				'audio/x-flac'
			]
		},
		{
			label: translation('text'),
			kind: 'text',
			types: [
				'text/plain',
				'text/csv',
				'text/html',
				'text/css',
				'text/javascript',
				'application/json',
				'application/xml',
				'application/x-yaml'
			]
		},
		{
			label: translation('compressed'),
			kind: 'compressed',
			types: [
				'application/zip',
				'application/x-7z-compressed',
				'application/x-rar-compressed',
				'application/x-tar',
				'application/gzip'
			]
		}
	];
}
