// @mui
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
//
import PaymentSummary from '../payment-summary';
import PaymentMethods from '../payment-methods';
import PaymentBillingAddress from '../payment-billing-address';
import useSWR from "swr";
import {endpoints, fetcher} from "../../../utils/axios";
import PaymentInvoices from "../payment-invoices";

// ----------------------------------------------------------------------

export default function PaymentView() {
	const {data: paymentMethods, isLoading} = useSWR(endpoints.subscriptions.paymentMethod, fetcher);
	const billingDetails = paymentMethods && paymentMethods.find(pm => pm.selected)?.billing_details;

	return (
		<Container
			sx={{
				pb: 10,
				minHeight: 1,
			}}
		>
			<Grid container rowSpacing={{xs: 5, md: 0}} columnSpacing={{xs: 0, md: 5}}>
				<Grid xs={12} md={4}>
					<PaymentSummary/>
				</Grid>

				<Grid xs={12} md={8}>
					<Box
						gap={5}
						display="grid"
						gridTemplateColumns={{
							xs: 'repeat(1, 1fr)',
							md: 'repeat(2, 1fr)',
						}}
						sx={{
							p: {md: 5},
							borderRadius: 2,
							border: (theme) => ({
								md: `dashed 1px ${theme.palette.divider}`,
							}),
						}}
					>
						<PaymentBillingAddress billingDetails={billingDetails}/>
						<PaymentMethods paymentMethods={paymentMethods} isLoading={isLoading}/>
					</Box>
				</Grid>
				<Grid xs={12} sx={{ mt: 2}}>
					<PaymentInvoices/>
				</Grid>
			</Grid>
		</Container>
	);
}
