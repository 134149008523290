import React, {useEffect} from 'react';
import Container from "@mui/material/Container";
import {useRouter, useSearchParams} from "../../routes/hook";
import {endpoints, fetcher} from "../../utils/axios";
import useSWR from "swr";
import Typography from "@mui/material/Typography";

export default function AppointmentCancellationView() {
	const router = useRouter();
	const searchParams = useSearchParams();
	const token = searchParams.get('token');

	useEffect(() => {
		if (!token) {
			router.push("/");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token]);


	const { data, error } = useSWR(endpoints.appointment.cancellation, fetcher({ params: { token } }));

	return (
		<Container sx={{ mt: 5 }}>
			{
				error && (
					<>
						<Typography variant="h3" align="center">Appointment cannot be cancelled</Typography>
						<Typography variant="body1" align="center">The appointment might expired or invalid token.</Typography>
					</>
				)
			}
			{
				data && (
					<>
						<Typography variant="h2" gutterBottom>Appointment cancelled</Typography>
						<Typography variant="body1" gutterBottom>Contact the organisation to reschedule.</Typography>
					</>
				)
			}
		</Container>
	);
};
