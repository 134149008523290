import {lazy, Suspense} from 'react';
import {Outlet} from 'react-router-dom';

// layouts
import AuthClassicLayout from 'src/layouts/auth/classic';
// components
import {SplashScreen} from 'src/components/loading-screen';
import {GuestGuard} from "../../auth/guard";
import PasswordResetPage from "../../pages/auth/reset-password";

// ----------------------------------------------------------------------

// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const JwtRegisterPage = lazy(() => import('src/pages/auth/jwt/register'));

const authJwt = [
	{
		path: 'login',
		element: (
			<AuthClassicLayout>
				<JwtLoginPage/>
			</AuthClassicLayout>
		),
	},
	{
		path: 'register',
		element: (
			<AuthClassicLayout title="Manage the job more effectively with Minimal">
				<JwtRegisterPage/>
			</AuthClassicLayout>
		),
	},
	{
		path: 'reset-password',
		element: (
			<AuthClassicLayout title="Manage the job more effectively with Minimal">
				<PasswordResetPage/>
			</AuthClassicLayout>
		),
	},
]

export const authRoutes = [
	{
		path: 'auth',
		element: (
			<GuestGuard>
				<Suspense fallback={<SplashScreen/>}>
					<Outlet/>
				</Suspense>
			</GuestGuard>
		),
		children: authJwt,
	},
];
