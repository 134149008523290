import React from 'react';
import useSWR from "swr";
import {useSnackbar} from 'src/components/snackbar';
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import axios, {endpoints, fetcher} from "../../../../utils/axios";
import AppointmentSmsTemplate from "./AppointmentSmsTemplate";
import PropTypes from "prop-types";
import {useLocales} from "../../../../locales";

const AppointmentSmsTemplateView = ({type, name}) => {
	const { t } = useLocales();
	const {enqueueSnackbar} = useSnackbar();
	const {data: template, isLoading, mutate} = useSWR(endpoints.templates.get(type, name), fetcher);

	if (isLoading) {
		return (
			<Box sx={{width: '100%'}}>
				<LinearProgress/>
			</Box>
		);
	}

	const handleUpdate = async (data) => {
		try {
			await axios.patch(endpoints.templates.update(type, name), data);
			await mutate(() => data, false);
			enqueueSnackbar(t('successes.updated'), {variant: 'success'});
		} catch (e) {
			console.error(e);
		}
	}

	return (
		<AppointmentSmsTemplate
			handleUpdate={handleUpdate}
			defaultValues={{
				body: template.body,
			}}
		/>
	);
};
AppointmentSmsTemplateView.propTypes = {
	type: PropTypes.string,
	name: PropTypes.string,
}

export default AppointmentSmsTemplateView;

