import PropTypes from 'prop-types';
import {Controller, useFormContext} from 'react-hook-form';
// @mui
import dayjs from 'dayjs';
import {MobileDateTimePicker} from "@mui/x-date-pickers/MobileDateTimePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {getPickerLocaleTexts} from "../../utils/pickers";
import {useLocales} from "../../locales";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {getLocaleDayjs} from "../../utils/dayjs";

// ----------------------------------------------------------------------

function isAMPM(locale = navigator.language) {
	const formatter = new Intl.DateTimeFormat(locale, {hour: 'numeric'});
	const parts = formatter.formatToParts(new Date());

	return parts.some(part => part.type === 'dayPeriod');
}

export default function RHFDateTimePicker({ name, helperText, onChange, ...other }) {
	const { control } = useFormContext();
	const { currentLang } = useLocales();

	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState: { error } }) => (
				<LocalizationProvider
					dateAdapter={AdapterDayjs}
					adapterLocale={getLocaleDayjs(currentLang.value)}
					localeText={getPickerLocaleTexts(currentLang.value)}
				>
					<MobileDateTimePicker
						{...field}
						fullWidth
						ampm={isAMPM()}
						slotProps={{ textField: { variant: "filled" } }}
						value={field.value || dayjs('')}
						onChange={(newValue) => {
							const isoString = newValue ? newValue.toISOString() : null;
							if (onChange) onChange(isoString);
							field.onChange(isoString);
						}}
						error={!!error}
						helperText={error ? error?.message : helperText}
						{...other}
					/>
				</LocalizationProvider>
			)}
		/>
	);
}

RHFDateTimePicker.propTypes = {
	helperText: PropTypes.object,
	name: PropTypes.string,
	onChange: PropTypes.func,
};
