import {useContext, useMemo} from 'react';
// routes
import {paths} from 'src/routes/paths';
// locales
import {useLocales} from 'src/locales';
// components
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';
import BusinessIcon from '@mui/icons-material/Business';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import SmsIcon from '@mui/icons-material/Sms';
import EmailIcon from '@mui/icons-material/Email';
import LayersIcon from '@mui/icons-material/Layers';
import PaymentIcon from '@mui/icons-material/Payment';
import {AuthContext} from "../../auth/context/jwt";
import {canUseFeature} from "../../utils/feature-control";
import {Features} from "../../assets/data/features";
import {useAuthContext} from "../../auth/hooks";
import {AdministrativeRoles, hasRole} from "../../utils/roles";
import NotificationsIcon from '@mui/icons-material/Notifications';
import EntitiesIcon from '@mui/icons-material/AccountTree';
import RoomIcon from '@mui/icons-material/HolidayVillage';
import AttributesIcon from '@mui/icons-material/Dns';
import useSWR from "swr";
import {endpoints, fetcher} from "../../utils/axios";
import ArticleIcon from '@mui/icons-material/Article';
import {paramCase} from "../../utils/change-case";
// ----------------------------------------------------------------------

export const icon = (name) => (
	<SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{width: 1, height: 1}}/>
	// OR
	// <Iconify icon="fluent:mail-24-filled" />
	// https://icon-sets.iconify.design/solar/
	// https://www.streamlinehq.com/icons
);

const ICONS = {
	job: icon('ic_job'),
	blog: icon('ic_blog'),
	chat: icon('ic_chat'),
	mail: icon('ic_mail'),
	user: icon('ic_user'),
	file: icon('ic_file'),
	lock: icon('ic_lock'),
	tour: icon('ic_tour'),
	order: icon('ic_order'),
	label: icon('ic_label'),
	blank: icon('ic_blank'),
	kanban: icon('ic_kanban'),
	folder: icon('ic_folder'),
	banking: icon('ic_banking'),
	booking: icon('ic_booking'),
	invoice: icon('ic_invoice'),
	service: icon('ic_job'),
	appointments: icon('ic_calendar'),
	disabled: icon('ic_disabled'),
	external: icon('ic_external'),
	menuItem: icon('ic_menu_item'),
	ecommerce: icon('ic_ecommerce'),
	analytics: icon('ic_analytics'),
	dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData() {
	const {user} = useAuthContext();

	const {features} = useContext(AuthContext);
	const {t} = useLocales();
	const canUseNotifications = canUseFeature(features, Features.SendSms) && canUseFeature(features, Features.SendEmail);
	const canCustomisePlatform = canUseFeature(features, Features.CustomiseBrandingPlatform);
	const canCustomiseEmail = canUseFeature(features, Features.CustomiseBrandingEmail);
	const canCustomiseSms = canUseFeature(features, Features.CustomiseBrandingSms);
	const canAddAttributes = canUseFeature(features, Features.CustomAttributes);
	const canAccessStorage = canUseFeature(features, Features.Storage);
	const {data: entities} = useSWR(endpoints.entityConfigurations.find, fetcher, false);
	const hasEntities = entities?.length > 0;

	return useMemo(
		() => [
			// OVERVIEW
			// ----------------------------------------------------------------------
			{
				subheader: t('navigations.dashboard.overview'),
				items: [
					{title: t('navigations.dashboard.title'), path: paths.dashboard.root, icon: ICONS.dashboard},
				],
			},

			// MANAGEMENT
			// ----------------------------------------------------------------------
			{
				subheader: t('navigations.dashboard.management'),
				items: [
					{
						title: t('navigations.dashboard.appointments'),
						path: paths.dashboard.appointments,
						icon: ICONS.appointments,
					},
					{
						title: t('navigations.dashboard.customers'),
						path: paths.dashboard.clients.root,
						icon: ICONS.user,
					},
					...(hasRole(user?.role, AdministrativeRoles) ? [
						{
							title: t('navigations.dashboard.employees'),
							path: paths.dashboard.employees.root,
							icon: <Iconify icon="solar:users-group-rounded-bold-duotone" width={25}/>,
						},

					] : []),
					{
						title: t('navigations.dashboard.services'),
						path: paths.dashboard.services.root,
						icon: ICONS.service,
					},
					{
						title: t('navigations.dashboard.halls'),
						path: paths.dashboard.halls.root,
						icon: <RoomIcon/>,
					},
				],
			},

			// ENTITIES
			// ----------------------------------------------------------------------
			...(hasEntities ? [{
				subheader: t('navigations.dashboard.entities'),
				items: entities.map((entity) => ({
					title: entity.entity,
					path: paths.dashboard.entities.root(paramCase(entity.entity)),
					icon: <ArticleIcon/>,
				}))
			}] : []),

			// STORAGE
			// ----------------------------------------------------------------------
			...(canAccessStorage ? [{
				subheader: t('navigations.dashboard.storage.title'),
				items: [
					{
						title: t('navigations.dashboard.storage.files'),
						path: paths.dashboard.storage.files,
						icon: ICONS.folder
					}]
			}] : []),

			// TEMPLATES
			// ----------------------------------------------------------------------
			...((hasRole(user?.role, AdministrativeRoles) && (canCustomiseEmail || canCustomiseSms)) ? [{
				subheader: t('navigations.dashboard.templates.title'),
				items: [
					...(canCustomiseEmail ? [{
						title: t('navigations.dashboard.templates.emails'),
						path: '#/dashboard/templates',
						icon: <EmailIcon/>,
						children: [
							{
								title: t('navigations.dashboard.templates.appointment.title'),
								path: '#/dashboard/templates/appointment',
								children: [
									{
										title: t('navigations.dashboard.templates.appointment.created'),
										path: paths.dashboard.templates.emails.appointment.created,
									},
									{
										title: t('navigations.dashboard.templates.appointment.reminder'),
										path: paths.dashboard.templates.emails.appointment.reminder,
									},
									{
										title: t('navigations.dashboard.templates.appointment.updated'),
										path: paths.dashboard.templates.emails.appointment.updated,
									},
									{
										title: t('navigations.dashboard.templates.appointment.cancelled'),
										path: paths.dashboard.templates.emails.appointment.cancelled,
									},
								],
							},
						],
					}] : []),
					...(canCustomiseSms ? [{
						title: t('navigations.dashboard.templates.sms'),
						path: '#/dashboard/templates',
						icon: <SmsIcon/>,
						children: [
							{
								title: t('navigations.dashboard.templates.appointment.title'),
								path: '#/dashboard/templates/appointment',
								children: [
									{
										title: t('navigations.dashboard.templates.appointment.created'),
										path: paths.dashboard.templates.sms.appointment.created,
									},
									{
										title: t('navigations.dashboard.templates.appointment.reminder'),
										path: paths.dashboard.templates.sms.appointment.reminder,
									},
									{
										title: t('navigations.dashboard.templates.appointment.updated'),
										path: paths.dashboard.templates.sms.appointment.updated,
									},
									{
										title: t('navigations.dashboard.templates.appointment.cancelled'),
										path: paths.dashboard.templates.sms.appointment.cancelled,
									},
								],
							},
						],
					}] : []),
				],
			}] : []),


			// SETTINGS
			// ----------------------------------------------------------------------
			...(hasRole(user?.role, AdministrativeRoles) ? [{
				subheader: t('navigations.dashboard.settings.title'),
				items: [
					{
						title: t('navigations.dashboard.settings.entityConfigurations'),
						path: paths.dashboard.settings.entityConfigurations.root,
						icon: <EntitiesIcon/>,
					},
					...(canAddAttributes ? [
						{
							title: t('navigations.dashboard.settings.customAttributes.title'),
							icon: <AttributesIcon/>,
							path: '#/dashboard/settings/custom-attributes',
							children: [
								{
									title: t('navigations.dashboard.settings.customAttributes.customer'),
									path: paths.dashboard.settings.customAttributes.customer,
								}
							]
						}] : []),
					{
						title: t('navigations.dashboard.settings.organisationDetails'),
						path: paths.dashboard.settings.organisationDetails,
						icon: <BusinessIcon/>,
					},
					{
						title: t('navigations.dashboard.settings.businessHours'),
						path: paths.dashboard.settings.businessHours,
						icon: <AccessTimeIcon/>,
					},
					...(canUseNotifications ? [{
						title: t('navigations.dashboard.settings.notifications'),
						path: paths.dashboard.settings.notifications,
						icon: <NotificationsIcon/>,
					}] : []),
					...(canCustomisePlatform ? [{
						title: t('navigations.dashboard.settings.customisation'),
						path: paths.dashboard.settings.customisation,
						icon: <SettingsSuggestIcon/>,
					}] : []),
					{
						title: t('navigations.dashboard.settings.subscription'),
						path: paths.dashboard.settings.subscription,
						icon: <LayersIcon/>,
					},
					{
						title: t('navigations.dashboard.settings.payment'),
						path: paths.dashboard.settings.payment,
						icon: <PaymentIcon/>,
					},
				],
			}] : []),
		],
		[t, canCustomiseEmail, canCustomisePlatform, canCustomiseSms, canUseNotifications, user?.role, canAddAttributes, entities, hasEntities, canAccessStorage],
	);
}
