export const AttributeTypes = ['text', 'dropdown', 'date', 'datetime', 'file', 'entity', 'entities'];
export const AttributeTypesEnum = {
	text: 'text',
	dropdown: 'dropdown',
	date: 'date',
	datetime: 'datetime',
	file: 'file',
	entity: 'entity',
	entities: 'entities',
};
