import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
// utils
// routes
import {paths} from 'src/routes/paths';
import {useRouter} from 'src/routes/hook';
// assets
// components
import {useSnackbar} from 'src/components/snackbar';
import FormProvider, {RHFAutocomplete, RHFSelect, RHFSwitch, RHFTextField,} from 'src/components/hook-form';
import axios, {endpoints, fetcher} from "../../../utils/axios";
import {useLocales} from "../../../locales";
import {useAuthContext} from "../../../auth/hooks";
import React from "react";
import {phoneNumberRegex} from "../../../utils/regex";
import {canSendSms} from "../../../data/twilio";
import useSWR, {mutate} from "swr";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import {AttributeTypes, AttributeTypesEnum} from "../../../data/attribute";

// ----------------------------------------------------------------------

const toCamelCase = (text) => text
	.replace(/[^a-zA-Z\s]/g, '')
	.toLowerCase()
	.split(' ')
	.map((word, index) => {
		if (index === 0) {
			return word;
		}
		return word.charAt(0).toUpperCase() + word.slice(1);
	})
	.join('');

const create = async (data) => {
	const response = await axios.post(endpoints.customAttributes.customer.create, data);
	return response.data;
}

const patch = async (id, data) => {
	const response = await axios.patch(endpoints.customAttributes.customer.patch(id), data);
	return response.data;
}

const defaultValues = {
	name: '',
	field: '',
	active: true,
	options: []
}

export default function CustomAttributeCustomerCreateForm({current, handleUpdate, removeCard}) {
	const {t} = useLocales();
	const router = useRouter();

	const {enqueueSnackbar} = useSnackbar();
	const { data: selectableEntities } = useSWR(endpoints.entityConfigurations.findSelectable, fetcher, false);

	const Schema = Yup.object().shape({
		name: Yup.string().required().max(100).matches(/^[a-zA-Z ]*$/, t('errors.only_letters')),
		field: Yup.string().required(),
		options: Yup.array().of(Yup.string()),
		active: Yup.boolean(),
	});

	const methods = useForm({
		resolver: yupResolver(Schema),
		defaultValues: current || defaultValues,
	});

	const {
		reset,
		handleSubmit,
		formState: {isSubmitting},
		watch
	} = methods;

	const typeValue = watch('type');

	const onSubmit = handleSubmit(async (data) => {
		try {
			const body = {
				...data,
				field: toCamelCase(data.name),
			}
			if (current) {
				const updated = await patch(current.field, body);
				handleUpdate(updated);
				await mutate(endpoints.customAttributes.customer.find, (prevData) => prevData.map((item) => item.field === current.field ? updated : item), false);
				await mutate(endpoints.customAttributes.customer.get(current.field), updated, false);
			} else {
				const created = await create(body);
				await mutate(endpoints.customAttributes.customer.find, (prevData) => prevData ? [...prevData, created] : [created], false);
			}
			enqueueSnackbar(current ? t('pages.customers.addCustomer.snackbar.update') : t('pages.customers.addCustomer.snackbar.add'));
			if (!current) {
				reset();
				router.push(paths.dashboard.customAttributes.customer);
			}
		} catch (error) {
			if (error.code) {
				enqueueSnackbar(t(`errors.${error.code}`), {variant: 'error'});
			}
			console.log(error)
			console.error(error);
		}
	});

	const Container = removeCard || current ? Box : Card;

	const getEntity = (entity) => {
		if (['service', 'customer', 'hall', 'employee'].includes(entity)) {
			return t(`entities.${entity}`);
		}
		return entity;
	}

	return (
		<FormProvider methods={methods} onSubmit={onSubmit}>
			<Grid container spacing={3}>

				<Grid xs={12} md={12}>
					<Container sx={{p: 3}}>
						<Box
							rowGap={3}
							columnGap={2}
							display="grid"
							gridTemplateColumns={{
								xs: 'repeat(1, 1fr)',
								sm: 'repeat(2, 1fr)',
							}}
						>
							<RHFTextField name="name" label={t('fields.name')}/>
							<RHFSelect
								name="type"
								label={t("fields.type")}
							>
								{AttributeTypes.map((option) => (
									<MenuItem key={option} value={option}>
										{t(`fieldTypes.${option}`)}
									</MenuItem>
								))}
							</RHFSelect>
							{[AttributeTypesEnum.entity, AttributeTypesEnum.entities].includes(typeValue) && (
								<RHFSelect
									name="entity"
									label={t('fields.entity')}

								>
									{selectableEntities.map((entity) => (
										<MenuItem key={entity} value={entity} sx={{ textTransform: 'capitalize' }}>
											{getEntity(entity)}
										</MenuItem>
									))}
								</RHFSelect>
							)}
							{typeValue === AttributeTypesEnum.dropdown && (
								<RHFAutocomplete
									name="options"
									label={t('fields.options')}
									clearIcon={false}
									options={[]}
									freeSolo
									multiple
									renderTags={(value, props) =>
										value.map((option, i) => (
											<Chip label={option} {...props({ index: i })} />
										))
									}
									renderInput={(params) => (
										<TextField
											label={t('fields.options')}
											{...params}
											helperText={t('helperTexts.options')}
										/>
									)}
								/>
							)}
						</Box>

						<Stack alignItems="flex-end" sx={{mt: 3}} direction="row" justifyContent="flex-end">
							<RHFSwitch name="active" label={t('fields.active')}
							           sx={{marginLeft: 1}}/>
							<LoadingButton type="submit" variant="contained" loading={isSubmitting}>
								{!current ? t('buttons.add') : t('buttons.update')}
							</LoadingButton>
						</Stack>
					</Container>
				</Grid>
			</Grid>
		</FormProvider>
	);
}

CustomAttributeCustomerCreateForm.propTypes = {
	current: PropTypes.object,
	handleUpdate: PropTypes.func,
	removeCard: PropTypes.func,
};
