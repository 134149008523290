// routes
import { paths } from 'src/routes/paths';
// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

function jwtDecode(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    alert('Token expired');

    sessionStorage.removeItem('accessToken');
    const username = sessionStorage.getItem('username');
    const subdomain = sessionStorage.getItem('subdomain');
    const hasParams = !!username && !!subdomain;
    const params = new URLSearchParams();
    if (hasParams) {
      params.append('username', username);
      params.append('subdomain', subdomain);
    }
    window.location.href = `${paths.auth.login}${hasParams ? `?${params.toString()}` : null}`;
  }, timeLeft);
};

// ----------------------------------------------------------------------

export const refreshSession = (accessToken) => {
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
}

export const setSession = (accessToken, email = null, remember = false) => {

  if (email) {
    sessionStorage.setItem('email', email);
    localStorage.setItem('email', email);
  }
  if (accessToken) {
    sessionStorage.setItem('accessToken', accessToken);

    const cachedRememberMe = localStorage.getItem('rememberMe');
    if (cachedRememberMe) {
      remember = cachedRememberMe === 'true';
    }

    if (remember) {
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('rememberMe', 'true');
    }

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken); // ~3 days by minimals server
    tokenExpired(exp);
  } else {
    const items = ['accessToken', 'rememberMe', 'email'];
    items.forEach(item => {
      sessionStorage.removeItem(item);
      localStorage.removeItem(item);
    });
    delete axios.defaults.headers.common.Authorization;
  }
};
