import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import InputAdornment from '@mui/material/InputAdornment';
import { useParams, useRouter } from 'src/routes/hook';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFAutocomplete, RHFSelect, RHFSwitch, RHFTextField } from 'src/components/hook-form';
import axios, { endpoints, fetcher } from "../../../utils/axios";
import { useLocales } from "../../../locales";
import React, {useState, useEffect, useCallback} from "react";
import useSWR, { mutate } from "swr";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import RHFDatePicker from "../../../components/hook-form/rhf-datepicker";
import RHFDateTimePicker from "../../../components/hook-form/rhf-datetimepicker";
import DeleteIcon from '@mui/icons-material/Delete';
import { AttributeTypesEnum } from "../../../data/attribute";
import {paths} from "../../../routes/paths";

// ----------------------------------------------------------------------

const create = async (entity, data) => {
	const response = await axios.post(endpoints.entities.create(entity), data);
	return response.data;
}

const patch = async (entity, id, data) => {
	const response = await axios.patch(endpoints.entities.patch(entity, id), data);
	return response.data;
}

const defaultValues = {
	active: true,
	attributes: {},
}

// replace - with space
export const formatEntityName = (entity) => entity.replace(/-/g, ' ');

export default function EntityCreateForm({ current, handleUpdate, removeCard }) {
	const { t } = useLocales();
	const router = useRouter();
	const params = useParams();
	const { entity: rawEntity } = params;
	const entity = formatEntityName(rawEntity);

	const [files, setFiles] = useState([]);
	const [entityOptions, setEntityOptions] = useState({}); // Stores options for each entity attribute

	const { data: entityConfiguration } = useSWR(endpoints.entityConfigurations.type(entity), fetcher);
	const { enqueueSnackbar } = useSnackbar();

	const handleFileFetch = useCallback(() => {
		if (current?.attributes) {
			const fileEntities = entityConfiguration.attributes.filter(attr => attr.type === 'file');
			try {
				const fetchFile = async (attr, fileId) => {
					const response = await axios.get(endpoints.files.read(fileId));
					setFiles((prevFiles) => [...prevFiles, {
						...response.data,
						field: attr.field,
					}]);
				}
				Promise.all(fileEntities.map(attr => fetchFile(attr, current.attributes[attr.field])));
			} catch(e) {
				console.error(e)
			}
		}
	}, [entityConfiguration.attributes, current?.attributes])

	useEffect(() => {
		if (entityConfiguration) {
			handleFileFetch();
		}
	}, [entityConfiguration, handleFileFetch]);

	const Schema = Yup.object().shape({
		attributes: Yup.object({
			field: Yup.string(),
			value: Yup.string().max(5000),
		}),
		active: Yup.boolean(),
	});

	const methods = useForm({
		resolver: yupResolver(Schema),
		defaultValues: current || defaultValues,
	});

	const {
		reset,
		handleSubmit,
		formState: { isSubmitting },
	} = methods;

	const onSubmit = handleSubmit(async (data) => {
		try {
			const mappedAttributes = Object.entries(data.attributes).map(([key, value]) => value && ({
				field: key,
				value: value.value ?? value,
			})).filter(Boolean);
			const body = {
				...data,
				attributes: mappedAttributes,
			};

			if (current) {
				const updated = await patch(entity, current.id || current._id, body);
				handleUpdate(updated);
				await mutate(endpoints.entities.find(entity), (prevData) => prevData.map((item) => item.id === current.id ? updated : item), false);
				await mutate(endpoints.entities.get(entity, current.id), updated, false);
			} else {
				const created = await create(entity, body);
				await mutate(endpoints.entities.find(entity), (prevData) => prevData ? [...prevData, created] : [created], false);
			}
			enqueueSnackbar(current ? t('pages.customers.addCustomer.snackbar.update') : t('pages.customers.addCustomer.snackbar.add'));
			if (!current) {
				reset();
				router.push(paths.dashboard.entities.root(entity));
			}
		} catch (error) {
			if (error.code) {
				enqueueSnackbar(t(`errors.${error.code}`), { variant: 'error' });
			}
			console.error(error);
		}
	}, error => console.error(error));

	const Container = removeCard || current ? Box : Card;

	const fetchEntities = async (entityConfig) => {
		if (entityConfig.isDefault) {
			const response = await axios.get(entityConfig.path);
			return response.data;
		}
		const response = await axios.get(endpoints.entities.find(entityConfig.entity));
		return response.data.map(item => {
			const attributesObject = item.attributes.reduce((acc, attr) => {
				acc[attr.field] = attr.value;
				return acc;
			}, {});
			return {
				entity: item.entity,
				...attributesObject,
				active: item.active,
				deleted: item.deleted,
				organisation: item.organisation,
				createdAt: item.createdAt,
				updatedAt: item.updatedAt,
				id: item.id || item._id
			};
		});
	}

	const getEntityOptions = async (entityConfig) => {
		try {
			const { data: currentEntityConfiguration } = await axios.get(endpoints.entityConfigurations.type(entityConfig.entity));
			const primaryAttributesField = currentEntityConfiguration.attributes
				.filter(attr => attr.primary)
				.map(attr => attr.field);

			const currentEntities = await fetchEntities(currentEntityConfiguration);

			return currentEntities.map((e) => {
				const label = primaryAttributesField
					.map(field => e[field])
					.filter(value => value)
					.join(' | ');

				return {
					label: label || e.id, // Fallback to e.id if no primary attributes are present
					value: e._id || e.id,
				};
			});
		} catch (e) {
			console.error(e);
		}
		return [];
	}

	const fetchEntityOptions = async (entityConfig) => {
		try {
			const options = await getEntityOptions(entityConfig);
			setEntityOptions((prevOptions) => ({
				...prevOptions,
				[entityConfig.field]: options,
			}));
		} catch (error) {
			console.error("Failed to fetch entity options:", error);
		}
	};

	useEffect(() => {
		if (entityConfiguration?.attributes) {
			entityConfiguration.attributes
				.filter(attr => attr.type === AttributeTypesEnum.entity)
				.forEach(fetchEntityOptions);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [entityConfiguration]);

	return (
		<FormProvider methods={methods} onSubmit={onSubmit}>
			<Grid container spacing={3}>
				<Grid xs={12} md={12}>
					<Container sx={{ p: 3 }}>
						<Box
							rowGap={3}
							columnGap={2}
							display="grid"
							gridTemplateColumns={{
								xs: 'repeat(1, 1fr)',
								sm: 'repeat(2, 1fr)',
								xl: 'repeat(3, 1fr)',
							}}
						>
							{entityConfiguration?.attributes?.filter(a => !['file', 'files'].includes(a.type)).map((attr) => {
								if (attr.type === AttributeTypesEnum.dropdown) {
									return (
										<RHFSelect
											fullWidth
											sx={{ textTransform: 'none' }}
											key={attr.name}
											name={`attributes.${attr.field}`}
											label={attr.name}
										>
											{attr.options.map((option) => (
												<MenuItem key={option} value={option}>
													{option}
												</MenuItem>
											))}
										</RHFSelect>
									);
								}
								if (attr.type === AttributeTypesEnum.entity) {
									return (
										<RHFAutocomplete
											key={attr.name}
											name={`attributes.${attr.field}`}
											label={attr.name}
											getOptionLabel={(option) => (entityOptions[attr.field]?.find((o) => o.value === option))?.label || option.label}
											options={entityOptions[attr.field] || []} // Pass options as an array
										/>
									);
								}
								if (attr.type === AttributeTypesEnum.date) {
									return (
										<RHFDatePicker
											key={attr.name}
											name={`attributes.${attr.field}`}
											label={attr.name}
											type="date"
											InputLabelProps={{ shrink: true }}
											error={false}
										/>
									);
								}
								if (attr.type === AttributeTypesEnum.datetime) {
									return (
										<RHFDateTimePicker
											key={attr.name}
											name={`attributes.${attr.field}`}
											label={attr.name}
											type="date"
											InputLabelProps={{ shrink: true }}
											error={false}
										/>
									);
								}
								return (
									<RHFTextField
										key={attr.name}
										name={`attributes.${attr.field}`}
										label={attr.name}
									/>
								)
							})}
						</Box>

						<Stack direction="column" spacing={1} sx={{ mt: 3 }}>
							{entityConfiguration?.attributes?.filter(attr => attr.type === 'file').map((attr) => (
								<Box key={attr.name} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
									<RHFTextField
										fullWidth
										disabled
										label={attr.name}
										name={`attributes.${attr.field}`}
										value={files.find((file) => file?.field === attr?.field)?.name || 'No file uploaded'}
										InputLabelProps={{ shrink: true }}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<Stack spacing={1} direction="row">
														{!!methods.getValues(`attributes.${attr.field}`) && (
															<Button
																variant="outlined"
																startIcon={<DeleteIcon />}
																onClick={async () => {
																	try {
																		await axios.delete(endpoints.files.delete(methods.getValues(`attributes.${attr.field}`)));
																		setFiles(files.filter(f => f.field !== attr.field));
																		methods.setValue(`attributes.${attr.field}`, '');
																	} catch (e) {
																		console.error(e);
																	}
																}}
															>
																{t('buttons.delete')}
															</Button>
														)}
														{!methods.getValues(`attributes.${attr.field}`) && <Button
															variant="contained"
															component="label"
															startIcon={<FileUploadIcon />}
														>
															{t('buttons.upload')}
															<input
																type="file"
																hidden
																onChange={async (event) => {
																	const file = event.target.files[0];
																	if (!file) return;
																	const formData = new FormData();
																	formData.append('file', file);
																	try {
																		const response = await axios.post(endpoints.files.upload, formData, {
																			headers: {
																				'Content-Type': 'multipart/form-data',
																			},
																		});
																		setFiles([
																			...files.filter(f => f.field !== attr.field), {
																				...response.data,
																				field: attr.field
																			}]
																		)
																		methods.setValue(`attributes.${attr.field}`, response.data.fileId);
																		enqueueSnackbar(t('successes.fileUploaded'), { variant: 'success' });
																	} catch (error) {
																		if (error.code) {
																			enqueueSnackbar(t(`errors.${error.code}`), { variant: 'error' });
																		}
																		console.error('Error uploading file:', error);
																	}
																}}
															/>
														</Button>}
													</Stack>
												</InputAdornment>
											),
										}}
									/>
								</Box>
							))}
						</Stack>

						<Stack alignItems="flex-end" sx={{ mt: 3 }} direction="row" justifyContent="flex-end">
							<RHFSwitch name="active" label={t('fields.active')} sx={{ marginLeft: 1 }} />
							<LoadingButton type="submit" variant="contained" loading={isSubmitting}>
								{!current ? t('buttons.add') : t('buttons.update')}
							</LoadingButton>
						</Stack>
					</Container>
				</Grid>
			</Grid>
		</FormProvider>
	);
}

EntityCreateForm.propTypes = {
	current: PropTypes.object,
	handleUpdate: PropTypes.func,
	removeCard: PropTypes.func,
};
