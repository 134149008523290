import PropTypes from 'prop-types';
// @mui
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
// hooks
// components
//
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import {useEffect, useRef, useState} from "react";
// ----------------------------------------------------------------------

const TypingEffect = ({ text, speed = 100 }) => {
	const [displayedText, setDisplayedText] = useState('');
	const [index, setIndex] = useState(0);
	const [isTyping, setIsTyping] = useState(true);

	const hasTypedBefore = useRef(false); // Persist the typing state across re-mounts

	useEffect(() => {
		// Skip typing effect if it has already run
		if (hasTypedBefore.current) {
			return;
		}

		// Continue typing until the end of the text
		if (index < text.length) {
			const timeout = setTimeout(() => {
				setDisplayedText((prev) => prev + text[index]);
				setIndex(index + 1);
			}, speed);

			// Return the cleanup function
			// eslint-disable-next-line consistent-return
			return () => clearTimeout(timeout);
		}

		// Mark that typing effect has completed
		hasTypedBefore.current = true;
		setIsTyping(false);
	}, [index, text, speed]);

	return (
		<div>
			{displayedText}
			{isTyping && <span className="cursor">|</span>}
		</div>
	);
};

TypingEffect.propTypes = {
	text: PropTypes.string,
	speed: PropTypes.number,
};

export default function ChatMessageItem({message}) {
	const {content} = message;
	const me = message.role === 'user';

	const renderBody = (
		<Stack
			sx={{
				p: 1.5,
				minWidth: 48,
				maxWidth: 320,
				borderRadius: 1,
				typography: 'body2',
				bgcolor: 'background.neutral',
				...(me && {
					color: 'grey.800',
					bgcolor: 'primary.lighter',
				}),
			}}
		>
			{me ? content : (
				<TypingEffect
					text={content}
					speed={40}
				/>
			)}
		</Stack>
	);

	return (
		<Stack direction="row" justifyContent={me ? 'flex-end' : 'unset'} sx={{mb: 5}} spacing={1}>
			{!me && (
				<Avatar>
					<SupportAgentIcon/>
				</Avatar>
			)}

			<Stack alignItems="flex-end">
				<Stack
					direction="row"
					alignItems="center"
					sx={{
						position: 'relative',
						'&:hover': {
							'& .message-actions': {
								opacity: 1,
							},
						},
					}}
				>
					{renderBody}
				</Stack>
			</Stack>
		</Stack>
	);
}

ChatMessageItem.propTypes = {
	message: PropTypes.object,
};
