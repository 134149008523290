import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import * as Yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import {capitalize, Grid} from "@mui/material";
import FormProvider, {RHFAutocomplete, RHFSwitch,} from "../../components/hook-form";
import axios, {endpoints} from "../../utils/axios";
import {useLocales} from "../../locales";
import {useSnackbar} from "src/components/snackbar";


function BusinessHours({
	                       stepper,
	                       handleNext,
	                       handleBack,
	                       setErrorMsg,
	                       hasBack,
	                       configurationData,
	                       setConfigurationData,
                       }) {
	const {enqueueSnackbar} = useSnackbar();
	const {t} = useLocales();


	const timeSchema = Yup.string()
		.matches(/^([01]\d|2[0-3]):([0-5]\d)$/, t('errors.invalid_time_format'))
		.required(t('errors.required_time'));

	const OpeningHoursSchema = Yup.object().shape({
		timezone: Yup.object({
			value: Yup.string().required(), label: Yup.string().required(),
		}), openingHours: Yup.object({
			monday: Yup.object({
				start: timeSchema, end: timeSchema,
			}), tuesday: Yup.object({
				start: timeSchema, end: timeSchema,
			}), wednesday: Yup.object({
				start: timeSchema, end: timeSchema,
			}), thursday: Yup.object({
				start: timeSchema, end: timeSchema,
			}), friday: Yup.object({
				start: timeSchema, end: timeSchema,
			}), saturday: Yup.object({
				start: timeSchema, end: timeSchema,
			}), sunday: Yup.object({
				start: timeSchema, end: timeSchema,
			}),

		}),
	});

	const methods = useForm({
		resolver: yupResolver(OpeningHoursSchema), defaultValues: {
			timezone: {
				label: configurationData?.timezone || null, value: configurationData?.timezone || null
			}, openingHours: configurationData?.openingHours || null,
		}
	});

	const {
		handleSubmit, setValue,
	} = methods;

	const [timezones, setTimezones] = useState([]);

	useEffect(() => {
		setTimezones(Intl.supportedValuesOf('timeZone'));
		const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		setValue('timezone', {value: userTimezone, label: userTimezone});
	}, [setValue]);

	const submit = handleSubmit(async data => {
		try {
			const requestBody = {
				timezone: data.timezone.value, openingHours: data.openingHours,
			}
			const response = await axios.patch(endpoints.configurations.update, requestBody);
			if (setConfigurationData) {
				setConfigurationData(response.data);
			}
			if (!stepper) {
				enqueueSnackbar(t('successes.updated'), {variant: 'success'});
			}
		} catch (error) {
			console.error(error);
			if (setErrorMsg) {
				setErrorMsg(typeof error === 'string' ? error : error.message);
			}
		}
		if (handleNext) {
			handleNext();
		}
	}, (error) => console.log(error));


	return (
		<Box sx={{mt: 4}}>
			<FormProvider methods={methods} onSubmit={submit}>
				<RHFAutocomplete
					name="timezone"
					label={t('fields.timezone')}
					options={timezones.map(timezone => ({value: timezone, label: timezone}))}
					getOptionLabel={(option) => option.label}
					isOptionEqualToValue={(option, value) => option.value === value.value}
					renderOption={(props, option) => (<li {...props} key={option.value}>
						{option.label}
					</li>)}
				/>
				{["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"].map(day => (<>
					<DayItem day={day} methods={methods}/>
					<Divider sx={{mt: 2}}/>
				</>))}

				{!stepper && (
					<Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 3}}>
						<Button
							color="inherit"
							size="large"
							type="submit"
							variant="contained"
							onClick={submit}
						>
							{t('buttons.update')}
						</Button>
					</Box>
				)}
				{stepper && (<Box sx={{display: 'flex', flexDirection: 'row', pt: 2, mt: 3}}>
					<Button
						size="large"
						variant="outlined"
						disabled={!hasBack}
						onClick={handleBack}
						sx={{mr: 1}}
					>
						{t('buttons.back')}
					</Button>
					<Box sx={{flex: '1 1 auto'}}/>
					<Button
						color="inherit"
						size="large"
						type="submit"
						variant="contained"
						onClick={submit}
					>
						{t('buttons.next')}
					</Button>
				</Box>)}
			</FormProvider>
		</Box>);
}

BusinessHours.propTypes = {
	stepper: PropTypes.bool,
	handleNext: PropTypes.func,
	handleBack: PropTypes.func,
	setErrorMsg: PropTypes.func,
	hasBack: PropTypes.bool,
	configurationData: PropTypes.object,
	setConfigurationData: PropTypes.func,
};

function DayItem({day, methods}) {
	const { t } = useLocales();
	const {watch, control} = methods;
	const values = watch();
	return (<Grid container spacing={1} sx={{mt: 1}}>
		<Grid item md={3} xs={6}>
			<Typography variant="h5">{capitalize(t(`days.${day}`))}</Typography>
		</Grid>
		<Grid item md={3} xs={6}>
			<RHFSwitch name={`openingHours.${day}.open`} label={t('fields.open')}/>
		</Grid>
		<Grid item md={3} xs={6}>
			<Controller
				name={`openingHours.${day}.start`}
				control={control}
				render={({field}) => (<TimePicker
					{...field}
					label={t('fields.start')}
					value={field.value ? setDateToTime(field.value) : setDateToTime("09:00")}
					onChange={(newValue) => field.onChange(dayjs(newValue).format('HH:mm'))}
					ampm={false}
					inputFormat="HH:mm"
					disabled={values?.openingHours?.[day]?.open === false}
				/>)}
			/>
		</Grid>
		<Grid item md={3} xs={6}>
			<Controller
				name={`openingHours.${day}.end`}
				control={control}
				render={({field}) => (<TimePicker
					{...field}
					label={t('fields.end')}
					value={field.value ? setDateToTime(field.value) : setDateToTime("17:00")}
					onChange={(newValue) => field.onChange(dayjs(newValue).format('HH:mm'))}
					ampm={false}
					inputFormat="HH:mm"
					disabled={values?.openingHours?.[day]?.open === false}
				/>)}
			/>
		</Grid>
	</Grid>)
}

DayItem.propTypes = {
	day: PropTypes.string, methods: PropTypes.object,
}

function setDateToTime(timeString) {
	const [hour, minute] = timeString.split(':');
	const result = dayjs()
		.hour(parseInt(hour, 10))
		.minute(parseInt(minute, 10))
		.second(0);

	return result.toDate();
}

export default BusinessHours;
